import { getAppConfig, initAppConfig } from '@/letapps-vue/utils/appConfig';
import { requireAuthentication } from '@/letapps-vue/utils/auth';
import { initAxios } from '@/letapps-vue/utils/axios';
import { initSentry } from '@/letapps-vue/utils/sentry';
import router from '@/router/router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'devextreme/dist/css/dx.common.css';
import { loadMessages, locale } from 'devextreme/localization';
import PDFObjectPlugin from 'pdfobject-vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import VueApexCharts from 'vue3-apexcharts';
import App from './App.vue';
import './css/letguide.scss';
import danishMessages from './da.json';
import 'dayjs/locale/da';

loadMessages(danishMessages);
locale('da');

dayjs.extend(isoWeek); // use plugin
dayjs.extend(isLeapYear); // use plugin
dayjs.extend(isoWeeksInYear); // use plugin
dayjs.extend(updateLocale); // use plugin
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);

dayjs.updateLocale('da', {
  monthsShort: [
    'jan',
    'feb',
    'mar',
    'apr',
    'maj',
    'juni',
    'juli',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
  ],
});

dayjs.locale('da');

(async () => {
  await initAppConfig();

  await requireAuthentication();

  const pinia = createPinia();
  const app = createApp(App);
  app.use(router);

  // await initErrorHandling(app, router);
  await initSentry(app, router);
  await initAxios();

  app.use(pinia);

  app.use(VueApexCharts);
  app.use(PDFObjectPlugin);

  if (getAppConfig().env === 'letguide-prod') {
    app.use(VueGtag, {
      config: { id: 'G-SBXHVF8492' },
    });
  }

  app.mount('#app');
})();
