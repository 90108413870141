<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import dayjs from 'dayjs';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

let forrigeAar = [];
let detteAar = [];
function prepareData() {
  const data = store.finansPlus;
  forrigeAar = data.slice(-8).slice(0, 4);
  detteAar = data.slice(-4);
}
function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    dataLabels: {
      // enabled: false,
      style: {
        fontSize: '11px',
        fontWeight: '400',
        colors: ['#000'],
      },
      offsetY: -20,

      formatter: function (value) {
        return formatNumber(value, 0, 0);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  prepareData();
  const series = [
    {
      name: 'Forrige år',
      data: forrigeAar.map((x, i) => {
        return {
          x: `Q${dayjs(x.tilDato).quarter()}-${dayjs(x.tilDato).format('YY')}/${dayjs(detteAar[i].tilDato).format('YY')}`,
          y: x.antalHenvisninger,
        };
      }),
    },
    {
      name: 'Dette år',
      data: detteAar.map((x, i) => {
        return {
          x: `Q${dayjs(x.tilDato).quarter()}-${dayjs(forrigeAar[i].tilDato).format('YY')}/${dayjs(x.tilDato).format('YY')}`,
          y: x.antalHenvisninger,
        };
      }),
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
