<script setup lang="ts">
import { DxSelectBox } from 'devextreme-vue/select-box';
import { onMounted, ref } from 'vue';
import { getKvartalsrapportDatasource } from '@/data/kvartalsrapport';
import { Kvartalsrapport } from '@/types/kvartalsrapport';

const sortedItems = ref<Kvartalsrapport[]>([]);

onMounted(async () => {
  const datasource = getKvartalsrapportDatasource();
  await datasource.loadIfEmpty();
  const items = await datasource.items();
  sortedItems.value = items.sort((p1: Kvartalsrapport, p2: Kvartalsrapport) =>
    p1.startDato > p2.startDato ? 1 : p1.startDato < p2.startDato ? -1 : 0,
  );
});
</script>

<template>
  <DxSelectBox
    :items="sortedItems"
    placeholder="Vælg rapport"
    :displayExpr="(x: Kvartalsrapport) => `${x?.aar} ${x?.periode}`"
    valueExpr="id"
  />
</template>

<style scoped></style>
