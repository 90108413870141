<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import TiptapComponentItemWrapper from '@/tiptap/component/TiptapComponentItemWrapper.vue';
import LetSectionColorDropDownButton from '@/tiptap/let-section/LetSectionColorDropDownButton.vue';
import { DxButton } from 'devextreme-vue/button';
import LetSectionWidthDropDownButton from '@/tiptap/let-section/LetSectionWidthDropDownButton.vue';
import { type Range } from '@tiptap/core';
import { ref } from 'vue';

const props = defineProps({
  ...nodeViewProps,
  childCount: {
    type: Number,
  },
});

const showToolbar = ref(false);

function addItemBefore() {
  addItem(props.getPos());
}

function addItemAfter() {
  const endPos = props.getPos() + props.node.nodeSize;
  addItem(endPos);
}

function addItem(pos: number | Range) {
  props.editor
    .chain()
    .insertContentAt(pos, {
      type: 'letSectionItem',
      content: [
        {
          type: 'paragraph',
          // content: [{ type: 'text', text: '[Overskrift]' }],
        },
      ],
    })
    .run();
}

function toggleToolbar() {
  showToolbar.value = !showToolbar.value;
}
</script>

<template>
  <node-view-wrapper class="let-section-column" v-bind="node.attrs">
    <tiptap-component-item-wrapper :editor="editor">
      <node-view-content class="let-section-column-content" />
      <template #buttons>
        <let-section-color-drop-down-button
          v-if="showToolbar"
          :updateAttributes="updateAttributes"
        />
        <let-section-width-drop-down-button
          v-if="showToolbar"
          :updateAttributes="updateAttributes"
        />
        <DxButton
          v-if="showToolbar"
          icon='<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path  transform="rotate(90 12 12)" fill="currentColor" d="M12 4a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 0 1 0-2h3V5a1 1 0 0 1 1-1ZM3 19a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"/></svg>'
          hint="Indsæt sektion til venstre"
          @click="addItemBefore"
        />
        <DxButton
          v-if="showToolbar"
          icon='<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path  transform="rotate(-90 12 12)" fill="currentColor" d="M12 4a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 0 1 0-2h3V5a1 1 0 0 1 1-1ZM3 19a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"/></svg>'
          hint="Indsæt sektion til højre"
          @click="addItemAfter"
        />
        <DxButton v-if="showToolbar" icon="trash" hint="Slet kolonne" @click="deleteNode" />
        <DxButton icon="preferences" @click="toggleToolbar" />
      </template>
    </tiptap-component-item-wrapper>
  </node-view-wrapper>
</template>

<style lang="scss">
@import '@/css//variables.letguide.scss';

.let-section-column {
  flex: 1;
  padding: 20px;
  word-break: break-word;

  &.flex-2 {
    flex: 1;
  }

  &[size='double'] {
    flex: 2;
  }

  &[color='blue'] {
    background-color: $letpension-blue;
    color: white;
  }

  &[color='sand'] {
    background-color: $letpension-sand;
  }

  &[color='grey'] {
    background-color: $letpension-grey-light;
  }

  &[color='green'] {
    background-color: $letpension-green;
    color: white;
  }

  &[color='orange'] {
    background-color: $letpension-orange;
    color: white;
  }

  &:first-child:not([color]) {
    padding-left: 0;
  }
}

.let-section-column-content {
  margin-top: 0.75em;
  min-width: 1em;
  vertical-align: top;
}

.tiptap[contenteditable='true'] {
  .let-section-column {
    border: 1px dashed $letpension-grey;
  }
}
</style>
