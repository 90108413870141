<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';
import dayjs from 'dayjs';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    xaxis: {
      labels: {
        rotateAlways: true,
        rotate: -45,
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (value) {
        return formatNumber(value, 1, 0);
      },
    },
  });
}

function getSeries() {
  const data = store.aktivitet.periodeAktivitet;

  const series = [
    {
      name: 'Antal rådgivere med 1-2 aftaler',
      data: data.map((x) => {
        return {
          x: dayjs()
            .year(x.aar)
            .month(x.maaned - 1)
            .format('MMMM'),
          y: x.antalRaadgivereMed1Til2Aftaler,
        };
      }),
    },
    {
      name: 'Antal rådgivere med 3-5 aftaler',
      data: data.map((x) => {
        return {
          x: dayjs()
            .year(x.aar)
            .month(x.maaned - 1)
            .format('MMMM'),
          y: x.antalRaadgivereMed3Til5Aftaler,
        };
      }),
    },
    {
      name: 'Antal rådgivere med flere end 5 aftaler',
      data: data.map((x) => {
        return {
          x: dayjs()
            .year(x.aar)
            .month(x.maaned - 1)
            .format('MMMM'),
          y: x.antalRaadgivereMedMereEnd5,
        };
      }),
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 300px;
}
</style>
