<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import dayjs from 'dayjs';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';
import notify from 'devextreme/ui/notify';
import { isLetpensionBruger } from '@/letapps-vue/utils/auth';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

let forrigeAar;

let detteAar;
function prepareData() {
  const salgProduktGrupper = store.udvikling.salgProduktGrupper;
  const salg = salgProduktGrupper.filter((gruppe) => gruppe.salgProduktGruppeNavn === 'Pension')[0];
  const maanedSalg = salg.maanedSalg;
  detteAar = maanedSalg.slice(maanedSalg.length - 12);
  forrigeAar = maanedSalg.slice(maanedSalg.length - 24).slice(0, 12);
}
function getOptions() {
  const options = Object.assign({}, getDefaultOptions(props.print), {
    dataLabels: {
      enabled: false,
      formatter: function (value) {
        return formatNumber(value, 1, 1);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });

  return options;
}

function getSeries() {
  prepareData();

  const series = [
    {
      name: 'Forrige år',
      data: forrigeAar.map((x) => {
        return { x: `${dayjs(x.periodeTil).format('MMM')}`, y: x.beloeb / 1000000 };
      }),
    },
    {
      name: 'Dette år',
      data: detteAar.map((x) => {
        return { x: `${dayjs(x.periodeTil).format('MMM')}`, y: x.beloeb / 1000000 };
      }),
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart" v-if="store.udvikling.salgProduktGrupper">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
