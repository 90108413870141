<script setup>
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { DxButton } from 'devextreme-vue';

const route = useRoute();
const filId = computed(() => Number(route.params.id));
const filnavn = ref();
const url = ref();

function loadPdf(id) {
  axios
    .get(getAppConfig().apiRoot + '/api/Fil/' + id, {
      responseType: 'blob',
    })
    .then((response) => {
      let downloadFilename = 'download.fil';
      //Læs filnavn fra response header
      const match = response.headers['content-disposition'].match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
      );
      if (match && match[1]) {
        downloadFilename = match[1];
      }
      filnavn.value = decodeURI(downloadFilename);
      const blob = new Blob([response.data], { type: response.data.type });
      url.value = window.URL.createObjectURL(blob);
      window.URL.revokeObjectURL(url);
    });
}

function download() {
  const link = document.createElement('a');
  link.href = url.value;
  link.setAttribute('download', filnavn.value);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

watchEffect(() => {
  if (filId.value) {
    loadPdf(filId.value);
  }
});
</script>

<template>
  <div class="pdf-viewer">
    <div class="toolbar">
      <DxButton text="Download fil" @click="download" type="default" />
    </div>
    <PdfObject v-if="url" :url="url" class="fil" :options="{ pdfOpenParams: { page: 1 } }" />
  </div>
</template>

<style scoped lang="scss">
.pdf-viewer {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .toolbar {
    margin: 20px 20px 0 20px;
    // height: 30px;
  }

  .fil {
    flex: 1;
  }
}
.fil {
  border: none;
  overflow: hidden;
  margin: 20px;
}
</style>

<style>
cr-icon-button {
  display: none;
}

#download {
  display: none;
}
</style>
