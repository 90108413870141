<script setup>
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import SamletFordelingChart from '@/pages/kvartalsrapport/charts/SamletFordelingChart.vue';
import SamletIndtjeningModTop5Chart from '@/pages/kvartalsrapport/charts/SamletIndtjeningModTop5Chart.vue';

defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();
</script>

<template>
  <div class="titel">Samlet indtjeningsbillede</div>
  <div v-if="store.indtjening" class="flex-container container">
    <div class="flex-1">
      <div class="chart-titel">Hvordan fordeler indtjeningen sig?</div>
      <SamletFordelingChart :print="print" />
    </div>
    <div class="flex-1">
      <div class="chart-titel">
        Hvor stor er den årlige indtjening pr. 100 medarbejdere – sammenlignet med de fem
        pengeinstitutter, der tjener mest?
      </div>
      <SamletIndtjeningModTop5Chart :print="print" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';
.titel {
  margin-bottom: 80px;
}
</style>
