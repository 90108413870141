<script setup>
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';

const props = defineProps({
  updateAttributes: { type: Function, required: true },
});

const items = [
  {
    value: 'normal',
    text: 'Enkelt bredde',
    // icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#ffffff" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>',
  },
  {
    value: 'double',
    text: 'Dobbelt bredde',
    // icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#6bb8bc" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>',
  },
];

function onItemClick(e) {
  const itemData = e.itemData;

  if (itemData.value === 'normal') {
    props.updateAttributes({ size: null });
  } else {
    props.updateAttributes({ size: itemData.value });
  }
}
</script>

<template>
  <DxDropDownButton
    hint="Skift bredde"
    :items="items"
    :dropDownOptions="{
      width: 150,
      wrapperAttr: { class: 'action-dropdown-list' },
    }"
    icon='<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 2048 2048"><path fill="currentColor" d="M0 1792V256h1280v1536H0zM128 384v1280h1024V384H128zm1280-128h640v1536h-640V256zm512 1408V384h-384v1280h384z"/></svg>'
    displayExpr="text"
    keyExpr="value"
    @item-click="onItemClick"
    :element-attr="{ style: 'background-color:white' }"
  >
  </DxDropDownButton>
</template>

<style scoped lang="scss">
//.field-text-box {
//  .dx-texteditor-input {
//    border: none;
//  }
//}
//
//.let-tiptap-toolbar .dx-selectbox .field-text-box .dx-texteditor-input {
//  border: none;
//}
//
//.field-icon {
//  position: relative;
//  font-size: 1.5rem;
//  top: 1px;
//  padding-left: 4px;
//}
//
//.item-icon {
//  position: relative;
//  font-size: 1.5rem;
//  top: 6px;
//  margin-right: 8px;
//}
//
//.item-text {
//  margin-top: 0;
//}
//
//.dx-dropdownlist-popup-wrapper
//  .dx-list:not(.dx-list-select-decorator-enabled)
//  .dx-list-item-content.select-box-item {
//  padding: 2px 8px;
//}
</style>
