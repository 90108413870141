import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import LetTabComponent from './LetTabComponent.vue';
import LetTabsComponent from './LetTabsComponent.vue';
import LetTabsButtonsComponent from './LetTabsButtonsComponent.vue';
import LetTabButtonComponent from './LetTabButtonComponent.vue';
import { nanoid } from 'nanoid';

export const LetTabs = Node.create({
  name: 'letTabs',
  group: 'block',
  content: 'letTabsButtons letTab+',
  isolating: true,
  allowGapCursor: false,

  parseHTML() {
    return [
      {
        tag: 'let-tabs',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-tabs', mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      insertTabs:
        (numberOfTabs = 2) =>
        ({ commands }) => {
          let buttonsHtml = '';
          let tabsHtml = '';
          for (let i = 1; i <= numberOfTabs; i++) {
            const tabId = nanoid(10);
            buttonsHtml +=
              '<let-tab-button data-tab-id="' + tabId + '">Tab ' + i + '</let-tab-button>';
            tabsHtml += '<let-tab data-tab-id="' + tabId + '"><p>Indhold ' + i + '</p></let-tab>';
          }
          commands.insertContent(
            '<let-tabs><let-tabs-buttons>' +
              buttonsHtml +
              '</let-tabs-buttons>' +
              tabsHtml +
              '</let-tabs>',
          );
          return true;
        },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(LetTabsComponent);
  },
});

export const LetTabsButtons = Node.create({
  name: 'letTabsButtons',
  group: 'block',
  content: 'letTabButton+',
  isolating: true,
  allowGapCursor: false,

  parseHTML() {
    return [
      {
        tag: 'let-tabs-buttons',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-tabs-buttons', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LetTabsButtonsComponent);
  },
});

export const LetTabButton = Node.create({
  name: 'letTabButton',
  group: 'block',
  content: 'block+',
  isolating: true,
  allowGapCursor: false,

  addAttributes() {
    return {
      tabId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-tab-id'),
        renderHTML: (attributes) => {
          return {
            'data-tab-id': attributes.tabId,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'let-tab-button',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-tab-button', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LetTabButtonComponent);
  },
});

export const LetTab = Node.create({
  name: 'letTab',
  group: 'block',
  content: 'block+',
  isolating: true,
  defining: true,
  allowGapCursor: true,

  addAttributes() {
    return {
      tabId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-tab-id'),
        renderHTML: (attributes) => {
          return {
            'data-tab-id': attributes.tabId,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'let-tab',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-tab', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LetTabComponent);
  },
});
