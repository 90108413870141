<script setup>
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxButton } from 'devextreme-vue/button';
import LetCard from '@/letapps-vue/components/LetCard.vue';
import { getBrugerNotifikationDatasource } from '@/data/notifikation';
import { getNotifikationTypeDatasource } from '@/data/notifikationType';
import { onMounted, ref } from 'vue';

const notifikationTypeDatasource = getNotifikationTypeDatasource();
const notifikationDatasource = getBrugerNotifikationDatasource();
const notifikationsTyper = ref([]);
const brugerNotifikationer = ref([]);
const changes = ref({});

onMounted(async () => {
  notifikationsTyper.value = await notifikationTypeDatasource.load();
  brugerNotifikationer.value = await notifikationDatasource.load();
});

function getNotifikation(id) {
  const found = brugerNotifikationer.value.filter((n) => n.notifikationTypeId === id);
  if (found.length > 0) {
    return found[0];
  }
  return null;
}

async function abonner(id, notifikation, e) {
  if (!e.event) return; // Vi skal kun gøre noget, hvis en bruger har klikket.
  if (e.value === false) {
    if (changes.value[id]?.action === 'add') {
      delete changes.value[id];
    } else {
      changes.value[id] = { action: 'remove', id: notifikation.id };
    }
  }
  if (e.value === true) {
    if (changes.value[id]?.action === 'remove') {
      delete changes.value[id];
    } else {
      changes.value[id] = { action: 'add', notifikationTypeId: id };
    }
  }
}

function saveChanges() {
  const promises = [];
  for (const [id, change] of Object.entries(changes.value)) {
    if (change.action === 'remove') {
      promises.push(notifikationDatasource.store().remove(change.id));
    }
    if (change.action === 'add') {
      promises.push(
        notifikationDatasource.store().insert({
          notifikationTypeId: id,
        }),
      );
    }
  }
  return Promise.all(promises);
}

async function abonnerOld(id, notifikation, e) {
  if (!e.event) return; // Vi skal kun gøre noget, hvis en bruger har klikket.
  if (e.value === false && notifikation) {
    await notifikationDatasource.store().remove(notifikation.id);
  }
  if (e.value === true) {
    await notifikationDatasource.store().insert({
      notifikationTypeId: id,
    });
  }
  brugerNotifikationer.value = await notifikationDatasource.load();
  notifikationsTyper.value = await notifikationTypeDatasource.load();
}
</script>

<template>
  <LetCard title="Mine notifikationer" class="bg-sand-light">
    <div v-for="n in notifikationsTyper" :key="n.id">
      <DxCheckBox
        :value="getNotifikation(n.id) != null"
        @valueChanged="(e) => abonner(n.id, getNotifikation(n.id), e)"
      />
      {{ n.navn }}
    </div>
    <br />
    <DxButton text="Gem ændringer" type="default" @click="saveChanges"></DxButton>

    <div class="notifikation-info">
      Hvis du har tilmeldt dig, men ikke modtager notifikationer, kan det være, at de er blevet
      fanget i dit spamfilter.
    </div>
  </LetCard>
</template>

<style scoped>
.notifikation-info {
  padding-top: 20px;
  font-style: italic;
  font-size: 0.9em;
}
</style>
