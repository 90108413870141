import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let opslagStore;
export function getOpslagDatasource() {
  if (!opslagStore) {
    opslagStore = createDevextremeDatasource('/api/Opslag', 'id', {
      paginate: false,
    });

    opslagStore.loadByIdOrStinavn = async function (id) {
      if (Number.isNaN(Number(id))) {
        const result = await opslagStore.store().load({ filter: ['stinavn', '=', id] });
        return result.data[0];
      } else {
        return await opslagStore.store().byKey(id);
      }
    };
  }

  return opslagStore;
}

let aktiveOpslagStore;
export function getAktiveOpslagDatasource() {
  if (!aktiveOpslagStore) {
    aktiveOpslagStore = createDevextremeDatasource('/api/Opslag/aktive', 'id');
  }

  return aktiveOpslagStore;
}
