<script setup lang="ts">
type Sidebredde = 'smal' | 'normal' | 'bred' | 'max' | 'fuld';

// const props = defineProps({
//   sidebredde: { type: SideBredde, default: 'normal' },
// });

const props = defineProps<{
  sidebredde: Sidebredde;
}>();

function getReadingAreaStyle() {
  if (props.sidebredde === 'fuld') {
    return { flex: 1 };
  } else {
    return { width: sidebreddeDefinitions[props.sidebredde] };
  }
}

const sidebreddeDefinitions: { [key in Sidebredde]: string } = {
  smal: '800px',
  normal: '1000px',
  bred: '1200px',
  max: '1400px',
  fuld: '',
};
</script>

<template>
  <div class="flex-container">
    <div v-if="sidebredde !== 'fuld'" class="reading-area-side-spacing" />
    <div :style="getReadingAreaStyle()">
      <slot />
    </div>
    <div v-if="sidebredde !== 'fuld'" class="reading-area-side-spacing" />
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

@media screen {
  .reading-area-side-spacing {
    flex: 1;
  }
}
</style>
