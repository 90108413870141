<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    dataLabels: {
      // enabled: false,
      style: {
        fontSize: '11px',
        fontWeight: '400',
        colors: ['#000'],
      },
      offsetY: -20,

      formatter: function (value) {
        return formatNumber(value);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  const series = [
    {
      name: 'Top 5',
      data: store.indtjening.indtjeningProduktGrupper.map((item) => {
        return {
          x: item.indtjeningProduktGruppeNavn,
          y: item.top5GennemsnitligIndtjening,
        };
      }),
    },
    {
      name: 'Gns. indtjening',
      data: store.indtjening.indtjeningProduktGrupper.map((item) => {
        return {
          x: item.indtjeningProduktGruppeNavn,
          y: item.gennemsnitligIndtjening,
        };
      }),
    },
  ];
  return series;
}
</script>

<template>
  <div class="chart" v-if="store.indtjening.indtjeningProduktGrupper">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 350px;
}
</style>
