import { TableCell } from '@tiptap/extension-table-cell';

export const LetTableCell = TableCell.extend({
  allowGapCursor: true,
  addAttributes() {
    return {
      colspan: {
        default: 1,
        renderHTML: (attributes) => {
          if (attributes.colspan > 1)
            return {
              colspan: attributes.colspan,
            };
        },
      },
      rowspan: {
        default: 1,
        renderHTML: (attributes) => {
          if (attributes.rowspan > 1)
            return {
              rowspan: attributes.rowspan,
            };
        },
      },

      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth');
          return colwidth ? [parseInt(colwidth, 10)] : null;
        },
        renderHTML: () => {
          return {};
        },
      },

      width: {
        default: null,
        parseHTML: (element) => element.getAttribute('width'),
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },
    };
  },
});
