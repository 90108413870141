import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSearchStore = defineStore('search', () => {
  const filter = ref('');
  const vejledningListe = ref([]);
  const nyhedListe = ref([]);
  const filListe = ref([]);
  const loading = ref(false);

  async function doSearch(searchString) {
    if (searchString === filter.value) {
      return;
    }

    filter.value = searchString;

    if (!filter.value) {
      vejledningListe.value = [];
      nyhedListe.value = [];
      filListe.value = [];
      return;
    }

    loading.value = true;
    const response = await axios.get(getAppConfig().apiRoot + '/api/search', {
      params: {
        criteria: filter.value,
      },
    });
    loading.value = false;
    if (response.data.opslag) {
      vejledningListe.value = response.data.opslag.filter((o) => o.opslagType !== 'Nyhed');
      nyhedListe.value = response.data.opslag.filter((o) => o.opslagType === 'Nyhed');
    } else {
      vejledningListe.value = [];
      nyhedListe.value = [];
    }
    if (response.data.filer) {
      filListe.value = response.data.filer;
    } else {
      filListe.value = [];
    }
  }

  return { filter, loading, vejledningListe, filListe, nyhedListe, doSearch };
});
