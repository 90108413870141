<script setup>
import LivrenteIndbetalingChart from '@/pages/kvartalsrapport/charts/LivrenteIndbetalingChart.vue';
import LivrenteNysalgChart from '@/pages/kvartalsrapport/charts/LivrenteNysalgChart.vue';
import LivrenteNysalgModTop5Chart from '@/pages/kvartalsrapport/charts/LivrenteNysalgModTop5Chart.vue';
import LivrenteBestandChart from '@/pages/kvartalsrapport/charts/LivrenteBestandChart.vue';
import LivrenteBestandPrMedarbejderChart from '@/pages/kvartalsrapport/charts/LivrenteBestandPrMedarbejderChart.vue';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import LetLoadIndicator from '@/letapps-vue/components/LetLoadIndicator.vue';
import LivrenteSalgPrUgeChart from '@/pages/kvartalsrapport/charts/LivrenteSalgPrUgeChart.vue';
import LivrenteSalgFordelingStykChart from '@/pages/kvartalsrapport/charts/LivrenteSalgFordelingStykChart.vue';
import LivrenteSalgFordelingBeloebChart from '@/pages/kvartalsrapport/charts/LivrenteSalgFordelingBeloebChart.vue';
import LivrenteSalgFordeling30pctChart from '@/pages/kvartalsrapport/charts/LivrenteSalgFordeling30pctChart.vue';

defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();
</script>

<template>
  <div class="titel">Letsikring af indtægt ved pension</div>
  <div class="flex-container container" v-if="store.udvikling">
    <div class="flex-1">
      <div class="chart-titel">Hvor meget er der indbetalt i mio. kr.?</div>
      <LivrenteIndbetalingChart :print="print" />
      <div class="chart-titel">Hvor stort er nysalget i mio. kr.?</div>
      <LivrenteNysalgChart :print="print" />
      <div class="chart-titel">
        Hvordan er salget i mio. kr. pr. 100 medarbejdere – sammenlignet med top 5
      </div>
      <LivrenteNysalgModTop5Chart :print="print" />
    </div>
    <div class="flex-1">
      <div class="chart-titel">Hvor stor er bestanden i mio. kr.?</div>
      <LivrenteBestandChart :print="print" />
      <div class="chart-titel">Hvor stor er bestanden i mio. kr. pr. 100 medarbejdere?</div>
      <LivrenteBestandPrMedarbejderChart :print="print" />
      <div class="chart-titel">Hvordan er salget sammenlignet med sidste års salg i mio. kr.?</div>
      <LivrenteSalgPrUgeChart :print="print" />
    </div>
    <div class="flex-1">
      <div class="chart-titel">Nysalg stk.</div>
      <LivrenteSalgFordelingStykChart :print="print" />
      <div class="chart-titel">Nysalg mio kr.</div>
      <LivrenteSalgFordelingBeloebChart :print="print" />
      <div class="chart-titel">Så stor en del af nysalget er fra 30 %-ordninger</div>
      <LivrenteSalgFordeling30pctChart :print="print" />
    </div>
  </div>
  <div v-else>
    <LetLoadIndicator :visible="true" />
    <div>Henter udvikling...</div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.flex-1 {
  min-width: 250px;
}
</style>
