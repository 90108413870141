<script setup>
import LetPage from '@/letapps-vue/components/LetPage';
import LetCard from '@/letapps-vue/components/LetCard';
import OpslagListe from '@/components/opslag/OpslagListe.vue';
import LetReadingArea from '@/letapps-vue/components/LetReadingArea.vue';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import SalgsUnderstoettelseSection from '@/components/kontakt/SalgsUnderstoettelseSection.vue';
import { authState } from '@/letapps-vue/utils/auth';

function getHentFilLink(id) {
  return `javascript:hentFil('${getAppConfig().apiRoot}/api/Fil/${id}', '_blank');`;
}

function onToggle(e) {
  const targetItem = e.target.closest('.let-accordion-item');
  const container = e.target.closest('.let-accordion-container');
  const allItems = container.querySelectorAll('.let-accordion-item');

  //Close other items
  allItems.forEach((item) => {
    if (item !== targetItem) {
      item.classList.remove('let-accordion-item-expanded');
    }
  });

  //Toggle current item
  targetItem.classList.toggle('let-accordion-item-expanded');
}
</script>

<template>
  <LetPage>
    <let-reading-area sidebredde="max">
      <div class="flex-container">
        <div class="flex-container-column flex-2">
          <h1>Ledersiden</h1>
          <OpslagListe opslag-type="Nyhed" :maalgruppeId="getAppConfig().lederMaalgruppeId" />
        </div>
        <div class="spacer"></div>
        <div class="flex-container-column flex-1">
          <h1>&nbsp;</h1>
          <LetCard class="bg-sand">
            <div class="let-accordion-container">
              <div class="let-accordion-item">
                <div class="let-accordion-item-header" @click="onToggle">
                  <div class="let-accordion-item-header-content">Værditilbud</div>
                  <div class="let-accordion-item-header-open-icon">
                    <i class="dx-icon-add"></i>
                  </div>
                </div>
                <div class="let-accordion-item-content">
                  <div class="let-accordion-item-content-inner">
                    <div class="let-accordion-item-content-styling">
                      <div>
                        <a :href="getHentFilLink(32)"
                          >Letpensions værditilbud Salg og rådgivning (PDF)</a
                        >
                        <br />
                        <br />
                        <a :href="getHentFilLink(33)"
                          >PFA-fordele til kunder og pengeinstitutter (PDF)</a
                        >
                        <br />
                        <br />
                        <a :href="getHentFilLink(94)"
                          >PFA-fordel Fortrinsret til PFA Boliger (PDF)</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="let-accordion-item">
                <div class="let-accordion-item-header" @click="onToggle">
                  <div class="let-accordion-item-header-content">Betalingsstrømme</div>
                  <div class="let-accordion-item-header-open-icon">
                    <i class="dx-icon-add"></i>
                  </div>
                </div>
                <div class="let-accordion-item-content">
                  <div class="let-accordion-item-content-inner">
                    <div class="let-accordion-item-content-styling">
                      <div>
                        <a :href="getHentFilLink(117)"
                          >Betalingsstrømme mellem pengeinstitutter, Letpension og PFA Pension
                          (PDF)</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="let-accordion-item">
                <div class="let-accordion-item-header" @click="onToggle">
                  <div class="let-accordion-item-header-content">Artikler og budskaber</div>
                  <div class="let-accordion-item-header-open-icon">
                    <i class="dx-icon-add"></i>
                  </div>
                </div>
                <div class="let-accordion-item-content">
                  <div class="let-accordion-item-content-inner">
                    <div class="let-accordion-item-content-styling">
                      <div>
                        <a href="https://letguide.letpension.dk/opslag/890"
                          >Katalog: artikler og budskaber om pension</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LetCard>
          <LetCard class="bg-sand">
            <div class="section-header">Temaside</div>
            <div>
              <a href="https://letguide.letpension.dk/opslag/859">Nyt sygdomsprodukt</a>
            </div>
            <div style="margin-top: 6px">
              <a href="https://letguide.letpension.dk/opslag/937">Nyt profildesign</a>
            </div>
          </LetCard>
          <LetCard class="bg-sand" v-if="authState.bruger.organisation.kamId">
            <SalgsUnderstoettelseSection :kamId="authState.bruger.organisation.kamId" />
          </LetCard>
        </div>
      </div>
    </let-reading-area>
  </LetPage>
</template>

<style scoped lang="scss">
@import '@/css/variables.letguide.scss';

.flex-container {
  gap: 0;
  padding-top: 20px;
}

.flex-container-column {
  gap: 30px;
}

.spacer {
  width: 80px;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1600px) {
  .spacer {
    width: 40px;
  }
}

.let-accordion-container {
  border-bottom: 1px solid $letpension-grey;
  // margin-bottom: 10px;
}
.let-accordion-item-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;
}

.let-accordion-item-content-inner {
  overflow: hidden;
}

.let-accordion-item-content-styling {
  border-top: 1px solid $letpension-grey;
  padding: 15px 5px 30px;
}

.let-accordion-item-expanded {
  .let-accordion-item-content {
    grid-template-rows: 1fr;
  }
}

.let-accordion-item-header {
  font-weight: 600;
  border-top: 1px solid $letpension-grey;
  padding: 15px 5px;
  display: flex;
}

.let-accordion-item-header {
  cursor: pointer;
}

.let-accordion-item-header-content {
  font-size: 1.15rem;
  color: #333333;
  flex: 1;
}

.let-accordion-item-header-open-icon {
  width: 20px;
  transform: translateY(10%) translateX(0%) rotate(0deg);
  transition: transform 200ms ease-out;

  i {
    font-size: 1.25rem;
  }
}

.let-accordion-item-expanded {
  .let-accordion-item-header-open-icon {
    transform: translateY(15%) translateX(-15%) rotate(45deg);
  }

  .let-accordion-item-header-close-icon {
    display: block;
  }
}
</style>
