<script setup>
import LetpensionLogo from '@/letapps-vue/assets/letpension-logo.svg';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore';

const store = useKvartalsrapportStore();
</script>

<template>
  <div class="flex-container container">
    <div class="left flex-1">
      <div class="title">Aktivitet</div>
      <div class="title">{{ store.rapport?.periode }} {{ store.rapport?.aar }}</div>
      <div class="pi">{{ store.pengeinstitut?.navn }}</div>
      <div class="logo">
        <img :src="LetpensionLogo" alt="Letpension logo" class="logo" />
      </div>
    </div>
    <div class="right flex-1"></div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.container {
  height: 100%;
}

.left {
  padding-top: 20vh;
}

.title {
  font-family: Marcia, 'Times New Roman', serif;
  font-size: 100px;
  font-weight: 500;
  line-height: 1;
}

.pi {
  margin-top: 20px;
  color: $letpension-orange;
  font-size: 40px;
  min-height: 64px;
}

.logo {
  margin-top: 80px;
  width: 60%;
}

.right {
  background-color: $letpension-blue;
  background-image: url(../../letapps-vue/assets/letpension-facet.svg);
  background-repeat: no-repeat;
  background-size: 6000px;
  background-position: 50% 50%;
}
</style>
