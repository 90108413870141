import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import axios from 'axios';
import type { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { type Options } from 'devextreme/data/data_source';

export interface LetCustomStore extends CustomStore {
  userData: any;
}

export interface LetDataSource extends DataSource {
  save: (values: { [key: string]: any }) => {};
  loadIfEmpty: () => {};
}

export function createDevextremeDatasource(
  URI: string,
  key: string,
  datasourceOptions?: Options<any, any>,
) {
  const { apiRoot } = getAppConfig();
  const URL = `${apiRoot}${URI}`;

  const store = new CustomStore({
    key,
    byKey: (id) =>
      axios.get(`${URL}/${id}`).then((response) => {
        return response.data;
      }),
    load: (loadOptions) => {
      const paramsString = processLoadOptions({
        ...loadOptions,
        userData: store.userData,
      });

      return axios.get(URL + paramsString).then((response) => {
        return response.data;
      });
    },
    insert: (values) => axios.post(URL, values),
    update: (id, values) => axios.put(`${URL}/${id}`, { id, ...values }),
    remove: (id) => axios.delete(`${URL}/${id}`),
  }) as LetCustomStore;
  store.userData = {};

  const datasource = new DataSource({ store, ...datasourceOptions }) as LetDataSource;
  datasource.save = (values: { [key: string]: any }) => {
    if (values[key]) {
      return datasource.store().update(values[key], values);
    } else {
      return datasource.store().insert(values);
    }
  };

  datasource.loadIfEmpty = async () => {
    if (datasource.items().length > 0) return datasource.items();
    else return datasource.load();
  };

  return datasource;
}

function processLoadOptions(loadOptions: LoadOptions) {
  //Det ser ud til at Devextreme.AspNet.Data server-side ikke forstår searchExpr osv. parametre
  //Men den forstår filter parameteren, så vi laver søgningen om til "filter" format
  if (loadOptions.searchExpr && !loadOptions.filter) {
    loadOptions.filter = [
      loadOptions.searchExpr,
      loadOptions.searchOperation,
      loadOptions.searchValue,
    ];
    loadOptions.searchExpr = undefined;
    loadOptions.searchOperation = undefined;
    loadOptions.searchValue = undefined;
  }

  let params = '?';

  const keys: any[] = [
    'filter',
    'group',
    'groupSummary',
    'parentIds',
    'requireGroupCount',
    'requireTotalCount',
    'searchExpr',
    'searchOperation',
    'searchValue',
    'select',
    'sort',
    'skip',
    'take',
    'totalSummary',
    'userData',
  ];
  keys.forEach((i) => {
    if (Object.prototype.hasOwnProperty.call(loadOptions, i)) {
      params += `${i}=${JSON.stringify(loadOptions[i as keyof LoadOptions])}&`;
    }
  });
  params = params.slice(0, -1);

  return params;
}

export function getJsonFromBlob(b: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function () {
      const result = this.result;
      if (typeof result === 'string') {
        const json = JSON.parse(result);
        resolve(json);
      }
    };
    reader.readAsText(b);
  });
}
