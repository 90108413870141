<script setup>
import LetLoadIndicator from '@/letapps-vue/components/LetLoadIndicator.vue';
import SearchResultFiler from './SearchResultFiler.vue';
import SearchResultNyheder from './SearchResultNyheder.vue';
import SearchResultVejledninger from './SearchResultVejledninger.vue';

import { useSearchStore } from '@/stores/searchStore';

const searchStore = useSearchStore();

function clickTab(e) {
  const button = e.target;
  const tabsContainer = button.closest('.let-tabs');

  const buttons = tabsContainer.querySelectorAll('.let-tab-button');
  buttons.forEach((tab) => {
    if (tab.getAttribute('tabid') === button.getAttribute('tabid')) {
      tab.classList.add('active');
    } else {
      tab.classList.remove('active');
    }
  });

  let tabs = tabsContainer.querySelectorAll('.let-tab');
  tabs.forEach((tab) => {
    if (tab.getAttribute('tabid') === button.getAttribute('tabid')) {
      tab.classList.add('active');
    } else {
      tab.classList.remove('active');
    }
  });
}

function getTabActive(tab) {
  if (tab === 'tabVejledninger') {
    if (searchStore.vejledningListe.length > 0) return 'active';
  }
  if (tab === 'tabFiler') {
    if (searchStore.vejledningListe.length === 0 && searchStore.filListe.length > 0)
      return 'active';
  }
  if (tab === 'tabNyheder') {
    if (
      searchStore.vejledningListe.length === 0 &&
      searchStore.filListe.length === 0 &&
      searchStore.nyhedListe.length > 0
    )
      return 'active';
  }

  return '';
}
</script>

<template>
  <div class="loading" v-if="searchStore.loading">
    <LetLoadIndicator :visible="searchStore.loading" text="Søger..." />
  </div>
  <div v-else class="let-tabs">
    <div class="let-tabs-buttons">
      <div class="let-tab-button-container">
        <div
          class="let-tab-button"
          :class="getTabActive('tabVejledninger')"
          tabid="tabVejledninger"
          @click="clickTab"
        >
          Vejledninger, Q&A's mv. ({{ searchStore.vejledningListe.length }})
        </div>
      </div>
      <div class="let-tab-button-container">
        <div
          class="let-tab-button"
          :class="getTabActive('tabFiler')"
          tabid="tabFiler"
          @click="clickTab"
        >
          Blanketter, breve og præsentationer mv. ({{ searchStore.filListe.length }})
        </div>
      </div>
      <div class="let-tab-button-container">
        <div
          class="let-tab-button"
          :class="getTabActive('tabNyheder')"
          tabid="tabNyheder"
          @click="clickTab"
        >
          Nyheder ({{ searchStore.nyhedListe.length }})
        </div>
      </div>
    </div>
    <div class="let-tab" :class="getTabActive('tabVejledninger')" tabid="tabVejledninger">
      <SearchResultVejledninger :opslagListe="searchStore.vejledningListe" />
    </div>
    <div class="let-tab" :class="getTabActive('tabFiler')" tabid="tabFiler">
      <SearchResultFiler :filListe="searchStore.filListe" />
    </div>
    <div class="let-tab" :class="getTabActive('tabNyheder')" tabid="tabNyheder">
      <SearchResultNyheder :opslagListe="searchStore.nyhedListe" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.loading {
  text-align: center;
}

.let-tabs-buttons {
  font-size: 1rem;
}

.let-tab {
  background-color: white;
}

.vis-flere {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vis-flere-button {
  width: 50px;
  height: 50px;
  cursor: pointer;

  i {
    position: relative;
    top: 3px;
    font-size: 2.25rem;
  }
}

.vis-flere-tekst {
  font-weight: 500;
  margin-top: 10px;
}

.status-row {
  padding: 2px;
}

.beskeder-link {
  text-align: right;
}

.ingen-opslag {
  padding: 20px;
}

.under-list {
  height: 150px;
  text-align: center;
}

.opslag-container {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;

  &:first-child {
    margin-top: 0;
  }
}

.opslag-liste-flere {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 140px;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(transparent, #fff) left repeat;
  }
}

.miniature-container {
  width: 210px;
  height: 120px;
  text-align: center;
  background-color: $letpension-sand;
}

.opslag-arrow {
  width: 16px;
  background-color: $letpension-grey;
  mask-image: url('../../letapps-vue/icons/arrow.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center left;
  margin-right: 5px;
}

.opslag-miniature {
  width: 210px;
  max-height: 120px;
  object-fit: cover;
}

.opslag-indhold {
  flex: 1;
  padding: 0;
}

.opslag-item {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 12px;
  }
}

.opslag-dato {
  margin-top: 3px;
  color: $letpension-grey;
  font-weight: 500;
  margin-bottom: 5px;
}

.opslag-overskrift {
  font-size: 1rem;
  font-weight: 600;
  color: $letpension-orange;
  margin-bottom: 3px;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.opslag-manchet {
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
}

.opslag-tags {
  display: flex;
  gap: 4px;
}

.opslag-tag {
  padding: 0 4px;
  border-radius: 3px;
  font-weight: 500;
  color: white;
}

:deep(.dx-tabpanel > .dx-tabpanel-container > .dx-multiview-wrapper) {
  border: none;
}
</style>
