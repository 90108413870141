<script setup>
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';

const props = defineProps({
  updateAttributes: Function,
});

const styles = [
  {
    value: 'let-tiptap-table',
    text: 'Letpension design',
  },
  {
    value: 'let-tiptap-table-clean',
    text: 'Ikke farvet',
  },
];

function onSelectStyle(e) {
  const { value } = e.itemData;
  props.updateAttributes({ class: value });
}
</script>

<template>
  <DxDropDownButton
    :items="styles"
    :dropDownOptions="{ width: 180 }"
    icon="palette"
    displayExpr="text"
    keyExpr="value"
    @item-click="onSelectStyle"
    :elementAttr="{ title: 'Vælg tabel design' }"
  >
  </DxDropDownButton>
</template>

<style scoped lang="scss"></style>
