<script setup>
import {
  getDefaultOptions,
  calcAxisMinValue,
  calcAxisMaxValue,
} from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import dayjs from 'dayjs';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  produktPropertyNavn: { type: String, require: true },
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    yaxis: {
      ...getDefaultOptions(props.print).yaxis,
      min: calcAxisMinValue(getSeries()),
      max: calcAxisMaxValue(getSeries()),
    },
    dataLabels: {
      enabled: false,
      formatter: function (value) {
        return formatNumber(value);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  const bestandTotaler = store.udvikling.bestandTotalerVaegtet;
  const detteAar = bestandTotaler.slice(-12);
  const forrigeAar = bestandTotaler.slice(-24).slice(0, 12);

  const series = [
    {
      name: 'Forrige år',
      data: forrigeAar.map((x) => {
        return {
          x: `${dayjs(x.bestandDato).subtract(1, 'day').format('MMM')}`,
          y: x[props.produktPropertyNavn],
        };
      }),
    },
    {
      name: 'Dette år',
      data: detteAar.map((x) => {
        return {
          x: `${dayjs(x.bestandDato).subtract(1, 'day').format('MMM')}`,
          y: x[props.produktPropertyNavn],
        };
      }),
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart" v-if="store.udvikling.bestandTotalerVaegtet">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
