<script setup lang="ts">
import { ROUTE_OPSLAG } from '@/router/routeNames';
import { formatDate } from 'devextreme/localization';

defineProps({
  opslagListe: { type: Object },
});

function getTagColor(tagType: string) {
  if (tagType === 'Indhold') return 'bg-green';
  if (tagType === 'Vejledning') return 'bg-blue';
  if (tagType === 'Produktside') return 'bg-yellow';
  if (tagType === 'Q&A') return 'bg-grey';
  return 'bg-green';
}
</script>

<template>
  <div v-for="opslag in opslagListe" :key="opslag.id" class="opslag-container">
    <router-link :to="`${ROUTE_OPSLAG}/${opslag.stinavn || opslag.masterId}`" class="big-link">
      <div class="opslag-item flex-container">
        <div class="opslag-indhold">
          <div class="opslag-dato">
            {{ formatDate(new Date(opslag.publiceringsTidspunkt), 'dd-MM-yyyy') }}
          </div>
          <div class="opslag-tags">
            <div class="opslag-tag" :class="getTagColor(opslag.opslagType)">
              {{ opslag.opslagType }}
            </div>
          </div>

          <div class="opslag-overskrift">
            {{ opslag.overskrift }}
          </div>
          <div class="opslag-manchet">
            {{ opslag.manchet }}
          </div>
        </div>
        <div class="opslag-arrow"></div>
      </div>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.let-tabs-buttons {
  font-size: 1rem;
}

.let-tab {
  background-color: white;
}

.vis-flere {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vis-flere-button {
  width: 50px;
  height: 50px;
  cursor: pointer;

  i {
    position: relative;
    top: 3px;
    font-size: 2.25rem;
  }
}

.vis-flere-tekst {
  font-weight: 500;
  margin-top: 10px;
}

.status-row {
  padding: 2px;
}

.beskeder-link {
  text-align: right;
}

.ingen-opslag {
  padding: 20px;
}

.under-list {
  height: 150px;
  text-align: center;
}

.opslag-container {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;

  &:first-child {
    margin-top: 0;
  }
}

.opslag-liste-flere {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 140px;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(transparent, #fff) left repeat;
  }
}

.miniature-container {
  width: 210px;
  height: 120px;
  text-align: center;
  background-color: $letpension-sand;
}

.opslag-arrow {
  width: 16px;
  background-color: $letpension-grey;
  mask-image: url('../../letapps-vue/icons/arrow.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center left;
  margin-right: 5px;
}

.opslag-miniature {
  width: 210px;
  max-height: 120px;
  object-fit: cover;
}

.opslag-indhold {
  flex: 1;
  padding: 0;
}

.opslag-item {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 12px;
  }
}

.opslag-dato {
  margin-top: 3px;
  color: $letpension-grey;
  font-weight: 500;
  margin-bottom: 3px;
}

.opslag-overskrift {
  font-size: 1rem;
  font-weight: 600;
  color: $letpension-orange;
  margin-bottom: 3px;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.opslag-manchet {
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
}

.opslag-tags {
  margin-bottom: 3px;
  display: flex;
  gap: 4px;
}

.opslag-tag {
  padding: 0 4px;
  border-radius: 3px;
  font-weight: 500;
  color: white;
}

:deep(.dx-tabpanel > .dx-tabpanel-container > .dx-multiview-wrapper) {
  border: none;
}
</style>
