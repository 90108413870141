<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { formatNumber } from '../kvartalsrapportUtil';
import dayjs from 'dayjs';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    yaxis: {
      show: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      style: {
        fontSize: '11px',
        fontWeight: '400',
        colors: ['#000'],
      },
      offsetY: -20,

      formatter: function (value) {
        return formatNumber(value, 1, 1);
      },
    },
  });
}

function getSeries() {
  const series = [
    {
      data: store.indtjening.indtjeningPerioder.map((x) => {
        const firstDay = dayjs(x.periodeNavn, 'DD-MM-YYYY');
        const lastDayInQuarter = firstDay.subtract(1, 'day');
        return {
          x: `Q${lastDayInQuarter.quarter()}-${lastDayInQuarter.year()}`,
          y: x.samletIndtjening / 1000000,
        };
      }),
    },
  ];
  return series;
}
</script>

<template>
  <div class="chart" v-if="store.indtjening.indtjeningPerioder">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 170px;
}
</style>
