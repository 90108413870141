import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { ref } from 'vue';
import { getPengeinstitutDatasource } from '@/data/pengeinstitut.js';
import { getKvartalsrapportDatasource } from '@/data/kvartalsrapport.js';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { getFinansPlusDatasource } from '@/data/finansPlus';
import { getFaktiskeIndbetalingerDatasource } from '@/data/faktiskeIndbetalinger';

const loadErrors = ref([]);
const rapport = ref();
const pengeinstitut = ref();
const indtjening = ref();
const aktivitet = ref();
const udvikling = ref();
const rangliste = ref();
const finansPlus = ref();
const faktiskeIndbetalinger = ref();

export const useKvartalsrapportStore = defineStore('kvartalsrapport', () => {
  async function loadData(rapportId, regnr) {
    loadErrors.value = [];

    await fetchPengeinstitut(regnr);
    await fetchRapport(rapportId);
    const rapportDato = dayjs(rapport.value.slutDato).add(1, 'day').format('YYYY-MM-DD');
    fetchData('indtjening', indtjening, rapportDato, regnr).then();
    fetchData('aktivitet', aktivitet, rapportDato, regnr).then();
    fetchData('udvikling', udvikling, rapportDato, regnr).then(() => {
      udvikling.value.bestandTotaler.sort((a, b) => (a.bestandDato > b.bestandDato ? 1 : -1));
      udvikling.value.bestandTotalerVaegtet.sort((a, b) =>
        a.bestandDato > b.bestandDato ? 1 : -1,
      );
    });
    fetchData('rangliste', rangliste, rapportDato, regnr).then();
    fetchFinansPlus(pengeinstitut.value.pengeinstitutId).then();
    fetchFaktiskeIndbetalinger(pengeinstitut.value.pengeinstitutId).then();
  }

  async function saveRapport() {
    const kvartalsrapportDatasource = getKvartalsrapportDatasource();
    try {
      const response = await kvartalsrapportDatasource
        .store()
        .update(rapport.value.id, rapport.value);
      rapport.value = response.data;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    pengeinstitut,
    rapport,
    indtjening,
    aktivitet,
    udvikling,
    rangliste,
    finansPlus,
    faktiskeIndbetalinger,
    loadData,
    loadErrors,
    saveRapport,
  };
});

async function fetchRapport(rapportId) {
  const kvartalsrapportDatasource = getKvartalsrapportDatasource();
  try {
    rapport.value = await kvartalsrapportDatasource.store().byKey(rapportId);
  } catch (error) {
    loadErrors.value.push(error);
    console.error(error);
  }
}

async function fetchPengeinstitut(regnr) {
  const pengeinstitutDatasource = getPengeinstitutDatasource();
  await pengeinstitutDatasource.loadIfEmpty();
  const piList = pengeinstitutDatasource.items();
  pengeinstitut.value = piList.filter((pi) => pi.regnr === regnr)[0];
  if (regnr === '0000') pengeinstitut.value.pengeinstitutId = 139;
  if (regnr === '8079') pengeinstitut.value.pengeinstitutId = 138;
  if (regnr === '7670') pengeinstitut.value.pengeinstitutId = 59;
  if (regnr === '7780') pengeinstitut.value.pengeinstitutId = 65;
  if (regnr === '6868') pengeinstitut.value.pengeinstitutId = 54;
  if (regnr === '7500') pengeinstitut.value.pengeinstitutId = 36;
  if (regnr === '7858') pengeinstitut.value.pengeinstitutId = 37;
  if (regnr === '6880') pengeinstitut.value.pengeinstitutId = 96;
  if (regnr === '9335') pengeinstitut.value.pengeinstitutId = 81;
  if (regnr === '7320') pengeinstitut.value.pengeinstitutId = 15;
  if (regnr === '0400') pengeinstitut.value.pengeinstitutId = 47;
  if (regnr === '6520') pengeinstitut.value.pengeinstitutId = 44;
  if (regnr === '6140') pengeinstitut.value.pengeinstitutId = 53;
  if (regnr === '7930') pengeinstitut.value.pengeinstitutId = 40;
  pengeinstitut.value.finansPlus = true;
  if (regnr === '0400' || regnr === '7780') {
    pengeinstitut.value.finansPlus = false;
  }
}

async function fetchFinansPlus(pengeinstitutId) {
  const ds = getFinansPlusDatasource();
  ds.filter('pengeinstitutId', '=', pengeinstitutId);
  ds.sort([{ selector: 'fraDato', desc: false }]);
  await ds.load();
  finansPlus.value = ds.items();
}

async function fetchFaktiskeIndbetalinger(pengeinstitutId) {
  const ds = getFaktiskeIndbetalingerDatasource();
  ds.filter('pengeinstitutId', '=', pengeinstitutId);
  ds.sort([{ selector: 'fraDato', desc: false }]);
  await ds.load();
  faktiskeIndbetalinger.value = ds.items();
}

async function fetchData(type, stateVariable, dato, regnr) {
  try {
    const response = await axios.get(`${getAppConfig().apiRoot}/api/lpnoegletal/${type}`, {
      params: { dato: dato, regnr: regnr },
    });
    stateVariable.value = response.data;
  } catch (error) {
    loadErrors.value.push(error);
  }
}
