import dayjs from 'dayjs';

export const monthNames = [
  'Januar',
  'Februar',
  'Marts',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'December',
];

export const periodeTypeOptions = [
  {
    value: 'uge',
    text: 'Uge',
  },
  {
    value: 'ÅTD',
    text: 'År til dato',
  },
];

export const indevaerendeAar = new Date().getFullYear();

export function getAarOptions() {
  const aarOptions = [];
  for (let i = 2023; i <= indevaerendeAar; i++) {
    aarOptions.push({ value: i });
  }
  return aarOptions;
}

export function getUgeOptions(aar: number) {
  const ugeOptions = [];
  for (let i = 1; i <= dayjs(aar + '-01-01').isoWeeksInYear(); i++) {
    if (!(aar === indevaerendeAar && i > dayjs().subtract(7, 'day').isoWeek())) {
      ugeOptions.push({ value: i, text: `Uge ${i}` });
    }
  }
  return ugeOptions;
}

export function getMaanedOptions(aar: number) {
  const options = [];

  for (let i = 0; i <= 11; i++) {
    if (!(aar === indevaerendeAar && i > dayjs().month() - 1)) {
      options.push({
        value: `${i + 1}`,
        text: monthNames[i],
      });
    }
  }

  return options;
}
