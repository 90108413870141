<script setup>
import { computed } from 'vue';
import LetPage from '@/letapps-vue/components/LetPage';
import { useRoute } from 'vue-router';
import FilDetail from '@/pages/filer/FilerDetail.vue';

const route = useRoute();
const filId = computed(() => Number(route.params.id));
</script>

<template>
  <LetPage fit>
    <FilDetail :filId="filId" />
  </LetPage>
</template>

<style scoped lang="scss"></style>
