export function getDataUrlFromImageFile(
  imageFile: File,
  maxWidth: number,
  maxHeight: number,
): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = document.createElement('img');
      img.onload = function () {
        let width = img.width;
        let height = img.height;

        // Change the resizing logic
        if (width > height) {
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        const dataUrl = canvas.toDataURL(imageFile.type);
        resolve(dataUrl);
      };
      img.src = <string>e.target?.result;
    };
    reader.readAsDataURL(imageFile);
  });
}

export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

export function blobToDataURL(blob: Blob) {
  return new Promise((resolve) => {
    const a = new FileReader();
    a.onload = function (e) {
      resolve(e.target?.result);
    };
    a.readAsDataURL(blob);
  });
}

// export function getImageSizeFromFile(imageFile: File) {
//   return new Promise((resolve) => {
//     const _URL = window.URL || window.webkitURL;
//     const img = new Image();
//     img.src = _URL.createObjectURL(imageFile);
//     img.onload = function () {
//       resolve({ width: this.width, height: this.height });
//     };
//   });
// }

const IMAGE_CACHE: { [key: string]: {} } = {};

export function resolveImg(src: string) {
  return new Promise((resolve, reject) => {
    const result = {
      complete: false,
      width: 0,
      height: 0,
      src,
    };

    if (!src) {
      reject(result);
      return;
    }

    // cache
    if (IMAGE_CACHE[src]) {
      resolve({ ...IMAGE_CACHE[src] });
      return;
    }

    const img = new Image();

    img.onload = () => {
      result.width = img.width;
      result.height = img.height;
      result.complete = true;

      IMAGE_CACHE[src] = { ...result };
      resolve(result);
    };

    img.onerror = () => {
      reject(result);
    };

    img.src = src;
  });
}
