<script setup>
import { getFaktiskeIndbetalingerDatasource } from '@/data/faktiskeIndbetalinger';
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import dayjs from 'dayjs';
import { watchEffect, ref } from 'vue';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const chart = ref();
const store = useKvartalsrapportStore();

let forrigeAar = [];
let detteAar = [];

function getOptions() {
  const series = getSeries();
  const values = series[0].data.map((d) => d.y);
  values.push(...series[1].data.map((d) => d.y));
  const maxValue = Math.max(...values);
  let decimals = 1;
  if (maxValue > 500) decimals = 0;
  return Object.assign({}, getDefaultOptions(props.print), {
    dataLabels: {
      // enabled: false,
      style: {
        fontSize: '11px',
        fontWeight: '400',
        colors: ['#000'],
      },
      offsetY: -20,
      formatter: function (value) {
        return formatNumber(value, decimals, decimals);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  const indbetalinger = store.faktiskeIndbetalinger;
  forrigeAar = indbetalinger.slice(-8).slice(0, 4);
  detteAar = indbetalinger.slice(-4);

  const series = [
    {
      name: 'Forrige år',
      data: forrigeAar.map((x, i) => {
        return {
          x: `Q${dayjs(x.tilDato).quarter()}-${dayjs(x.tilDato).format('YY')}/${dayjs(detteAar[i].tilDato).format('YY')}`,
          y: x.indbetalt / 1000000,
        };
      }),
    },
    {
      name: 'Dette år',
      data: detteAar.map((x, i) => {
        return {
          x: `Q${dayjs(x.tilDato).quarter()}-${dayjs(forrigeAar[i].tilDato).format('YY')}/${dayjs(x.tilDato).format('YY')}`,
          y: x.indbetalt / 1000000,
        };
      }),
    },
  ];

  return series;
}

// function getSeries() {
//   const series = [
//     {
//       name: 'Forrige år',
//       data: [],
//     },
//     {
//       name: 'Dette år',
//       data: [],
//     },
//   ];

//   return series;
// }
</script>

<template>
  <div class="chart" v-if="store.faktiskeIndbetalinger">
    <apexchart
      ref="chart"
      height="100%"
      type="bar"
      :options="getOptions()"
      :series="getSeries()"
    ></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
