import { dataURItoBlob } from '@/letapps-vue/utils/imageUtils';
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';

export async function uploadOpslagFil(dataUrl, opslagId) {
  const formData = new FormData();
  formData.set('files', dataURItoBlob(dataUrl));
  const response = await axios.post(
    getAppConfig().apiRoot + '/api/Opslag/' + opslagId + '/fil',
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return getAppConfig().apiRoot + '/api/OpslagFiler/' + response.data.id;
}

export async function getByKey(key) {
  try {
    const response = await axios.get(getAppConfig().apiRoot + '/api/Opslag/getbykey/' + key);
    return response.data;
  } catch (e) {
    console.error(e);
  }
}
