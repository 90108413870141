<script setup lang="ts">
import { DxButton } from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { hasRettighed } from '@/letapps-vue/utils/auth';

const props = defineProps({
  to: { type: String },
  rettighed: { type: String },
  // text: { type: String },
});

const router = useRouter();
function go() {
  if (props.to) {
    router.push(props.to);
  }
}
</script>

<template>
  <DxButton
    v-if="hasRettighed(rettighed) || !rettighed"
    class="let-floating-action-button"
    @click="go"
  />
</template>

<style lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

.let-floating-action-button {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.let-floating-action-button-container {
  position: relative;
}

.let-floating-action-button-container:hover {
  .let-floating-action-button {
    opacity: 1;
  }
}
</style>
