<script setup>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { computed, onMounted, reactive, ref } from 'vue';
import { NodeSelection } from 'prosemirror-state';
import { clamp } from '@/letapps-vue/utils/shared';
import { blobToDataURL } from '@/letapps-vue/utils/imageUtils';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import axios from 'axios';
import TiptapComponentItemWrapper from '@/tiptap/component/TiptapComponentItemWrapper.vue';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxForm, DxItem } from 'devextreme-vue/form';
import { DxTextBox } from 'devextreme-vue/text-box';

const props = defineProps(nodeViewProps);
const imageSrc = ref('');

const allowResizing = false;
const fullScreen = ref(false);
const resizing = ref(false);
const maxWidth = ref(0);

const resizerState = reactive({
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  dir: '',
});

const imgOption = ref({
  src: '',
  caption: '',
  ref: '',
  width: 0,
  height: 0,
  originalWidth: 0,
  originalHeight: 0,
});

const resizeDirections = [
  { label: '', value: 'tl' },
  { label: '', value: 'tr' },
  { label: '', value: 'bl' },
  { label: '', value: 'br' },
];

function selectImage() {
  if (!props.editor.view.editable && props.node.attrs.ref) {
    window.open(props.node.attrs.ref, '_blank');
    return;
  }

  const { state } = props.editor.view;
  let { tr } = state;
  const selection = NodeSelection.create(state.doc, props.getPos());
  tr = tr.setSelection(selection);
  props.editor.view.dispatch(tr);
}

function onMouseDown(e, direction) {
  e.preventDefault();
  e.stopPropagation();

  resizerState.x = e.clientX;
  resizerState.y = e.clientY;
  resizerState.w = Math.min(imgOption.value.width, maxWidth.value);
  resizerState.h = imgOption.value.height;
  resizerState.direction = direction;

  resizing.value = true;
  onEvents();
}

function onMouseUp(e) {
  e.preventDefault();
  e.stopPropagation();

  if (!resizing.value) {
    return;
  }

  resizing.value = false;
  Object.assign(resizerState, {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    direction: '',
  });

  offEvents();
  selectImage();
}

function onMouseMove(e) {
  e.preventDefault();
  e.stopPropagation();
  if (!resizing.value) {
    return;
  }

  const ratio = imgOption.value.originalWidth / imgOption.value.originalHeight;

  const { x, y, w, h, direction } = resizerState;
  const dx = (e.clientX - x) * (/l/.test(direction) ? -1 : 1);
  const dy = (e.clientY - y) * (/t/.test(direction) ? -1 : 1);
  let width = clamp(w + dx, 20, 100000);
  let height = Math.max(h + dy, 20);

  const newRatio = width / height;
  if (newRatio > ratio) {
    width = parseInt(height * ratio, 10);
  } else if (newRatio < ratio) {
    height = parseInt(width / ratio, 10);
  }

  resize(width, height);
  imgOption.value.width = width;
  imgOption.value.height = height;
}

function resize(width, height) {
  props.updateAttributes({
    width: width,
    height: height,
  });
}

function onEvents() {
  document.addEventListener('mousemove', onMouseMove, true);
  document.addEventListener('mouseup', onMouseUp, true);
}
function offEvents() {
  document.removeEventListener('mousemove', onMouseMove, true);
  document.removeEventListener('mouseup', onMouseUp, true);
}

const src = computed({
  get() {
    return props.node.attrs.src;
  },
  set(src) {
    props.updateAttributes({
      src,
    });
  },
});

function getMaxSize() {
  const { width } = getComputedStyle(props.editor.view.dom);
  maxWidth.value = parseInt(width, 10);
}

function resolveImage() {
  if (src.value.startsWith(getAppConfig().apiRoot)) {
    //Authentication needed to fetch image from letapps-api
    axios
      .get(src.value, {
        responseType: 'blob',
      })
      .then((response) => {
        const blob = response.data;
        blobToDataURL(blob).then((dataUrl) => {
          imageSrc.value = dataUrl;
        });
      });
  } else {
    imageSrc.value = src.value;
  }
}

resolveImage();

onMounted(getMaxSize);

const captionField = ref(null);
const visible = ref(false);
const data = ref({});

function showPopup() {
  visible.value = true;
  data.value.caption = props.node.attrs.caption;
}

function onClickOk() {
  props.updateAttributes({ caption: data.value.caption });
  visible.value = false;
}

function onPopupShown() {
  captionField.value.instance.focus();
}
</script>

<template>
  <node-view-wrapper as="span">
    <tiptap-component-item-wrapper :editor="editor">
      <template #buttons>
        <DxButton icon="preferences" @click="showPopup" />
      </template>
      <div class="image-view" :class="{ 'full-screen': fullScreen }">
        <!-- image, use span for print -->
        <div
          class="image-container"
          data-drag-handle
          :data-text-align="node.attrs.textAlign"
          :class="{ selected: selected || resizing }"
        >
          <img
            v-bind="node.attrs"
            :src="imageSrc"
            :class="{
              selected: selected || resizing,
              'cursor-pointer': node.attrs.ref,
            }"
            @click="selectImage"
          />
          <div
            v-if="editor.view.editable"
            v-show="allowResizing && (selected || resizing)"
            class="image-resizer"
          >
            <span
              v-for="(item, index) in resizeDirections"
              :key="index"
              :class="`image-resizer__handler--${item.value}`"
              class="image-resizer__handler"
              @mousedown="onMouseDown($event, item.value)"
            />
          </div>
        </div>
        <div class="image-caption" v-if="node.attrs.caption">
          {{ node.attrs.caption }}
        </div>
      </div>
    </tiptap-component-item-wrapper>

    <DxPopup
      v-model:visible="visible"
      title="Billede"
      :width="400"
      :height="250"
      :hide-on-outside-click="true"
      @shown="onPopupShown"
    >
      <DxForm :form-data="data" label-mode="outside" label-location="left">
        <DxItem data-field="Billedtekst">
          <DxTextBox ref="captionField" v-model="data.caption"></DxTextBox>
        </DxItem>
      </DxForm>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{
          text: 'OK',
          onClick: onClickOk,
        }"
      />
    </DxPopup>
  </node-view-wrapper>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.image-view {
  position: relative;
  display: inline-block;
  white-space: nowrap;

  &:hover {
    background: rgba(0, 0, 0, 0.02);

    .o-toolbar {
      visibility: visible;
    }
  }

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;

    .o-toolbar {
      position: absolute;
      top: 0;
      margin: 0;
      visibility: visible;
      z-index: 10;
    }

    .image-container {
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .caption {
      display: none;
    }
  }

  .image-container {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    outline: transparent solid 1px;
    clear: both;
    box-sizing: border-box;

    img {
      display: block;
    }

    &.selected {
      outline-style: solid;
      outline-color: $letpension-button;
    }

    .image-resizer {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .image-resizer__handler {
      background-color: $letpension-button;
      border: 1px solid #fff;
      border-radius: 2px;
      box-sizing: border-box;
      display: block;
      height: 12px;
      position: absolute;
      width: 12px;
      z-index: 2;
    }

    .image-resizer__handler--tl {
      cursor: nwse-resize;
      left: -6px;
      top: -6px;
    }

    .image-resizer__handler--tr {
      cursor: nesw-resize;
      right: -6px;
      top: -6px;
    }

    .image-resizer__handler--bl {
      bottom: -6px;
      cursor: nesw-resize;
      left: -6px;
    }

    .image-resizer__handler--br {
      bottom: -6px;
      cursor: nwse-resize;
      right: -6px;
    }
  }
}

.image-caption {
  text-align: center;
  font-size: 0.8rem;
  margin-top: -5px;
}
</style>
