import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let store;

export function getOrganisationDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/Organisationer', 'id');
  }

  return store;
}
