<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { formatNumber } from '../kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getData() {
  let periodeAktiviteter = store.aktivitet.periodeAktivitet;
  let senesteAktivitetsPeriode = periodeAktiviteter[periodeAktiviteter.length - 1];

  let antalUdenBehov = 0;
  let antalOpsparing = 0;
  let antalNedsparing = 0;
  let antalFormue = 0;

  for (let i = 0; i < periodeAktiviteter.length; i++) {
    if (periodeAktiviteter[i].aar === senesteAktivitetsPeriode.aar) {
      let periodeAktivitet = periodeAktiviteter[i];
      antalUdenBehov += periodeAktivitet.SumAntalForslagUdenBehov;
      antalOpsparing += periodeAktivitet.SumAntalOpsparingForslag;
      antalNedsparing += periodeAktivitet.SumAntalNedsparingForslag;
      antalFormue += periodeAktivitet.SumAntalFormueForslag;
    }
  }

  return [
    { name: 'Uden behov', value: antalUdenBehov },
    { name: 'Pension og forsikring', value: antalOpsparing },
    { name: 'Planlæg pensionering', value: antalNedsparing },
    { name: 'Formue', value: antalFormue },
  ];
}

function getOptions() {
  const data = getData();
  return Object.assign({}, getDefaultOptions(props.print), {
    chart: {
      ...getDefaultOptions(props.print).chart,
      dropShadow: {
        enabled: false,
      },
    },
    dataLabels: {
      formatter: function (val, opts) {
        return formatNumber(opts.w.config.series[opts.seriesIndex]);
      },
      dropShadow: false,
      style: {
        colors: ['#000'],
      },
      background: {
        enabled: true,
        foreColor: '#fff',
      },
    },
    labels: data.map((x) => x.name),
  });
}

function getSeries() {
  const data = getData();
  return data.map((x) => x.value);
}
</script>

<template>
  <div class="chart">
    <apexchart height="100%" type="pie" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 250px;
}
</style>
