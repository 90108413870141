import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import LetAccordionComponent from '@/tiptap/let-accordion/LetAccordionComponent.vue';
import LetAccordionItemComponent from '@/tiptap/let-accordion/LetAccordionItemComponent.vue';
import LetAccordionItemHeaderComponent from '@/tiptap/let-accordion/LetAccordionItemHeaderComponent.vue';
import LetAccordionItemContentComponent from '@/tiptap/let-accordion/LetAccordionItemContentComponent.vue';

export const LetAccordion = Node.create({
  name: 'letAccordion',
  group: 'block',
  content: 'letAccordionItem+',
  isolating: true,
  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'let-accordion',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-accordion', mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      insertAccordion:
        () =>
        ({ commands }) => {
          commands.insertContent(
            '<let-accordion><let-accordion-item class="let-accordion-item-expanded"><let-accordion-item-header><p>[Overskrift]</p></let-accordion-item-header><let-accordion-item-content><p>[Indhold]</p></let-accordion-item-content></let-accordion-item></let-accordion>',
          );
          return true;
        },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(LetAccordionComponent);
  },
});

export const LetAccordionItem = Node.create({
  name: 'letAccordionItem',
  group: 'block',
  content: 'letAccordionItemHeader letAccordionItemContent',
  isolating: true,

  // addAttributes() {
  //   return {
  //     class: {
  //       default: null,
  //       // Customize the HTML parsing
  //       parseHTML: (element) => {
  //         return element.getAttribute('class');
  //       },
  //       // … and customize the HTML rendering
  //       renderHTML: (attributes) => {
  //         if (attributes.class) {
  //           return {
  //             class: attributes.class,
  //           };
  //         }
  //       },
  //     },
  //   };
  // },

  parseHTML() {
    return [
      {
        tag: 'let-accordion-item',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-accordion-item', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LetAccordionItemComponent);
  },
});

export const LetAccordionItemHeader = Node.create({
  name: 'letAccordionItemHeader',
  group: 'block',
  content: 'block+',
  isolating: true,

  parseHTML() {
    return [
      {
        tag: 'let-accordion-item-header',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-accordion-item-header', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LetAccordionItemHeaderComponent);
  },
});
export const LetAccordionItemContent = Node.create({
  name: 'letAccordionItemContent',
  group: 'block',
  content: 'block+',
  isolating: true,

  parseHTML() {
    return [
      {
        tag: 'let-accordion-item-content',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-accordion-item-content', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LetAccordionItemContentComponent);
  },
});
