<script setup>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { ref } from 'vue';
import { DxForm, DxItem, DxLabel } from 'devextreme-vue/form';
import { DxButton } from 'devextreme-vue/button';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { getOpslagDatasource } from '@/data/opslag';
import { getFilDatasource } from '@/data/fil';

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
});

const visible = ref(false);
const data = ref({});

const opslagDatasource = getOpslagDatasource();
const filDatasource = getFilDatasource();

function onClickOk() {
  if (props.editor.getAttributes('letLink')) {
    if (data.value.link) {
      //Existing link. Update attributes
      props.editor.commands.setLink({
        link: data.value.link,
        linkTarget: data.value.linkTarget,
        href: data.value.link,
        target: data.value.linkTarget,
      });
    } else {
      //Remove existing link
      props.editor.commands.unsetLink();
    }
  } else {
    //Create new link
    const { state } = props.editor;
    const { from, to } = state.selection;
    const text = state.doc.textBetween(from, to, ' ');
    props.editor.commands.setLink({
      link: data.value.link,
      linkTarget: data.value.linkTarget,
      href: data.value.link,
      target: data.value.linkTarget,
    });

    // props.editor.commands.insertLink(data.value.link, text, data.value.linkTarget);
  }

  visible.value = false;
}
function showPopup() {
  if (props.editor.state.selection.empty) {
    props.editor.commands.extendMarkRange('letLink');
  }
  visible.value = true;
  const link = props.editor.getAttributes('letLink').link || '';
  const linkTarget = props.editor.getAttributes('letLink').linkTarget || '_self';
  data.value = {
    link,
    linkTarget,
  };
}

function opslagSelected(e) {
  const opslag = e.selectedItem;
  data.value.link = '/opslag/' + (opslag.stinavn || opslag.masterId);
}

function filSelected(e) {
  const fil = e.selectedItem;
  data.value.link = getAppConfig().apiRoot + '/api/Fil/' + (fil.stinavn || fil.id);
}
</script>

<template>
  <DxButton
    hint="Link"
    icon="link"
    @click="showPopup"
    :disabled="editor.state.selection.empty && !editor.getAttributes('letLink').link"
  >
  </DxButton>
  <DxPopup
    v-model:visible="visible"
    title="Link"
    :width="1000"
    :height="400"
    :hide-on-outside-click="true"
  >
    <DxForm :form-data="data" label-mode="outside" label-location="left">
      <DxItem data-field="link">
        <DxLabel text="Indtast url" />
        <DxTextBox v-model="data.link" />
      </DxItem>
      <DxItem>eller</DxItem>
      <DxItem>
        <DxLabel text="Vælg opslag" />
        <DxSelectBox
          :data-source="opslagDatasource"
          value-expr="id"
          display-expr="overskrift"
          :search-enabled="true"
          search-expr="Overskrift"
          @selection-changed="opslagSelected"
        />
      </DxItem>
      <DxItem>eller</DxItem>
      <DxItem>
        <DxLabel text="Vælg fil" />
        <DxSelectBox
          :data-source="filDatasource"
          value-expr="id"
          display-expr="navn"
          :search-enabled="true"
          search-expr="Navn"
          @selection-changed="filSelected"
        />
      </DxItem>
      <DxItem data-field="linkTarget">
        <DxLabel text="Åbnes" />
        <DxSelectBox
          v-model="data.linkTarget"
          :items="[
            { value: '_self', text: 'I samme vindue' },
            { value: '_blank', text: 'I nyt vindue' },
            { value: 'download', text: 'Download fil' },
          ]"
          value-expr="value"
          display-expr="text"
        />
      </DxItem>
    </DxForm>
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="after"
      :options="{
        text: 'OK',
        onClick: onClickOk,
      }"
    />
  </DxPopup>
</template>

<style scoped lang="scss"></style>
