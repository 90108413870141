<script setup>
import LetLoadIndicator from '@/letapps-vue/components/LetLoadIndicator.vue';
import FinansPlusIndbetalingChart from '@/pages/kvartalsrapport/charts/FinansPlusIndbetalingChart.vue';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { ref, watchEffect } from 'vue';
import FinansPlusHenvisningerChart from './charts/FinansPlusHenvisningerChart.vue';
import FinansPlusNysalgChart from './charts/FinansPlusNysalgChart.vue';
import FinansPlusProvisionChart from './charts/FinansPlusProvisionChart.vue';
import dayjs from 'dayjs';

defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();
const senesteDato = ref('');

watchEffect(() => {
  const data = store.finansPlus;
  if (data && data.length > 0) {
    const seneste = data[data.length - 1];
    senesteDato.value = seneste.tilDato;
  }
});
</script>

<template>
  <div class="titel">
    <div>FinansPlus henvisninger til PFA firmapension</div>
    <div
      class="undertitel"
      v-if="senesteDato && store.rapport && senesteDato !== store.rapport.slutDato"
    >
      Bemærk data er fra {{ dayjs(senesteDato).format('DD-MM-YYYY') }}
    </div>
  </div>
  <div class="flex-container container" v-if="store.finansPlus">
    <div class="flex-1">
      <div class="chart-titel">Samlede indbetalinger i mio. kr.</div>
      <p>Målsætning om 2 mia. kr. i 2025 indgår i Letpensions samlede vækstmål på 8 mia kr.</p>
      <FinansPlusIndbetalingChart :print="print" />
      <div class="chart-titel">Nysalg løbende indbetalinger i mio. kr.</div>
      <p>
        PFA’s winrate på henvisninger er ca. 50%, og nysalget bidrager til væksten i de samlede
        indbetalinger.
      </p>
      <FinansPlusNysalgChart :print="print" />
    </div>
    <div class="flex-1">
      <div class="chart-titel">Provision til pengeinstituttet i kr.</div>
      <p>Provision til pengeinstitutter beregnes af den samlede opsparing i FinansPlus.</p>
      <FinansPlusProvisionChart :print="print" />
      <div class="chart-titel">Antal henvisninger i stk.</div>
      <p>10 pengeinstitutter henviser potentielle firmapensionskunder til PFA Pension.</p>
      <FinansPlusHenvisningerChart :print="print" />
    </div>
  </div>
  <div v-else>
    <LetLoadIndicator :visible="true" />
    <div>Henter Finans Plus...</div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.logo {
  width: 160px;
}

.undertitel {
  margin-left: 0;
}
</style>
