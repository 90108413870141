<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getData() {
  let indtjeningProduktGrupper = store.indtjening.indtjeningProduktGrupper;

  const data = indtjeningProduktGrupper?.map((item) => {
    return {
      name: item.indtjeningProduktGruppeNavn,
      value: item.samletIndtjening,
    };
  });

  return data;
}

function getOptions() {
  const data = getData();
  return Object.assign({}, getDefaultOptions(props.print), {
    chart: {
      ...getDefaultOptions(props.print).chart,
      dropShadow: {
        enabled: false,
      },
    },
    dataLabels: {
      formatter: function (val, opts) {
        return formatNumber(opts.w.config.series[opts.seriesIndex]);
      },
      minAngleToShowLabel: 1,
      dropShadow: false,
      style: {
        colors: ['#000'],
      },
      background: {
        enabled: true,
        foreColor: '#fff',
      },
    },
    labels: data?.map((x) => x.name),
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -20,
        },
      },
    },
  });
}

function getSeries() {
  const data = getData();
  return data.map((x) => x.value);
}
</script>

<template>
  <div class="chart" v-if="store.indtjening.indtjeningProduktGrupper">
    <apexchart height="100%" type="pie" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 350px;
}
</style>
