<script setup>
import { getAktiveOpslagDatasource } from '@/data/opslag';
import LetLoadIndicator from '@/letapps-vue/components/LetLoadIndicator.vue';
import moreIcon from '@/letapps-vue/icons/more.svg';
import { ROUTE_OPSLAG } from '@/router/routeNames';
import { formatDate } from 'devextreme/localization';
import { onMounted, ref } from 'vue';

const pagesize = 10;

const props = defineProps({
  opslagType: { type: String },
  maalgruppeId: { type: Number },
});
const opslagDatasource = getAktiveOpslagDatasource();
const opslagListe = ref([]);
const loading = ref(false);
const flere = ref(true);
const skip = ref(0);

async function opdater() {
  loading.value = true;
  const loadResult = await opslagDatasource.store().load({
    take: pagesize,
    skip: skip.value,
    filter: ['opslagType', '=', props.opslagType],
    sort: [
      { selector: 'altidOeverst', desc: true },
      { selector: 'publiceringsTidspunkt', desc: true },
    ],
  });
  loading.value = false;

  let filteredList = loadResult.data;

  if (props.maalgruppeId) {
    filteredList = filteredList.filter((x) =>
      x.adgange.some((a) => a.rolle?.id === props.maalgruppeId),
    );
  } else {
    filteredList = filteredList.filter((x) => x.adgange.length === 0);
  }

  opslagListe.value.push(...filteredList);
  if (filteredList.length === 0) {
    flere.value = false;
  }
}

function visFlere() {
  skip.value = skip.value + pagesize;
  opdater();
}

onMounted(opdater);
</script>

<template>
  <div :class="flere && opslagListe.length > 0 ? 'opslag-liste-flere' : ''">
    <div v-for="opslag in opslagListe" :key="opslag.id" class="opslag-container">
      <router-link :to="`${ROUTE_OPSLAG}/${opslag.stinavn || opslag.masterId}`" class="big-link">
        <div class="opslag-item flex-container">
          <div class="miniature-container">
            <img :src="opslag.miniature" class="opslag-miniature" />
          </div>
          <div class="opslag-indhold">
            <div class="opslag-dato">
              {{ formatDate(new Date(opslag.publiceringsTidspunkt), 'dd-MM-yyyy') }}
            </div>
            <div class="opslag-overskrift">
              {{ opslag.overskrift }}
            </div>
            <div class="opslag-manchet">
              {{ opslag.manchet }}
            </div>
          </div>
          <div class="opslag-arrow"></div>
        </div>
      </router-link>
    </div>
  </div>
  <div class="under-list">
    <div class="vis-flere" v-if="flere && !loading">
      <div class="vis-flere-button" @click="visFlere()">
        <img :src="moreIcon" />
      </div>
      <div class="vis-flere-tekst">Vis flere nyheder</div>
    </div>
    <div v-if="loading">
      <let-load-indicator :visible="loading" text="Henter opslag..." />
    </div>
    <div v-if="!loading && opslagListe.length === 0" class="ingen-opslag">
      Der er ingen opslag at vise i øjeblikket
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.vis-flere {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vis-flere-button {
  width: 50px;
  height: 50px;
  cursor: pointer;

  i {
    position: relative;
    top: 3px;
    font-size: 2.25rem;
  }
}

.vis-flere-tekst {
  font-weight: 500;
  margin-top: 10px;
}

.status-row {
  padding: 2px;
}

.beskeder-link {
  text-align: right;
}

.ingen-opslag {
  padding: 20px;
}

.under-list {
  height: 150px;
  text-align: center;
}

.opslag-container {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;

  &:first-child {
    margin-top: 0;
  }
}

.opslag-liste-flere {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 140px;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(transparent, #fff) left repeat;
  }
}

.miniature-container {
  width: 210px;
  height: 120px;
  text-align: center;
  background-color: $letpension-sand;
}

.opslag-arrow {
  width: 16px;
  background-color: $letpension-grey;
  mask-image: url('../../letapps-vue/icons/arrow.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center left;
  margin-right: 5px;
}

.opslag-miniature {
  width: 210px;
  max-height: 120px;
  object-fit: cover;
}

.opslag-indhold {
  flex: 1;
  padding: 0;
}

.opslag-item {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 12px;
  }
}

.opslag-dato {
  margin-top: 3px;
  color: $letpension-grey;
  font-weight: 500;
  margin-bottom: 5px;
}

.opslag-overskrift {
  font-size: 1rem;
  font-weight: 600;
  color: $letpension-orange;
  margin-bottom: 3px;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.opslag-manchet {
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
}

.opslag-tags {
  display: flex;
  gap: 4px;
}

.opslag-tag {
  padding: 0 4px;
  border-radius: 3px;
  font-weight: 500;
  color: white;
}
</style>
