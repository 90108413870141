<script setup>
import LetPage from '@/letapps-vue/components/LetPage';
import { reactive, ref } from 'vue';
import { DxButton } from 'devextreme-vue/button';
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import RapportParameters from '@/components/rapportering/RapportParameters';
import { indevaerendeAar } from '@/utils/rapporteringUtils';
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { authState } from '@/letapps-vue/utils/auth';
import { getJsonFromBlob } from '@/letapps-vue/utils/useRestApi';
import dayjs from 'dayjs';
import LetReadingArea from '@/letapps-vue/components/LetReadingArea.vue';
import LetToolbar from '@/letapps-vue/components/LetToolbar.vue';

const periodOptions = reactive({
  aar: indevaerendeAar,
  periodeType: 'ÅTD',
  maaned: dayjs().month().toString(),
  uge: dayjs().subtract(7, 'day').isoWeek(),
  regnr: authState.bruger.organisation.regnr,
  rapport: 'nysalg-pi',
});

const isDownloading = ref(false);
const downloadErrors = ref(null);

function download() {
  isDownloading.value = true;
  downloadErrors.value = null;
  const type = periodOptions.rapport;
  const queryString = Object.keys(periodOptions)
    .map((key) => `${key}=${periodOptions[key]}`)
    .join('&');
  const url = `${getAppConfig().apiRoot}/api/salgsrapportering/${type}-excel?${queryString}`;
  let downloadFilename = type;
  if (type.endsWith('pi')) {
    downloadFilename = downloadFilename.replaceAll('-pi', `-${periodOptions.regnr}`);
  }

  // Use axios to download file because we need accesstoken in header
  axios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      //Læs filnavn fra response header
      const match = response.headers['content-disposition'].match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
      );
      if (match && match[1]) {
        downloadFilename = match[1];
      }
      const linkUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = linkUrl;
      link.setAttribute('download', downloadFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      isDownloading.value = false;
    })
    .catch((err) => {
      if (err.response) {
        getJsonFromBlob(err.response.data).then((json) => {
          isDownloading.value = false;
          downloadErrors.value = json.message || err.message;
        });
      } else {
        console.error(err);
        isDownloading.value = false;
        downloadErrors.value = err.message;
      }
    });
}
</script>

<template>
  <LetPage>
    <LetToolbar> </LetToolbar>
    <LetReadingArea sidebredde="normal">
      <div class="overskrift">
        <h1>Rapportering</h1>
      </div>
      <br />
      <RapportParameters v-model="periodOptions" />
      <div class="submit-container">
        <DxButton
          text="Hent rapport i Excel"
          type="default"
          icon="download"
          @click="download()"
          :disabled="isDownloading"
        />
        <DxLoadIndicator v-if="isDownloading" :visible="isDownloading" class="loading-indicator" />
        <div class="error-container">
          {{ downloadErrors }}
        </div>
      </div>
    </LetReadingArea>
  </LetPage>
</template>

<style lang="scss">
.overskrift {
  padding-top: 40px;
}

.submit-container {
  margin-top: 10px;
  display: flex;
  height: 36px;
  gap: 20px;
}

.error-container {
  padding-top: 8px;
}

.rapport-gruppe {
  margin-top: 40px;
  font-weight: 700;
  font-size: 1.125rem;
  padding-bottom: 20px;
}

.rapport-navn {
  font-weight: 600;
  padding-right: 40px;
  width: 250px;
}

.loading-indicator {
  height: 30px;
}
</style>
