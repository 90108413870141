<script setup>
import {
  driftstatusValues,
  getAktiveDriftbeskederDatasource,
  getDriftOrgDatasource,
} from '@/data/driftstatus';
import LetFloatingAction from '@/letapps-vue/components/LetFloatingAction.vue';
import { authState } from '@/letapps-vue/utils/auth';
import { ROUTE_DRIFTBESKEDER } from '@/router/routeNames';
import { RettighedsType } from '@/types/rettighed';
import { onMounted, ref } from 'vue';

const orgDatasource = getDriftOrgDatasource();
const beskeder = getAktiveDriftbeskederDatasource();

const statusList = ref([]);
const beskedList = ref([]);
const loading = ref(false);

async function opdater() {
  loading.value = true;
  await beskeder.load();
  beskedList.value = beskeder
    .items()
    .filter(
      (b) =>
        b.organisationId === 1 ||
        b.organisationId === authState.bruger.organisation.itcId ||
        authState.bruger.organisation.itcId == null,
    );
  loading.value = false;
}

onMounted(async () => {
  await orgDatasource.loadIfEmpty();
  statusList.value = orgDatasource.items();
  await opdater();
  setInterval(opdater, 5 * 60000);
});
</script>

<template>
  <div class="let-floating-action-button-container">
    <LetFloatingAction
      :rettighed="RettighedsType.LetguideDriftbesked"
      icon="preferences"
      type="default"
      styling-mode="outlined"
      :to="ROUTE_DRIFTBESKEDER"
    />

    <div class="section-header">Driftstatus</div>
    <div v-if="beskedList.length === 0 && !loading" class="status-row">
      <div class="status-icon">
        <i class="dx-icon-isnotblank color-green"></i>
      </div>
      <div class="status-text">Normal drift</div>
    </div>
    <div v-for="besked in beskedList" :key="besked.id" class="besked-item let-edit-icon-revealer">
      <router-link :to="`${ROUTE_DRIFTBESKEDER}/${besked.id}`" class="big-link">
        <div class="status-row">
          <div class="status-icon">
            <i class="dx-icon-isnotblank" :class="'color-' + besked.status"></i>
          </div>
          <div class="status-text">
            <div class="besked-overskrift">{{ besked.overskrift }}</div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.status-row {
  display: flex;
}

.status-icon {
  width: 20px;
}

.beskeder-link {
  text-align: right;
}

.besked-item:not(:first-child) {
  margin-top: 10px;
}

.besked-overskrift {
  font-weight: 400;
}

.besked-starttidspunkt {
  font-size: 14px;
  font-weight: 500;
  color: $letpension-grey;
}

.besked-tekst {
  margin-top: 10px;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  // -webkit-box-orient: vertical;

  :deep(p),
  :deep(ul) {
    margin-top: 0;
  }
}
</style>
