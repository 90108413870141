<script setup>
import { getMedarbejderDatasource } from '@/data/medarbejder';
import { ref, watchEffect } from 'vue';

const props = defineProps({
  kamId: {
    type: Number,
    required: true,
  },
});

const kam = ref();

const medarbejderDatasource = getMedarbejderDatasource();

watchEffect(async () => {
  if (props.kamId > 0) {
    kam.value = await medarbejderDatasource.store().byKey(props.kamId);
  }
});
</script>

<template>
  <div v-if="kam">
    <div class="section-header">Salgsunderstøttelse</div>
    <img v-if="kam.billede" class="kam-image" :src="'data:image/jpeg;base64,' + kam.billede" />
    <div class="kam-navn">{{ kam.navn }}</div>
    <div class="kam-titel">Forretningsansvarlig for salg og pengeinstitutter</div>
    <div>
      <a :href="'mailto:' + kam.email">{{ kam.email }}</a>
    </div>
  </div>
</template>

<style scoped>
.kam-image {
  clip-path: circle();
  width: 120px;
  padding: 8px 0;
}

.kam-navn {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.kam-titel {
  margin-bottom: 5px;
}
</style>
