<script setup>
import LetCard from '@/letapps-vue/components/LetCard.vue';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { RettighedsType } from '@/types/rettighed';
import axios from 'axios';
import { onMounted, ref } from 'vue';

const administrators = ref({});

onMounted(async () => {
  const response = await axios.get(getAppConfig().apiRoot + '/api/brugere/administrators');
  administrators.value = response.data;
});

function getAdminLink() {
  if (location.origin.includes('letguide.'))
    return location.origin.replaceAll('letguide.', 'admin.');
  if (location.origin.includes('letguide-'))
    return location.origin.replaceAll('letguide-', 'letadmin-');
  if (location.origin.includes('localhost:8080')) return location.origin.replaceAll('8080', '8090');
  return 'https://admin.letpension.dk';
}
</script>

<template>
  <LetCard title="Administratorer i mit pengeinstitut" class="bg-sand-light">
    <div v-for="admin in administrators" :key="admin.id">
      {{ admin.fornavn }} {{ admin.efternavn }}:
      <a :href="'mailto:' + admin.email">{{ admin.email }}</a>
    </div>
    <div v-if="hasRettighed(RettighedsType.AppAdmin)" class="admin-link">
      <a :href="getAdminLink()" target="_blank">Link til Administrationsportal</a>
    </div>
  </LetCard>
</template>

<style scoped>
.admin-link {
  margin-top: 20px;
}
</style>
