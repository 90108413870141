import axios from 'axios';

let appConfig: AppConfig;

export function getAppConfig() {
  return appConfig;
}

export const initAppConfig = async () => {
  const response = await axios.get(`/app-config.json?_cb=${new Date().getTime()}`, {});
  appConfig = response.data;
  console.log(`build number: ${appConfig.buildNumber}`);
};

export interface AppConfig {
  application: string;
  appName: string;
  buildNumber: string;
  env: string;
  apiRoot: string;
  b2cScopes: string[];
  msalConfig: {
    auth: {
      clientId: string;
      authority: string;
      knownAuthorities: string[];
      redirectUri: string;
    };
    cache: {
      cacheLocation: string;
    };
  };
  sentry: {
    enabled: boolean;
    dsn: string;
    tracesSampleRate: number;
  };
}
