import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

export function getDefaultOptions(print = false) {
  return {
    yaxis: {
      show: true,
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          return formatNumber(value);
        },
      },
    },
    grid: {
      show: true,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    chart: {
      // events: {
      //   click: function (event, chartContext, config) {
      //     console.log('click');
      //     // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
      //   },
      // },
      toolbar: {
        show: false,
      },
      animations: { enabled: !print },
      dropShadow: {
        enabled: false,
      },
    },
    colors: [
      '#6bb8bc',
      '#e49d3b',
      '#b9c35d',
      '#eacb7a',
      '#0c637f',
      '#b8926f',
      '#a69f89',
      '#6d4a30',
      '#a6c2b5',
    ],
    tooltip: { enabled: true },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
      pie: {
        dataLabels: {
          minAngleToShowLabel: 1,
          offset: -10,
        },
      },
    },
  };
}

export function calcAxisMinValue(series) {
  const values = series[0].data.map((d) => d.y);
  values.push(...series[1].data.map((d) => d.y));
  const minValue = Math.min(...values);
  return toPrecision(minValue * 0.99, 2, 'down');
}

export function calcAxisMaxValue(series) {
  const values = series[0].data.map((d) => d.y);
  values.push(...series[1].data.map((d) => d.y));
  const maxValue = Math.max(...values);
  return toPrecision(maxValue, 2, 'up');
}

export function toPrecision(number, precision, direction) {
  precision -= Math.floor(number).toString().length;
  var order = Math.pow(10, precision);
  number *= order;
  var option = direction === 'down' ? 'floor' : 'ceil';
  return Math[option].call(null, number) / order;
}
