import { TextStyle } from '@tiptap/extension-text-style';

export const LetSpan = TextStyle.extend({
  name: 'letSpan',

  addAttributes() {
    return {
      class: {
        default: null,
        // Customize the HTML parsing
        parseHTML: (element) => {
          return element.getAttribute('class');
        },
        // … and customize the HTML rendering
        renderHTML: (attributes) => {
          if (attributes.class) {
            return {
              class: attributes.class,
            };
          }
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element) => {
          if (element instanceof HTMLElement) {
            const hasStyles = element.hasAttribute('style');
            const hasClass = element.hasAttribute('class');

            if (!hasStyles && !hasClass) {
              return false;
            }
          }

          return {};
        },
      },
    ];
  },
});
