<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import dayjs from 'dayjs';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  produktGruppeNavn: { type: String, require: true },
  print: { type: Boolean, default: false },
});
const store = useKvartalsrapportStore();

let forrigeAar;

let detteAar;
function prepareData() {
  const salgProduktGrupper = store.udvikling.salgProduktGrupper;
  const salg = salgProduktGrupper.filter(
    (gruppe) => gruppe.salgProduktGruppeNavn === props.produktGruppeNavn,
  )[0];
  const maanedSalg = salg.maanedSalg;
  detteAar = maanedSalg.slice(maanedSalg.length - 12);
  forrigeAar = maanedSalg.slice(maanedSalg.length - 24).slice(0, 12);
}
function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    dataLabels: {
      enabled: false,
      formatter: function (value) {
        return formatNumber(value, 1, 0);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  prepareData();

  const series = [
    {
      name: 'Forrige år',
      data: forrigeAar.map((x) => {
        return { x: `${dayjs(x.periodeTil).format('MMM')}`, y: x.antal };
      }),
    },
    {
      name: 'Dette år',
      data: detteAar.map((x) => {
        return { x: `${dayjs(x.periodeTil).format('MMM')}`, y: x.antal };
      }),
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart" v-if="store.udvikling.salgProduktGrupper">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
