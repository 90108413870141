import { mergeAttributes } from '@tiptap/core';
import { Link } from '@tiptap/extension-link';
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';

window.hentFil = function (url, target) {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((response) => {
      let downloadFilename = 'download.fil';
      //Læs filnavn fra response header
      const match = response.headers['content-disposition'].match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
      );
      if (match && match[1]) {
        downloadFilename = match[1];
      }

      if (target === 'download') {
        const link = document.createElement('a');
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', downloadFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        downloadFilename = downloadFilename.replaceAll('.', '_');
        let fileViewUrl = url.replaceAll('/Fil/', '/fileview/') + '/' + downloadFilename;
        fileViewUrl = fileViewUrl.substring(fileViewUrl.indexOf('/api/') + 4);
        window.open(fileViewUrl, '_blank');
      }
    });
};

export const LetLink = Link.extend({
  name: 'letLink',
  inclusive: false,

  parseHTML() {
    return [
      {
        tag: 'let-link',
      },
      {
        tag: 'a',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    let renderedAttributes = {};
    if (HTMLAttributes['data-link']?.startsWith(getAppConfig().apiRoot)) {
      renderedAttributes = {
        'data-link': HTMLAttributes['data-link'],
        'data-target': HTMLAttributes['data-target'],
        href:
          'javascript:hentFil("' +
          HTMLAttributes['data-link'] +
          '", "' +
          HTMLAttributes['data-target'] +
          '");',
      };
    } else {
      renderedAttributes = {
        href: HTMLAttributes['href'],
        target: HTMLAttributes['target'],
      };
    }
    return ['a', mergeAttributes(renderedAttributes), 0];
  },

  addAttributes() {
    return {
      ...this.parent?.(),

      link: {
        parseHTML: (element) => element.getAttribute('data-link') || element.getAttribute('href'),
        renderHTML: (attributes) => {
          return {
            'data-link': attributes.link,
          };
        },
      },
      linkTarget: {
        parseHTML: (element) =>
          element.getAttribute('data-target') || element.getAttribute('target'),
        renderHTML: (attributes) => {
          return {
            'data-target': attributes.linkTarget,
          };
        },
      },
    };
  },
});
