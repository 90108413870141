<script setup>
import AarligIndtjeningChart from '@/pages/kvartalsrapport/charts/AarligIndtjeningChart.vue';
import UdviklingChart from '@/pages/kvartalsrapport/charts/UdviklingChart.vue';
import LetLoadIndicator from '@/letapps-vue/components/LetLoadIndicator.vue';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore';
import { formatNumber } from './kvartalsrapportUtil';

defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getRangliste(antalMedarbejdereFra = 0, antalMedarbejdereTil = 999999) {
  const liste = store.rangliste.placeringer.filter((item) => {
    if (
      item.antalMedarbejdere >= antalMedarbejdereFra &&
      item.antalMedarbejdere <= antalMedarbejdereTil
    )
      return item;
  });

  return liste.sort((a, b) =>
    a.placeringAlle > b.placeringAlle ? 1 : b.placeringAlle > a.placeringAlle ? -1 : 0,
  );
}

function getTop(top = 5, antalMedarbejdereFra = 0, antalMedarbejdereTil = 999999) {
  const alle = getRangliste(antalMedarbejdereFra, antalMedarbejdereTil);
  const filtered = alle.filter((pi, index) => {
    if (index < top) return pi;
  });
  let prevPi = {};
  filtered.forEach((pi, index) => {
    if (pi.placeringAlle === prevPi.placeringAlle) {
      pi.gruppePlacering = prevPi.gruppePlacering;
    } else {
      pi.gruppePlacering = index + 1;
    }
    prevPi = pi;
  });
  return filtered;
}
function getMinPlacering() {
  const a = store.rangliste.placeringer.filter((item) => {
    if (item.pengeinstitut.regnr === store.pengeinstitut.regnr) return item;
  });
  return a[0];
}

function getSenesteIndtjening() {
  const indtjening = store.indtjening.indtjeningPerioder;
  return indtjening.slice(indtjening.length - 1)[0];
}

function cleanPiNavn(navn) {
  return navn.replaceAll(' A/S', '');
}
</script>

<template>
  <div class="titel">Hovedtal</div>
  <div class="flex-container container">
    <div class="flex-1">
      <div class="chart-titel" style="padding-bottom: 60px">
        Rangliste: Hvem klarer sig bedst i Letpension-samarbejdet?
      </div>
      <div v-if="store.rangliste" class="flex-container flex-gap-40">
        <div class="flex-1">
          <div class="rangliste-main">
            <div
              v-for="(item, index) in getTop()"
              :key="index"
              class="flex-container rangliste-item"
            >
              <div class="rangliste-navn flex-1">{{ cleanPiNavn(item.pengeinstitut.navn) }}</div>
              <div class="rangliste-placering numbers">{{ item.placeringAlle }}</div>
            </div>
          </div>
          <div class="rangliste-min-placering flex-container" v-if="getMinPlacering()">
            <div class="rangliste-navn flex-1">
              {{ cleanPiNavn(getMinPlacering().pengeinstitut.navn) }}
            </div>
            <div class="rangliste-placering numbers">{{ getMinPlacering().placeringAlle }}</div>
          </div>
        </div>
        <div class="flex-1">
          <div class="rangliste-subtitle">Over 500 medarbejdere</div>
          <div v-for="(item, index) in getTop(10, 500)" :key="index" class="flex-container">
            <div class="rangliste-navn flex-1">{{ cleanPiNavn(item.pengeinstitut.navn) }}</div>
            <div class="rangliste-placering numbers">{{ item.gruppePlacering }}</div>
          </div>
          <div class="rangliste-subtitle">100-499 medarbejdere</div>
          <div v-for="(item, index) in getTop(10, 100, 499)" :key="index" class="flex-container">
            <div class="rangliste-navn flex-1">{{ cleanPiNavn(item.pengeinstitut.navn) }}</div>
            <div class="rangliste-placering numbers">{{ item.gruppePlacering }}</div>
          </div>
          <div class="rangliste-subtitle">0-99 medarbejdere</div>
          <div v-for="(pi, index) in getTop(10, 0, 99)" :key="index" class="flex-container">
            <div class="rangliste-navn flex-1">{{ cleanPiNavn(pi.pengeinstitut.navn) }}</div>
            <div class="rangliste-placering numbers">{{ pi.gruppePlacering }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <LetLoadIndicator :visible="true" />
        <div>Henter rangliste...</div>
      </div>
    </div>
    <div class="flex-1">
      <div class="chart-titel">
        Hvordan har den årlige indtjening udviklet sig sammenholdt med tidligere år, i mio. kr.?
      </div>
      <div v-if="store.indtjening">
        <AarligIndtjeningChart :print="print" />
        <div class="chart-titel">Hvor meget har I solgt i forhold til sidste kvartal?</div>
        <div class="flex-container flex-gap-20">
          <div class="flex-1">
            <UdviklingChart produkt="SygdomSamlet" :print="print" />
            <div class="chart-caption">Letsikring ved sygdom (stk.)</div>
          </div>
          <div class="flex-1">
            <UdviklingChart produkt="Død" :print="print" />
            <div class="chart-caption">Letsikring ved død (stk.)</div>
          </div>
          <div class="flex-1">
            <UdviklingChart produkt="Pension" :print="print" />
            <div class="chart-caption">Letsikring af indtægt ved pension (mio.kr.)</div>
          </div>
        </div>
        <div class="chart-titel">
          Hvor stor er jeres årlige indtjening i mio. kr. sammenlignet med de fem pengeinstitutter,
          der tjener mest?
        </div>
        <br />
        <div>I regnestykket har vi omregnet tallene for top 5 forholdsmæssigt.</div>
        <div class="sammenligning">
          <div class="flex-container">
            <div class="sammenligning-header">Indtjening</div>
            <div class="sammenligning-header flex-2">Indtjening top 5 niveau</div>
            <div class="sammenligning-header">Netto forskel</div>
          </div>
          <div class="flex-container" v-if="store.indtjening.indtjeningPerioder">
            <div class="sammenligning-tal">
              {{ formatNumber(getSenesteIndtjening().samletIndtjening / 1000000, 1, 1) }}
            </div>
            <div class="sammenligning-tal flex-2">
              {{ formatNumber(getSenesteIndtjening().top5GennemsnitligIndtjening / 1000000, 1, 1) }}
            </div>
            <div class="sammenligning-tal">
              {{
                formatNumber(
                  (getSenesteIndtjening().samletIndtjening -
                    getSenesteIndtjening().top5GennemsnitligIndtjening) /
                    1000000,
                  1,
                  0,
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <LetLoadIndicator :visible="true" />
        <div>Henter indtjening...</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.rangliste-subtitle {
  font-weight: bold;
  background-color: $letpension-sand;
  margin-top: 30px;
}

.rangliste-main {
  background-image: url('./resources/triangle.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-weight: 600;
  padding: 0 20px;

  .rangliste-item {
    padding: 35px 0;
  }
}

.rangliste-placering {
  width: 20px;
  text-align: right;
  margin-right: 10px;
}

.rangliste-navn {
  margin-bottom: 5px;
}

.rangliste-min-placering {
  background-color: $letpension-sand;
  margin-top: 20px;
  font-weight: 600;
  padding: 10px 20px;
}

.chart-caption {
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
}

.chart-titel {
  padding-top: 20px;
}

.sammenligning {
  margin-top: 20px;
  border-top: 2px solid $letpension-blue;
  border-bottom: 2px solid $letpension-blue;
}

.sammenligning-header {
  flex: 1;
  text-align: center;
}

.sammenligning-tal {
  flex: 1;
  text-align: center;
  color: $letpension-blue;
  font-size: 1.3rem;
  font-weight: bold;
}
</style>
