import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let medarbejderStore;
export function getMedarbejderDatasource() {
  if (!medarbejderStore) {
    medarbejderStore = createDevextremeDatasource('/api/Medarbejdere', 'id');
  }

  return medarbejderStore;
}
