import Table, { createTable } from '@tiptap/extension-table';
import { TextSelection } from '@tiptap/pm/state';
import { LetTableView } from './LetTableView.js';

export const LetTable = Table.extend({
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      resizable: false,
      handleWidth: 5,
      cellMinWidth: 25,
      View: LetTableView,
      lastColumnResizable: true,
      allowTableNodeSelection: false,
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),

      insertLetTable:
        ({ rows = 3, cols = 3, withHeaderRow = true, className = null } = {}) =>
        ({ editor, commands, tr, dispatch }) => {
          const node = createTable(editor.schema, rows, cols, withHeaderRow);

          if (dispatch) {
            const offset = tr.selection.anchor + 1;
            commands.insertContent({
              type: 'letTableWrapper',
              attrs: { class: className },
              content: [node.toJSON()],
            });

            tr.scrollIntoView().setSelection(TextSelection.near(tr.doc.resolve(offset)));
          }

          return true;
        },

      deleteLetTable:
        () =>
        ({ state, dispatch }) => {
          let $pos = state.selection.$anchor;
          for (let d = $pos.depth; d > 0; d--) {
            let node = $pos.node(d);
            if (node.type.name === 'letTableWrapper') {
              if (dispatch) {
                dispatch(state.tr.delete($pos.before(d), $pos.after(d)).scrollIntoView());
              }
              return true;
            }
          }
          return false;
        },
    };
  },
});
