<script setup lang="ts">
import { useRouter } from 'vue-router';
import LetButton from '@/letapps-vue/components/LetButton.vue';

const router = useRouter();
const props = defineProps({
  text: { type: String, default: 'Tilbage' },
  route: { type: String },
});

function go() {
  if (props.route) {
    router.push({
      path: props.route,
    });
  } else {
    router.back();
  }
}
</script>

<template>
  <let-button
    :text="text"
    stylingMode="text"
    icon="arrowleft"
    @click="go"
    class="back-button"
  ></let-button>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';
//.back-button {
//  color: $letpension-grey;
//
//  :deep(.dx-icon) {
//    color: $letpension-grey;
//  }
//}
</style>
