<script setup></script>

<template>
  <div class="page">
    <div class="menu">
      <div class="menu-item"><router-link to="opslag">Opslag</router-link></div>
      <div class="menu-item"><router-link to="filer">Filer</router-link></div>
      <div class="menu-item"><router-link to="driftbeskeder">Driftbeskeder</router-link></div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension';

.page {
  height: calc(100vh - 140px);
  width: 100%;
  display: flex;
  gap: 20px;
}

.menu {
  //background-color: $letpension-sand-light;
  width: 150px;
  padding-top: 45px;
  //padding: 20px;

  a {
    text-decoration: none;
    color: black;

    &.router-link-active {
      color: $letpension-orange;
    }
  }
}

.menu-item {
  font-size: 1.075rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.content {
  flex: 1;
}
</style>
