export function getPositionOfNode(editor, node) {
  const doc = editor.state.doc;
  let positionOfNode = null;
  doc.descendants((n, pos) => {
    if (n === node) {
      positionOfNode = pos;
      return false;
    }
  });
  return positionOfNode;
}
