import { hasRettighed } from '@/letapps-vue/utils/auth';
import { ROUTE_KVARTALSRAPPORT, ROUTE_LEDERSIDEN } from '@/router/routeNames';
import { RettighedsType } from '@/types/rettighed';
import { computed } from 'vue';

export const navigation = [
  {
    text: 'Rapportering',
    path: '/rapportering',
    rettighed: RettighedsType.LetguideRapportering,
    items: [
      {
        text: 'Kvartalsrapport',
        path: ROUTE_KVARTALSRAPPORT,
        rettighed: RettighedsType.LetguideKvartalsrapport,
      },
    ],
  },
  {
    text: 'Ledersiden',
    path: ROUTE_LEDERSIDEN,
    rettighed: RettighedsType.LetguideFagleder,
  },
  {
    text: 'Rådgiversiden',
    path: '/opslag/tilraadgivere',
    rettighed: RettighedsType.LetguideRaadgiver,
  },
  {
    text: 'Afkast',
    path: '/opslag/omafkast',
    rettighed: RettighedsType.LetguideRaadgiver,
  },
  {
    text: 'Få hjælp det rette sted',
    path: '/opslag/hjaelp',
    rettighed: RettighedsType.LetguideRaadgiver,
  },
  {
    text: 'Om',
    path: '/opslag/om',
    rettighed: RettighedsType.LetguideRaadgiver,
  },
];

export const accesibleMenuItems = computed(() =>
  navigation.filter((item) => !item.rettighed || hasRettighed(item.rettighed)),
);

export default navigation;
