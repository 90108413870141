<script setup>
import OpslagLinkList from '@/components/opslag/OpslagLinkList.vue';
import OpslagMeta from '@/components/opslag/OpslagMeta.vue';
import OpslagToc from '@/components/opslag/OpslagToc.vue';
import { getOpslagDatasource } from '@/data/opslag';
import LetBackButton from '@/letapps-vue/components/LetBackButton.vue';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import LetReadingArea from '@/letapps-vue/components/LetReadingArea.vue';
import toTopIcon from '@/letapps-vue/icons/totop.svg';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { authState, hasRettighed } from '@/letapps-vue/utils/auth';
import { ROUTE_HOME, ROUTE_OPSLAG } from '@/router/routeNames';
import LetTipTapEditor from '@/tiptap/component/LetTipTapEditor.vue';
import { RettighedsType } from '@/types/rettighed';
import { getByKey } from '@/utils/opslagUtils';
import axios from 'axios';
import dayjs from 'dayjs';
import { DxToolbar, DxItem } from 'devextreme-vue/toolbar';
import { formatDate } from 'devextreme/localization';
import { onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';

const props = defineProps({
  id: {
    type: [Number, String],
    required: false,
  },
});

const opslagDatasource = getOpslagDatasource();

const opslag = ref();
const opslagFindesIkke = ref(false);
const notifikationSending = ref(false);

watchEffect(async () => {
  if (props.id) {
    try {
      opslag.value = await getByKey(props.id);
    } catch (error) {
      opslagFindesIkke.value = true;
    }
    if (opslag.value) {
      opslag.value.links = opslag.value.links?.sort((a, b) => a.sortering - b.sortering);
    }
  } else {
    opslag.value = {
      opslagType: 'Nyhed',
      publiceringsTidspunkt: new Date(),
    };
  }
});

async function publicerOpslag() {
  opslag.value.kladde = 0;
  if (!opslag.value.publiceringsTidspunkt) {
    opslag.value.publiceringsTidspunkt = new Date();
  }

  try {
    await opslagDatasource.save(opslag.value);
  } catch (e) {
    console.error(e);
  }
}

const scrollPosition = ref(0);
onMounted(() => {
  window.addEventListener('scroll', onScroll, true);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll, true);
});
function onScroll() {
  scrollPosition.value = window.scrollY;
}
function scrollToTop() {
  //Remove Hash from url
  history.pushState('', document.title, window.location.pathname + window.location.search);
  window.scrollTo(0, 0);
}

async function sendNotifikation() {
  notifikationSending.value = true;
  const response = await axios.get(`${getAppConfig().apiRoot}/api/Opslag/sendnotifikation`, {
    params: { opslagId: opslag.value.id },
  });
  if (response.status === 200) {
    opslag.value.notifikationAfsendt = new Date();
  }
  notifikationSending.value = false;
}

function getClass() {
  return 'opslag-type-' + opslag.value.opslagType?.replaceAll('&', '_');
}

function showBackButton() {
  return opslag.value?.opslagType !== 'Indhold';
}
</script>

<template>
  <div
    class="return-to-top"
    :class="scrollPosition > 0 ? 'return-to-top--visible' : ''"
    @click="scrollToTop()"
  >
    <img :src="toTopIcon" />
  </div>
  <LetPage>
    <div v-if="opslag" :class="getClass()">
      <DxToolbar class="let-toolbar" v-if="opslag.id !== 0">
        <DxItem location="before" v-if="showBackButton()">
          <LetBackButton text="Tilbage" />
        </DxItem>
        <DxItem
          location="after"
          v-if="dayjs(opslag.publiceringsTidspunkt) > dayjs()"
          cssClass="hide-on-mobile"
        >
          <div class="advarsel">
            Opslaget publiceres {{ dayjs(opslag.publiceringsTidspunkt).format('DD-MM-YYYY HH:mm') }}
          </div>
        </DxItem>
        <DxItem
          location="after"
          v-if="dayjs(opslag.udloebsTidspunkt) < dayjs()"
          cssClass="hide-on-mobile"
        >
          <div class="advarsel">
            Opslaget er afpubliceret {{ dayjs(opslag.udloebsTidspunkt).format('DD-MM-YYYY HH:mm') }}
          </div>
        </DxItem>
        <DxItem location="after" v-if="opslag.slettet" cssClass="hide-on-mobile">
          <div class="advarsel">Opslaget er slettet!</div>
        </DxItem>
        <DxItem
          location="after"
          v-if="
            !opslag.kladde &&
            opslag?.notifikationAfsendt &&
            opslag.opslagType === 'Nyhed' &&
            hasRettighed(RettighedsType.LetguideOpslagAdmin)
          "
          cssClass="hide-on-mobile"
        >
          <div>
            Seneste notifikation: {{ dayjs(opslag.notifikationAfsendt).format('DD-MM-YYYY HH:mm') }}
          </div>
        </DxItem>
        <DxItem
          location="after"
          v-if="
            !opslag.kladde &&
            hasRettighed(RettighedsType.LetguideOpslagAdmin) &&
            opslag.opslagType === 'Nyhed' &&
            !opslag.slettet
          "
          locate-in-menu="auto"
        >
          <LetButton
            icon="email"
            text="Send notifikation"
            type="default"
            styling-mode="text"
            @click="sendNotifikation"
            :loading="notifikationSending"
          />
        </DxItem>
        <DxItem location="after" v-if="opslag.kladde">
          <div class="advarsel">Ikke publiceret!</div>
        </DxItem>
        <DxItem
          location="after"
          v-if="opslag.kladde && hasRettighed(RettighedsType.LetguideOpslagAdmin)"
          locate-in-menu="auto"
        >
          <LetButton
            icon="export"
            text="Publicér opslag"
            :use-submit-behavior="false"
            @click="publicerOpslag"
            type="default"
            styling-mode="text"
          />
        </DxItem>
        <DxItem
          location="after"
          v-if="hasRettighed(RettighedsType.LetguideOpslagAdmin)"
          locate-in-menu="auto"
        >
          <router-link
            v-if="opslag.kladdeOwner === authState.bruger.email || opslag.kladdeOwner == null"
            :to="`${ROUTE_OPSLAG}/${id}/edit`"
          >
            <let-button icon="edit" text="Rediger opslag" type="default" styling-mode="text" />
          </router-link>
          <div v-else>Opslaget redigeres af {{ opslag.kladdeOwner }}</div>
        </DxItem>
      </DxToolbar>
      <LetReadingArea :sidebredde="opslag.sidebredde">
        <h1 v-if="opslag.opslagType !== 'Indhold'">{{ opslag.overskrift }}</h1>
        <div
          class="opslag-dato"
          v-if="opslag.publiceringsTidspunkt && opslag.opslagType === 'Nyhed'"
        >
          {{ formatDate(new Date(opslag.publiceringsTidspunkt), 'dd-MM-yyyy') }}
        </div>
        <div
          class="opslag-forfatter"
          v-if="
            opslag.opslagType !== 'Indhold' &&
            opslag.opslagType !== 'Vejledning' &&
            opslag.opslagType !== 'Q&A'
          "
        >
          {{ opslag.forfatter }}
        </div>
        <div class="opslag-tags" v-if="opslag.opslagType === 'Nyhed'">
          <div
            v-for="adgang in opslag.adgange"
            :key="adgang.id"
            class="opslag-tag"
            :class="'bg-orange'"
          >
            {{ adgang.rolle.navn }}
          </div>
          <div
            v-for="tag in opslag.tags"
            :key="tag.id"
            class="opslag-tag"
            :class="`bg-${tag.color || 'green'}`"
          >
            {{ tag.navn }}
          </div>
        </div>

        <hr v-if="opslag.opslagType === 'Nyhed'" />
        <OpslagMeta
          :opslag="opslag"
          v-if="opslag.opslagType === 'Vejledning' || opslag.opslagType === 'Produktside'"
        />
        <OpslagToc
          :opslag="opslag"
          v-if="opslag.opslagType === 'Vejledning' || opslag.opslagType === 'Produktside'"
        />
        <LetTipTapEditor :model-value="opslag.tekst" :editable="false" />
        <div
          v-if="
            opslag.links?.length > 0 &&
            (opslag.opslagType === 'Vejledning' || opslag.opslagType === 'Produktside')
          "
          class="let-section-container"
        >
          <div class="let-section-column">
            <h3 id="relaterede-materialer-anchor">Relaterede materialer</h3>
          </div>
          <div class="let-section-column" size="double">
            <OpslagLinkList :opslag="opslag" />
          </div>
        </div>
      </LetReadingArea>
    </div>
    <div v-if="opslagFindesIkke">
      Opslag findes ikke. Klik <router-link :to="ROUTE_HOME">her</router-link> for at komme retur
      til forsiden
    </div>
  </LetPage>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.advarsel {
  font-weight: bold;
  color: $letpension-error;
  margin-right: 20px;
}

.opslag-dato {
  color: $letpension-grey;
}

.opslag-forfatter {
  color: $letpension-grey;
}

.opslag-overskrift {
  font-family: Marcia;
  font-size: 3.125rem;
  line-height: 1;
  padding: 20px 0;
}

.opslag-tekst {
  margin-top: 20px;
}

.opslag-tags {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.opslag-tag {
  padding: 1px 8px;
  border-radius: 3px;
  font-weight: 600;
  color: white;
  white-space: nowrap;
}

.opslag-relaterede-materialer {
  margin-top: 30px;
  padding: 20px;
  background-color: $letpension-sand;
}

.return-to-top {
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 1s ease,
    visibility 1s ease;
  position: fixed;
  bottom: 50px;
  right: 100px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 100;

  i {
    font-size: 2.25rem;
  }

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}

.seneste-notifikation {
  padding-top: 3px;
}

@media print {
  .return-to-top {
    display: none;
  }
}

@media (min-width: 800px) {
  .page-footer {
    padding: 30px 131px;
  }

  :deep(.hide-on-mobile) {
    display: table-cell;
  }
}

@media (max-width: 900px) {
  :deep(.hide-on-mobile) {
    display: none;
  }
}
</style>
