<script setup lang="ts">
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore';
import { onMounted, ref, watchEffect } from 'vue';
import KvartalsrapportDoed from './KvartalsrapportDoed.vue';
import KvartalsrapportFinansPlus from './KvartalsrapportFinansPlus.vue';
import KvartalsrapportForord from './KvartalsrapportForord.vue';
import KvartalsrapportForside from './KvartalsrapportForside.vue';
import KvartalsrapportHovedtal from './KvartalsrapportHovedtal.vue';
import KvartalsrapportLivrente from './KvartalsrapportLivrente.vue';
import KvartalsrapportRaadgiverAdfaerd from './KvartalsrapportRaadgiverAdfaerd.vue';
import KvartalsrapportSamletIndtjening from './KvartalsrapportSamletIndtjening.vue';
import KvartalsrapportSection from './KvartalsrapportSection.vue';
import KvartalsrapportSygdom from './KvartalsrapportSygdom.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const store = useKvartalsrapportStore();
const regnr = ref<string>();
const rapportId = ref<number>();
const ready = ref(false);

function loadData() {
  store.loadData(rapportId.value, regnr.value);
}

onMounted(() => {
  const rapportIdParam = route.query.rapportId as string;
  rapportId.value = Number.parseInt(rapportIdParam);
  regnr.value = route.query.regnr as string;
  loadData();
});

watchEffect(() => {
  if (
    store.rapport &&
    store.rangliste &&
    store.indtjening &&
    store.aktivitet &&
    store.udvikling &&
    store.finansPlus
  ) {
    ready.value = true;
    setTimeout(window.print, 2000);
    window.onafterprint = function () {
      window.close();
    };
  }
});
</script>

<template>
  <KvartalsrapportSection>
    <div class="content" v-if="ready">
      <div class="page">
        <KvartalsrapportForside />
      </div>
      <div class="page">
        <KvartalsrapportForord :print="true" />
      </div>
      <div class="page">
        <KvartalsrapportHovedtal :print="true" />
      </div>
      <div class="page">
        <KvartalsrapportLivrente :print="true" />
      </div>
      <div class="page">
        <KvartalsrapportDoed :print="true" />
      </div>
      <div class="page">
        <KvartalsrapportSygdom :print="true" />
      </div>
      <div class="page">
        <KvartalsrapportRaadgiverAdfaerd :print="true" />
      </div>
      <div class="page" v-if="store.pengeinstitut.finansPlus">
        <KvartalsrapportFinansPlus :print="true" />
      </div>
      <KvartalsrapportSamletIndtjening :print="true" />
    </div>
    <div v-else>Henter data...</div>
  </KvartalsrapportSection>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.content {
  width: 1400px;
  margin: 20px;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.page {
  page-break-after: always;
}
</style>
