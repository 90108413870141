<script setup>
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';

const props = defineProps({
  updateAttributes: { type: Function, required: true },
});

const colors = [
  {
    value: 'normal',
    text: 'Normal',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#ffffff" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>',
  },
  {
    value: 'blue',
    text: 'Blå',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#6bb8bc" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>',
  },
  {
    value: 'sand',
    text: 'Sand',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#F6F0E7FF" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>  ',
  },
  {
    value: 'grey',
    text: 'Grå',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#BFBFBF26" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>',
  },
  {
    value: 'green',
    text: 'Grøn',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#b9c35d" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>',
  },
  {
    value: 'orange',
    text: 'Orange',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 22 22"><path fill="#e49d3b" stroke="#b8926f" stroke-width="1" d="M2 20V4h20v16H2Z"></path></svg>',
  },
];

function onItemClick(e) {
  const itemData = e.itemData;

  if (itemData.value === 'normal') {
    props.updateAttributes({ color: null });
  } else {
    props.updateAttributes({ color: itemData.value });
  }
}
</script>

<template>
  <DxDropDownButton
    hint="Skift baggrundsfarve"
    :items="colors"
    :dropDownOptions="{
      width: 120,
      wrapperAttr: { class: 'action-dropdown-list' },
    }"
    icon="palette"
    displayExpr="text"
    keyExpr="value"
    @item-click="onItemClick"
    :element-attr="{ style: 'background-color:white' }"
  >
  </DxDropDownButton>
</template>

<style scoped lang="scss">
//.field-text-box {
//  .dx-texteditor-input {
//    border: none;
//  }
//}
//
//.let-tiptap-toolbar .dx-selectbox .field-text-box .dx-texteditor-input {
//  border: none;
//}
//
//.field-icon {
//  position: relative;
//  font-size: 1.5rem;
//  top: 1px;
//  padding-left: 4px;
//}
//
//.item-icon {
//  position: relative;
//  font-size: 1.5rem;
//  top: 6px;
//  margin-right: 8px;
//}
//
//.item-text {
//  margin-top: 0;
//}
//
//.dx-dropdownlist-popup-wrapper
//  .dx-list:not(.dx-list-select-decorator-enabled)
//  .dx-list-item-content.select-box-item {
//  padding: 2px 8px;
//}
</style>
