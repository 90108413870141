<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getOptions() {
  const series = getSeries();
  return Object.assign({}, getDefaultOptions(props.print), {
    chart: {
      ...getDefaultOptions(props.print).chart,
      dropShadow: {
        enabled: false,
      },
    },
    dataLabels: {
      dropShadow: false,
      style: {
        colors: ['#000'],
      },
      background: {
        enabled: true,
        foreColor: '#fff',
      },
    },

    // labels: [formatNumber([0]), formatNumber([1])],
    labels: [formatNumber(series[0]), formatNumber(series[1]) + ' (30%)'],
  });
}

function getSeries() {
  const salgProduktGrupper = store.udvikling.salgProduktGrupper;
  const salg = salgProduktGrupper.filter((gruppe) => gruppe.salgProduktGruppeNavn === 'Pension')[0];
  const salg30pct = salgProduktGrupper.filter(
    (gruppe) => gruppe.salgProduktGruppeNavn === 'Livrente30Pct',
  )[0];
  const senesteKvartal = salg.kvartalSalg.slice(-1)[0];
  const senesteKvartal30pct = salg30pct.kvartalSalg.slice(-1)[0];

  return [
    senesteKvartal.sumLoebendeIndbetaling +
      senesteKvartal.sumIndskud -
      (senesteKvartal30pct.sumLoebendeIndbetaling + senesteKvartal30pct.sumIndskud),
    senesteKvartal30pct.sumLoebendeIndbetaling + senesteKvartal30pct.sumIndskud,
  ];
}
</script>

<template>
  <div class="chart" v-if="store.udvikling.salgProduktGrupper">
    <apexchart height="100%" type="pie" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
