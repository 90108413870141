<script setup>
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import LetLoadIndicator from '@/letapps-vue/components/LetLoadIndicator.vue';
import RisikoNysalgChart from '@/pages/kvartalsrapport/charts/RisikoNysalgChart.vue';
import RisikoBestandChart from '@/pages/kvartalsrapport/charts/RisikoBestandChart.vue';
import RisikoBestandPrMedarbejderChart from '@/pages/kvartalsrapport/charts/RisikoBestandPrMedarbejderChart.vue';
import RisikoNysalgModTop5Chart from '@/pages/kvartalsrapport/charts/RisikoNysalgModTop5Chart.vue';
import RisikoSalgPrUgeChart from '@/pages/kvartalsrapport/charts/RisikoSalgPrUgeChart.vue';

defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();
</script>

<template>
  <div class="titel">
    Letsikring ved sygdom<span class="undertitel">sygdom + kritisk sygdom + helbred</span>
  </div>
  <div class="flex-container container" v-if="store.udvikling">
    <div class="flex-1">
      <div class="chart-titel">Hvor stort er nysalget i stk.?</div>
      <RisikoNysalgChart produkt-gruppe-navn="SygdomSamlet" :print="print" />
      <div class="chart-titel">Hvor stor er bestanden i stk.?</div>
      <RisikoBestandChart produkt-property-navn="antalSygdom" :print="print" />
      <div class="chart-titel">Hvor stor er bestanden i stk. pr. 100 medarbejdere?</div>
      <RisikoBestandPrMedarbejderChart produkt-property-navn="antalSygdom" :print="print" />
    </div>
    <div class="flex-1">
      <div class="chart-titel">
        Hvordan er salget i stk. pr. 100 medarbejdere – sammenlignet med top 5
      </div>
      <RisikoNysalgModTop5Chart produkt-gruppe-navn="SygdomSamlet" :print="print" />
      <div class="chart-titel">Hvordan er salget sammenlignet med sidste års salg i stk.?</div>
      <RisikoSalgPrUgeChart produkt-gruppe-navn="SygdomSamlet" :print="print" />
    </div>
  </div>
  <div v-else>
    <LetLoadIndicator :visible="true" />
    <div>Henter udvikling...</div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';
</style>
