<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    xaxis: {
      labels: {
        rotateAlways: true,
        rotate: -45,
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (value) {
        return formatNumber(value, 1, 0);
      },
    },
  });
}

function getSeries() {
  const periodeAktiviteter = store.aktivitet.periodeAktivitet;
  const senesteAktivitetsPeriode = periodeAktiviteter[periodeAktiviteter.length - 1];

  let antalLivrente = 0;
  let antalBarn = 0;
  let antalLaanOgKredit = 0;
  let antalDoed = 0;
  let antalSygdom = 0;
  let antalKritiskSygdom = 0;
  let antalHelbred = 0;
  let antalKapitalpension = 0;
  let antalRatepension = 0;
  let antalAldersopsparing = 0;

  for (let i = 0; i < periodeAktiviteter.length; i++) {
    if (periodeAktiviteter[i].aar === senesteAktivitetsPeriode.aar) {
      let periodeAktivitet = periodeAktiviteter[i];
      antalLivrente += periodeAktivitet.SumAntalLivrenter;
      antalBarn += periodeAktivitet.SumAntalBoernerenter;
      antalLaanOgKredit += periodeAktivitet.SumAntalLaanOgKredit;
      antalDoed += periodeAktivitet.SumAntalDoed;
      antalSygdom += periodeAktivitet.SumAntalSygdom;
      antalKritiskSygdom += periodeAktivitet.SumAntalKritiskSygdom;
      antalHelbred += periodeAktivitet.SumAntalHelbred;
      antalAldersopsparing += periodeAktivitet.SumAntalAldersopsparinger;
      antalKapitalpension += periodeAktivitet.SumAntalKapitalpensioner;
      antalRatepension += periodeAktivitet.SumAntalRatepensioner;
    }
  }

  const series = [
    {
      data: [
        { x: 'Livrenter', y: antalLivrente },
        { x: 'Børnerenter', y: antalBarn },
        { x: 'Lån og kredit', y: antalLaanOgKredit },
        { x: 'Død', y: antalDoed },
        { x: 'Sygdom', y: antalSygdom },
        { x: 'Kritisk sygdom', y: antalKritiskSygdom },
        { x: 'Helbred', y: antalHelbred },
        { x: 'Kapitalpension', y: antalKapitalpension },
        { x: 'Ratepension', y: antalRatepension },
        { x: 'Aldersopsparing', y: antalAldersopsparing },
      ],
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 300px;
}
</style>
