<script setup>
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';
import addAccordionIcon from '@/letapps-vue/icons/accordion.svg';
import addTabsIcon from '@/letapps-vue/icons/tabs.svg';

const props = defineProps({
  editor: Object,
});

const components = [
  {
    value: 'section',
    text: 'Sektion',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 2048 2048"><path fill="currentColor" d="M0 1792V256h1280v1536H0zM128 384v1280h1024V384H128zm1280-128h640v1536h-640V256zm512 1408V384h-384v1280h384z"/></svg>',
  },
  {
    value: 'table',
    text: 'Tabel',
    icon: 'inserttable',
  },
  {
    value: 'accordion',
    text: 'Harmonika',
    icon: addAccordionIcon,
  },
  {
    value: 'tabs',
    text: 'Faneblade',
    icon: addTabsIcon,
  },
];

function onSelectComponent(e) {
  const { value } = e.itemData;

  switch (value) {
    case 'table':
      addTable();
      break;
    case 'section':
      addSection();
      break;
    case 'accordion':
      addAccordion();
      break;
    case 'tabs':
      addTabs();
      break;
    default:
  }
}

function addTable() {
  props.editor.chain().insertLetTable({ className: 'let-tiptap-table' }).run();
}

function addSection() {
  props.editor.chain().insertSection().run();
}

function addAccordion() {
  props.editor.chain().insertAccordion().run();
}
function addTabs() {
  props.editor.chain().insertTabs(3).run();
}
</script>

<template>
  <DxDropDownButton
    :items="components"
    :dropDownOptions="{ width: 200 }"
    icon="plus"
    displayExpr="text"
    keyExpr="value"
    @item-click="onSelectComponent"
    :elementAttr="{ title: 'Indsæt webpart' }"
  >
  </DxDropDownButton>
</template>

<style scoped lang="scss"></style>
