<script setup>
import { ref, watch } from 'vue';
import DxDataGrid, { DxColumn, DxExport, DxPaging } from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { indevaerendeAar } from '@/utils/rapporteringUtils';
import LetPage from '@/letapps-vue/components/LetPage';
import RapportParameters from '@/components/rapportering/RapportParameters';

const { apiRoot } = getAppConfig();
const periodOptions = ref({
  aar: indevaerendeAar,
  periodeType: 'uge',
  maaned: 'ÅTD',
  uge: null,
});
const grid = ref();

const dataSource = new CustomStore({
  load: () =>
    axios
      .get(`${apiRoot}/api/salgsrapportering/nysalgtotaler`, { params: periodOptions.value })
      .then((response) => response.data),
});

function onToolbarPreparing(e) {
  e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'periodSelector',
  });
}

function onExporting(e) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Salgsrapport');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/excel' }),
        `Salgsrapport-${periodOptions.value.aar}-${periodOptions.value.maaned}.xlsx`,
      );
    });
  });
  e.cancel = true;
}

function onCellPrepared(e) {
  if (e.rowType === 'data') {
    e.cellElement.style.fontWeight = e.data.pengeInstitut === 'All' ? 'bold' : 'normal';
  }
}

watch([periodOptions], () => grid.value.instance.refresh(), { deep: true });
</script>

<template>
  <let-page title="Nysalg totaler">
    <DxDataGrid
      ref="grid"
      :data-source="dataSource"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
      @cell-prepared="onCellPrepared"
    >
      <template #periodSelector>
        <RapportParameters v-model="periodOptions" />
      </template>

      <DxExport :enabled="true" />

      <DxPaging :page-size="100"></DxPaging>
      <DxColumn
        data-field="pengeInstitut"
        caption="Pengeinstitut"
        data-type="string"
        :width="300"
      />
      <DxColumn caption="Letsikring af indtægt ved pension">
        <DxColumn
          data-field="antalLivrenter"
          caption="Antal"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="loebendeIndbetalinger"
          caption="Lb indbet"
          data-type="number"
          cssClass="numbers"
          format="#,###"
          :min-width="140"
        />
        <DxColumn
          data-field="indskud"
          caption="Indskud"
          data-type="number"
          cssClass="numbers"
          format="#,###"
          :min-width="140"
        />
        <DxColumn
          data-field="antalOverloeb"
          caption="Overløb"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalParagraf41"
          caption="§41"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalParagraf21"
          caption="§21"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
      </DxColumn>
      <DxColumn caption="Risikodækninger">
        <DxColumn
          data-field="antalRisikoIAlt"
          caption="I alt"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalDoed"
          caption="Død"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalLaanOgKredit"
          caption="Lån og kredit"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalBarn"
          caption="Barn"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalSygdom"
          caption="Sygdom"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalKritiskSygdom"
          caption="Kritisk sygdom"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
        <DxColumn
          data-field="antalHelbred"
          caption="Helbred"
          data-type="number"
          cssClass="numbers"
          format="#,###"
        />
      </DxColumn>
    </DxDataGrid>
  </let-page>
</template>
