<script setup lang="ts">
import { isLetpensionBruger } from '@/letapps-vue/utils/auth';
import RapportSelectBox from './RapportSelectBox.vue';
import AarSelectBox from './AarSelectBox.vue';
import MaanedSelectBox from './MaanedSelectBox.vue';
import UgeSelectBox from './UgeSelectBox.vue';
import PeriodeTypeSelectBox from './PeriodeTypeSelectBox.vue';
import RegnrSelectBox from './RegnrSelectBox.vue';

interface RapportOptions {
  rapport: string;
  aar: number;
  maaned: string;
  periodeType: string;
  uge: string;
  regnr: string;
}

const model = defineModel<RapportOptions>();
</script>

<template>
  <table v-if="model" class="param-container">
    <tr>
      <td class="label">Rapport:</td>
      <td>
        <RapportSelectBox v-model="model.rapport" :width="300" />
      </td>
    </tr>
    <tr>
      <td class="label">År:</td>
      <td>
        <AarSelectBox v-model="model.aar" :width="300" />
      </td>
    </tr>
    <tr
      v-if="
        [
          'nysalg-pi',
          'nysalg-pi-lts',
          'nysalg-pi-privat',
          'ydelsesaendringer-pi',
          'skiftafpengeinstitut-pi',
          'uafsluttetpiskifte-pi',
          'nysalg-detaljer-pi',
          'nysalg-udvalg-pi',
        ].includes(model.rapport)
      "
    >
      <td class="label">Periode:</td>
      <td>
        <PeriodeTypeSelectBox v-model="model.periodeType" :rapport="model.rapport" :width="300" />
      </td>
    </tr>
    <tr
      v-if="
        [
          'bestand-livrente-pi',
          'bestand-livrente-pi-privat',
          'bestand-risiko-pi',
          'bestand-risiko-pi-privat',
          'livrenteunderudbetaling-pi',
          'raadgiverstatistik-maaned-pi',
        ].includes(model.rapport)
      "
    >
      <td class="label">Måned:</td>
      <td>
        <MaanedSelectBox v-model="model.maaned" :aar="model.aar" :width="300" />
      </td>
    </tr>
    <tr
      v-if="
        [
          'nysalg-pi',
          'nysalg-pi-privat',
          'nysalg-pi-lts',
          'raadgiverstatistik-uge-pi',
          'raadgiverstatistikdetaljer-uge-pi',
          'ydelsesaendringer-pi',
          'skiftafpengeinstitut-pi',
          'uafsluttetpiskifte-pi',
          'nysalg-detaljer-pi',
          'nysalg-udvalg-pi',
        ].includes(model.rapport)
      "
    >
      <td class="label">Uge:</td>
      <td>
        <UgeSelectBox v-model="model.uge" :aar="model.aar" :width="300" />
      </td>
    </tr>
    <tr v-if="isLetpensionBruger()">
      <td class="label">Pengeinstitut:</td>
      <td>
        <Suspense>
          <RegnrSelectBox v-model="model.regnr" :width="300" />
        </Suspense>
      </td>
    </tr>
  </table>
</template>

<style scoped>
.param-container {
  border-spacing: 5px;
}

.label {
  font-weight: bold;
  width: 150px;
}
</style>
