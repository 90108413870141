import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let store;

export function getFinansPlusDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/FinansPlus', 'id', {
      paginate: false,
    });
  }

  return store;
}
