<script setup>
import SearchResult from '@/components/search/SearchResult.vue';
import LetReadingArea from '@/letapps-vue/components/LetReadingArea.vue';
import { DxTextBox } from 'devextreme-vue/text-box';
import { onMounted, ref } from 'vue';
import { useSearchStore } from '@/stores/searchStore';

const searchStore = useSearchStore();
const searchField = ref();
const searchString = ref(searchStore.filter);

function doSearch(e) {
  searchStore.doSearch(searchString.value);
}

onMounted(() => {
  setTimeout(function () {
    searchField.value.instance.focus();
  }, 100);
});
</script>

<template>
  <LetReadingArea sidebredde="normal">
    <div class="search-container">
      <DxTextBox
        ref="searchField"
        mode="search"
        v-model="searchString"
        placeholder="Indtast søgekriterie..."
        class="search-field"
        @value-changed="doSearch"
      />
    </div>
    <SearchResult :filter="searchStore.filter" />
  </LetReadingArea>
</template>

<style scoped lang="scss">
@import '@/css/variables.letguide.scss';

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .search-field {
    width: 600px;
  }
}
</style>
