import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let maalgruppeRolleStore;
export function getMaalgruppeRolleDatasource() {
  if (!maalgruppeRolleStore) {
    maalgruppeRolleStore = createDevextremeDatasource('/api/Roller', 'id', {});
    maalgruppeRolleStore.filter('maalgruppe', '=', true);
  }

  return maalgruppeRolleStore;
}
