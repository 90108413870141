<script setup>
import dayjs from 'dayjs';
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { formatNumber } from '../kvartalsrapportUtil';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';

const store = useKvartalsrapportStore();

const props = defineProps({
  produkt: { type: String, required: true },
  print: { type: Boolean, default: false },
});

function getData() {
  const salgProduktGrupper = store.udvikling.salgProduktGrupper;
  const salg = salgProduktGrupper.filter(
    (gruppe) => gruppe.salgProduktGruppeNavn === props.produkt,
  )[0];
  const kvartalSalg = salg.kvartalSalg;
  return kvartalSalg.slice(kvartalSalg.length - 2);
}

function getOptions() {
  const sidsteKvartaler = getData();

  return Object.assign({}, getDefaultOptions(props.print), {
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },

    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },

      categories: sidsteKvartaler.map(
        (x) => `Q${dayjs(x.periodeTil).quarter()}-${dayjs(x.periodeTil).year()}`,
      ),
    },
    plotOptions: {
      bar: {
        distributed: true,
        columnWidth: '100%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    legend: { show: false },
    dataLabels: {
      style: {
        fontSize: '11px',
        fontWeight: '400',
        colors: ['#000'],
      },
      offsetY: -20,

      formatter: function (value) {
        if (props.produkt === 'Pension') return formatNumber(value, 1, 1);
        else return formatNumber(value);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  const sidsteKvartaler = getData();

  return [
    {
      data: sidsteKvartaler.map((x) => {
        if (props.produkt === 'Pension') return x.beloeb / 1000000;
        else return x.antal;
      }),
    },
  ];
}
</script>

<template>
  <div class="chart" v-if="store.udvikling?.salgProduktGrupper">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 170px;
}
</style>
