<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import dayjs from 'dayjs';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

let detteAar;

function prepareData() {
  const salgProduktGrupper = store.udvikling.salgProduktGrupper;
  const salg = salgProduktGrupper.filter((gruppe) => gruppe.salgProduktGruppeNavn === 'Pension')[0];
  const maanedSalg = salg.maanedSalg;
  detteAar = maanedSalg.slice(maanedSalg.length - 12);
}
function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    dataLabels: {
      enabled: false,
      formatter: function (value) {
        return formatNumber(value, 1, 1);
      },
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  prepareData();

  const series = [
    {
      name: 'Top 5 pengeinstitutter gns. pr. 100 medarbejdere',
      data: detteAar.map((x) => {
        return { x: `${dayjs(x.periodeTil).format('MMM')}`, y: x.top5Salg / 1000000 };
      }),
    },
    {
      name: store.pengeinstitut.navn,
      data: detteAar.map((x) => {
        return { x: `${dayjs(x.periodeTil).format('MMM')}`, y: x.gennemsnitligSalg / 1000000 };
      }),
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart" v-if="store.udvikling.salgProduktGrupper">
    <apexchart height="100%" type="bar" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
