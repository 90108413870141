<script setup type="ts">
import PageHeader from '@/layout/default/PageHeader.vue';
import PageFooter from '@/layout/default/PageFooter.vue';
import { ref } from 'vue';
import { debounce } from '@/letapps-vue/utils/debounce';
import TopNavSubMenu from '@/letapps-vue/layouts/topnav/TopNavSubMenu.vue';
import { DxDrawer } from 'devextreme-vue';

const menuIsOpen = ref(false);
const visibleSubMenu = ref({
  text: '',
  left: 0,
});

function showMenuDebounced(show, submenu) {
  menuIsOpen.value = show;
  visibleSubMenu.value.text = submenu.text;
  visibleSubMenu.value.left = submenu.left;
}

const showMenu = debounce((visible, submenu) => {
  showMenuDebounced(visible, submenu);
}, 300);

function closeTopMenu() {
  menuIsOpen.value = false;
}
</script>

<template>
  <PageHeader :show-menu-func="showMenu" />
  <!-- <DxDrawer
    class="layout-body"
    position="top"
    template="menuTemplate"
    v-model:opened="menuIsOpen"
    reveal-mode="expand"
  > -->
  <div class="page-content">
    <slot />
  </div>
  <PageFooter />
  <!-- <template #menuTemplate>
      <TopNavSubMenu
        @click="closeTopMenu"
        :visible-sub-menu="visibleSubMenu"
        :show-menu-func="showMenu"
      />
    </template>
  </DxDrawer> -->
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension';

.page-content {
  min-height: calc(100vh - 185px);
  padding: 0 20px;
  margin-top: 20px;
}

@media (min-width: 800px) {
  .page-content {
    padding: 0 131px;
  }
}
</style>
