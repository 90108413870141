<script setup lang="ts">
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';

const router = useRouter();

function onClickForside() {
  router.push('/');
}
</script>

<template>
  <div class="error-404">
    <img src="../assets/letpension-logo.svg" class="logo" alt="Letpension logo" />
    <div>
      <div class="message">Siden findes ikke</div>

      <DxButton text="Til forsiden" @click="onClickForside" type="default" class="home-button" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../css/variables.letpension';

.error-404 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $letpension-sand;
  background-image: url(../assets/letpension-facet.svg);
  background-repeat: no-repeat;
  background-size: 6000px;
  background-position: -2700px -2700px;
  text-align: center;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logo {
  position: fixed;
  top: 25px;
  left: 25px;
  height: 50px;
}

.message {
  font-size: 2.6rem;
}

.home-button {
  margin-top: 48px;
}
</style>
