import { getOrganisationDatasource } from '@/data/organisation';
import { driftstatusValues } from '@/data/driftstatus';

export const getOrgDropdownOptions = () => ({
  dataSource: getOrganisationDatasource(true),
  valueExpr: 'id',
  displayExpr: 'navn',
  searchEnabled: true,
});
export const getDriftOrgDropdownOptions = () => ({
  // dataSource: getDriftOrgDatasource(true),
  dataSource: [
    {
      id: 1,
      navn: 'Alle',
    },
    {
      id: 3,
      navn: 'BankData',
    },
    {
      id: 4,
      navn: 'BEC',
    },
    {
      id: 5,
      navn: 'SDC',
    },
  ],
  valueExpr: 'id',
  displayExpr: 'navn',
  searchEnabled: true,
});

export const getDriftStatusDropdownOptions = () => ({
  dataSource: driftstatusValues,
  valueExpr: 'status',
  displayExpr: 'text',
});
