<script setup>
import { nextTick, onMounted, ref } from 'vue';

defineProps({
  opslag: {
    type: Object,
    required: true,
  },
});

const headings = ref([]);
const root = ref(null);

nextTick(handleUpdate);

onMounted(() => {
  // props.editor.on('update', handleUpdate);
});

function handleUpdate() {
  if (!root.value) return;

  const opslagDoc =
    root.value.closest('.opslag-type-Vejledning') || root.value.closest('.opslag-type-Produktside');
  if (!opslagDoc) return;

  const headingsInDoc = [];

  const allHeadings = opslagDoc.querySelectorAll('h3, [id="relaterede-materialer-anchor"]');

  allHeadings.forEach((el) => {
    const textWithoutTag = el.innerHTML.replace(/(<([^>]+)>)/gi, '');
    let id = textWithoutTag.replaceAll(' ', '-');

    // let id = `heading-${headingsInDoc.length + 1}`;
    if (el.getAttribute(id)) {
      id = el.getAttribute(id);
    } else {
      el.setAttribute('id', id);
    }

    headingsInDoc.push({
      level: el.tagName.substr(1),
      text: textWithoutTag,
      id,
    });
  });

  headings.value = headingsInDoc;

  setTimeout(() => {
    if (location.hash) {
      var requested_hash = location.hash.slice(1);
      location.hash = '';
      location.hash = requested_hash;
    }
  }, 10);
}
</script>

<template>
  <div ref="root">
    <div class="let-toc" v-if="headings.length > 0">
      <div class="let-toc__title">Indhold</div>
      <ul class="let-toc__list">
        <li
          class="let-toc__item"
          :class="`let-toc__item--${heading.level}`"
          v-for="(heading, index) in headings"
          :key="index"
        >
          <a :href="`#${heading.id}`" class="let-toc__link">
            {{ heading.text }}&nbsp;<i class="dx-icon-arrowright" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.let-toc {
  margin-bottom: 30px;
  padding: 0;

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    color: black;
    font-size: 1rem;
    text-decoration: none;
  }

  &__item {
    a:hover {
      opacity: 0.5;
    }

    &--4 {
      padding-left: 1rem;
    }

    &--5 {
      padding-left: 2rem;
    }

    &--6 {
      padding-left: 3rem;
    }
  }
}
</style>
