<script setup>
import {
  DxColumn,
  DxDataGrid,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxItem } from 'devextreme-vue/toolbar';
import { getFilDatasource } from '@/data/fil';
import { ROUTE_FILER } from '@/router/routeNames';
import { useRouter } from 'vue-router';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import LetLink from '@/components/filer/LetLink.vue';

const router = useRouter();

const filDatasource = getFilDatasource();

function onRowClick(e) {
  const filId = e.data.id;
  router.push(`${ROUTE_FILER}/${filId}`);
}

function opretFil() {
  router.push(`${ROUTE_FILER}/-1`);
}

function getLink(data) {
  return getAppConfig().apiRoot + '/api/Fil/' + (data.stinavn || data.id);
}
</script>

<template>
  <DxDataGrid
    :data-source="filDatasource"
    :remote-operations="true"
    height="100%"
    :focused-row-enabled="true"
    :row-alternation-enabled="true"
    :show-column-lines="false"
    :hover-state-enabled="true"
    :show-borders="true"
    @row-click="onRowClick"
  >
    <DxScrolling mode="virtual" row-rendering-mode="virtual" :preload-enabled="true" />
    <DxPaging :page-size="50" />
    <DxSearchPanel :visible="true" :highlight-search-text="false" placeholder="Søg..." />
    <DxToolbar>
      <DxItem location="before">
        <DxButton
          text="Opret fil"
          icon="plus"
          @click="opretFil"
          type="default"
          styling-mode="text"
        />
      </DxItem>
      <DxItem name="searchPanel" location="after" />
    </DxToolbar>
    <DxColumn data-field="filnavn" sort-order="asc" cell-template="linkTemplate" />
    <DxColumn data-field="navn" />
    <template #linkTemplate="{ data }">
      <LetLink :href="getLink(data.data)">{{ data.value }}</LetLink>
    </template>
  </DxDataGrid>
</template>

<style></style>
