<script setup>
import DriftbeskedEdit from '@/components/driftstatus/DriftbeskedEdit.vue';
import DriftbeskedView from '@/components/driftstatus/DriftbeskedView.vue';
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { RettighedsType } from '@/types/rettighed';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();
const id = computed(() => Number.parseInt(route.params.id));
const viewMode = computed(() => route.params.viewMode);
</script>

<template>
  <Suspense>
    <DriftbeskedEdit :id="id" v-if="viewMode === 'edit' && hasRettighed(RettighedsType.LetguideDriftbesked)" />
    <DriftbeskedView :id="id" v-else />
  </Suspense>
</template>

<style scoped lang="scss"></style>
