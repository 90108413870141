<script setup lang="ts">
const props = defineProps({
  fit: {
    type: Boolean,
    default: false,
  },
  title: { type: String },
});

function getClasses() {
  if (props.fit) return ['let-page-fit'];
  return [];
}
</script>

<template>
  <div class="let-page" :class="getClasses()">
    <div class="flex-container">
      <div v-if="title" class="let-page-title">{{ title }}</div>
      <div v-if="title" class="let-page-title-tools">
        <slot name="page-tools" />
      </div>
    </div>
    <div class="let-page-content">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.screenshot {
  width: 100%;
  border: 1px solid lightgray;
}

div.side-nav .let-page {
  padding: 20px 30px;
}

@media print {
  .let-page {
    padding: 0;
  }
}

.let-page-content {
  .flex-container {
    gap: 0;
  }
}

.let-page-title {
  flex: 1;
  font-family: marcia, georgia, serif;
  font-size: 2.6rem;
  font-weight: 500;
  padding: 5px 0 16px 0;
  line-height: 1.2;
  letter-spacing: -0.8px;
  font-feature-settings: 'liga' off;
  margin-bottom: 8px;
}

.let-page-title-tools {
}

.let-page-content {
  flex-grow: 1;
  overflow: hidden;
}

.let-page-fit {
  height: calc(100vh);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.top-nav .let-page-fit {
  height: calc(100vh - 140px);
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
