<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import TiptapComponentWrapper from '@/tiptap/component/TiptapComponentWrapper.vue';
import { watchEffect } from 'vue';
import { useAccordionStore } from '@/tiptap/let-accordion/accordionStore';
import { DxButton } from 'devextreme-vue/button';

const props = defineProps(nodeViewProps);

const store = useAccordionStore();

watchEffect(() => {
  store.setChildCount(props.node.childCount);
});

function onMouseEnter() {
  //Needed to have correct number, if we have multiple accordions in the same editor
  store.setChildCount(props.node.childCount);
}
</script>

<template>
  <node-view-wrapper class="let-accordion-container" @mouseenter="onMouseEnter">
    <tiptap-component-wrapper title="Harmonika" :editor="editor">
      <node-view-content />
      <template #buttons>
        <DxButton icon="trash" @click="deleteNode" />
      </template>
    </tiptap-component-wrapper>
  </node-view-wrapper>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.let-accordion-container {
  border-bottom: 1px solid $letpension-grey;
  margin-bottom: 10px;
}
</style>
