<script setup>
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore';
import { onMounted, ref, watchEffect } from 'vue';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar.js';
import RegnrSelectBox from '@/components/rapportering/RegnrSelectBox.vue';
import KvartalsrapportSelectBox from '@/components/rapportering/KvartalsrapportSelectBox.vue';
import DxButton from 'devextreme-vue/button';
import { DxSelectBox } from 'devextreme-vue/select-box.js';
import { useRouter } from 'vue-router';
import { ROUTE_KVARTALSRAPPORT_PRINT } from '@/router/routeNames';
import KvartalsrapportSection from './KvartalsrapportSection.vue';
import { authState } from '@/letapps-vue/utils/auth';
import { getKvartalsrapportDatasource } from '@/data/kvartalsrapport';

const router = useRouter();

const store = useKvartalsrapportStore();
const regnr = ref();
const rapportId = ref();
const side = ref();
const sider = ref([]);

watchEffect(() => {
  if (store.pengeinstitut) {
    const sideList = [
      { text: 'Forside', value: '/kvartalsrapport/forside' },
      { text: 'Forord', value: '/kvartalsrapport/forord' },
      { text: 'Hovedtal', value: '/kvartalsrapport/hovedtal' },
      { text: 'Letsikring af indtægt ved pension', value: '/kvartalsrapport/livrente' },
      { text: 'Letsikring ved død', value: '/kvartalsrapport/doed' },
      { text: 'Letsikring ved sygdom', value: '/kvartalsrapport/sygdom' },
      { text: 'Rådgiveradfærd', value: '/kvartalsrapport/raadgiveradfaerd' },
    ];

    if (store.pengeinstitut.finansPlus) {
      sideList.push({ text: 'Finans plus', value: '/kvartalsrapport/finansplus' });
    }
    sideList.push({ text: 'Samlet indtjening', value: '/kvartalsrapport/samlet' });
    sider.value = sideList;
  }
});

function loadData() {
  store.loadData(rapportId.value, regnr.value);
}

function onChangeSide(e) {
  router.push(e.value);
}

onMounted(async () => {
  const rapporterDs = getKvartalsrapportDatasource();
  await rapporterDs.loadIfEmpty();
  const rapporter = await rapporterDs.items();
  const senesteRapport = rapporter.slice(-1)[0];
  rapportId.value = senesteRapport.id;

  regnr.value = authState.bruger.organisation.regnr;
  side.value = router.currentRoute.value.path;

  loadData();
});

function previousPage() {
  const currentPage = side.value;
  let prevPage = null;
  for (let i in sider.value) {
    const s = sider.value[i];
    if (s.value === currentPage) {
      side.value = prevPage;
      break;
    }
    prevPage = s.value;
  }
}

function nextPage() {
  const currentPage = side.value;
  let selectNext = false;
  for (let i in sider.value) {
    const s = sider.value[i];
    if (selectNext) {
      side.value = s.value;
      break;
    }
    if (s.value === currentPage) {
      selectNext = true;
    }
  }
}

function udskrivRapport() {
  window.open(
    window.location.origin +
      ROUTE_KVARTALSRAPPORT_PRINT +
      '?rapportId=' +
      rapportId.value +
      '&regnr=' +
      regnr.value,
    'kvartalsrapportprint',
  );
}
</script>

<template>
  <div>{{ authState.bruger.regnr }}</div>
  <DxToolbar class="let-toolbar" v-if="sider.length > 0">
    <DxItem location="before">
      <RegnrSelectBox v-model="regnr" :width="250" @valueChanged="loadData" />
    </DxItem>
    <DxItem location="before">
      <KvartalsrapportSelectBox v-model="rapportId" @valueChanged="loadData" />
    </DxItem>
    <DxItem>
      <DxButton icon="chevronleft" @click="previousPage" :disabled="side === sider[0].value" />
    </DxItem>
    <DxItem>
      <DxSelectBox
        :items="sider"
        v-model="side"
        @valueChanged="onChangeSide"
        placeholder="Vælg side"
        displayExpr="text"
        valueExpr="value"
        :width="300"
      />
    </DxItem>
    <DxItem>
      <DxButton
        icon="chevronright"
        @click="nextPage"
        :disabled="side === sider[sider.length - 1].value"
      />
    </DxItem>
    <DxItem location="after">
      <DxButton icon="print" text="Udskriv" @click="udskrivRapport" />
    </DxItem>
  </DxToolbar>
  <KvartalsrapportSection>
    <router-view />
  </KvartalsrapportSection>
  <div v-for="(e, index) in store.loadErrors" :key="index">{{ e }}</div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.menu {
  width: 200px;
}
</style>
