<script setup>
import { getDriftbeskedDatasource } from '@/data/driftstatus';
import { getOrganisationDatasource } from '@/data/organisation';
import { ROUTE_DRIFTBESKEDER } from '@/router/routeNames';
import dayjs from 'dayjs';
import { DxButton } from 'devextreme-vue/button';
import { DxColumn, DxDataGrid, DxLookup, DxSearchPanel, DxToolbar } from 'devextreme-vue/data-grid';
import { DxDropDownOptions } from 'devextreme-vue/lookup';
import { DxItem } from 'devextreme-vue/toolbar';
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const beskedDatasource = getDriftbeskedDatasource();
beskedDatasource.sort([
  { selector: 'udloebet', desc: false },
  { selector: 'startTidspunkt', desc: true },
]);
let orgData = ref();

watchEffect(async () => {
  await getOrganisationDatasource().loadIfEmpty();
  orgData.value = getOrganisationDatasource().items();
});
function onRowClick(e) {
  const beskedId = e.data.id;
  router.push(`${ROUTE_DRIFTBESKEDER}/${beskedId}`);
}

function opretDriftbesked() {
  router.push(`${ROUTE_DRIFTBESKEDER}/-1/edit`);
}

function formatRow(r) {
  if (r.rowType === 'data') {
    if (r.data.slutTidspunkt) {
      if (dayjs(r.data.slutTidspunkt) < dayjs(new Date())) {
        r.rowElement.style.color = '#999999';
      }
    }
  }
}
</script>

<template>
  <DxDataGrid
    :data-source="beskedDatasource"
    :row-alternation-enabled="true"
    :show-column-lines="false"
    :hover-state-enabled="true"
    :show-borders="true"
    @row-click="onRowClick"
    @row-prepared="formatRow"
  >
    <DxSearchPanel :visible="true" :highlight-search-text="false" placeholder="Søg..." />

    <DxToolbar>
      <DxItem location="before">
        <DxButton
          text="Opret driftbesked"
          icon="plus"
          @click="opretDriftbesked"
          type="default"
          styling-mode="text"
        />
      </DxItem>
      <DxItem name="searchPanel" location="after" />
    </DxToolbar>

    <DxColumn data-field="overskrift" cell-template="overskrift-cell" />
    <template #overskrift-cell="{ data }">
      <i class="dx-icon-isnotblank status-icon" :class="'color-' + data.data.status"></i>
      <span>{{ data.text }}</span>
    </template>
    <DxColumn data-field="organisationId" caption="Organisation" data-type="number" :width="150">
      <DxLookup :data-source="orgData" value-expr="id" display-expr="navn">
        <DxDropDownOptions :defer-rendering="false" />
      </DxLookup>
    </DxColumn>
    <DxColumn
      data-field="startTidspunkt"
      caption="Start"
      data-type="date"
      format="dd-MM-yyyy HH:mm"
      css-class="numbers"
      :width="150"
    />
    <DxColumn
      data-field="slutTidspunkt"
      caption="Slut"
      data-type="date"
      format="dd-MM-yyyy HH:mm"
      css-class="numbers"
      :width="150"
    />
    <DxColumn
      data-field="notifikationAfsendt"
      caption="Notifikation"
      data-type="date"
      format="dd-MM-yyyy HH:mm"
      css-class="numbers"
      :width="150"
    />
  </DxDataGrid>
</template>

<style>
.status-icon {
  margin-right: 4px;
  /*font-size: 1rem;*/
  vertical-align: baseline;
}
</style>
