<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { type Range } from '@tiptap/core';
import { useAccordionStore } from '@/tiptap/let-accordion/accordionStore';
import TiptapComponentItemWrapper from '@/tiptap/component/TiptapComponentItemWrapper.vue';
import { DxButton } from 'devextreme-vue/button';

const props = defineProps({
  ...nodeViewProps,
  childCount: {
    type: Number,
  },
});

const store = useAccordionStore();

function addItemBefore() {
  addItem(props.getPos());
}

function addItemAfter() {
  const endPos = props.getPos() + props.node.nodeSize;
  addItem(endPos);
}

function addItem(pos: number | Range) {
  props.editor
    .chain()
    .insertContentAt(pos, {
      type: 'letAccordionItem',
      attrs: {
        class: 'let-accordion-item-expanded',
      },
      content: [
        {
          type: 'letAccordionItemHeader',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: '[Overskrift]' }],
            },
          ],
        },
        {
          type: 'letAccordionItemContent',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: '[Indhold]' }],
            },
          ],
        },
      ],
    })
    .run();
}
</script>

<template>
  <node-view-wrapper>
    <tiptap-component-item-wrapper :editor="editor">
      <node-view-content class="let-accordion-item" v-bind="node.attrs" />
      <template #buttons>
        <DxButton
          icon='<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 5a1 1 0 0 0 1 1h16a1 1 0 1 0 0-2H4a1 1 0 0 0-1 1Zm9 15a1 1 0 0 0 1-1v-3h3a1 1 0 1 0 0-2h-3v-3a1 1 0 1 0-2 0v3H8a1 1 0 1 0 0 2h3v3a1 1 0 0 0 1 1Z"/></svg>'
          hint="Indsæt ny sektion før"
          @click="addItemBefore"
        />
        <DxButton
          icon='<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 0 1 0-2h3V5a1 1 0 0 1 1-1ZM3 19a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"/></svg>'
          hint="Indsæt ny sektion efter"
          @click="addItemAfter"
        />
        <DxButton
          icon="trash"
          hint="Slet denne sektion"
          @click="deleteNode"
          v-if="store.childCount > 1"
        />
      </template>
    </tiptap-component-item-wrapper>
  </node-view-wrapper>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';
</style>
