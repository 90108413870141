import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let tagStore;
export function getTagDatasource() {
  if (!tagStore) {
    tagStore = createDevextremeDatasource('/api/Tags', 'id', {});
    tagStore.sort([{ selector: 'navn', desc: false }]);
  }

  return tagStore;
}
