import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import LetSectionComponent from '@/tiptap/let-section/LetSectionComponent.vue';
import LetSectionItemComponent from '@/tiptap/let-section/LetSectionItemComponent.vue';

export const LetSection = Node.create({
  name: 'letSection',
  group: 'block',
  content: 'letSectionItem+',
  isolating: true,
  draggable: false,
  allowGapCursor: false,

  parseHTML() {
    return [
      {
        tag: 'let-section',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-section', mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      insertSection:
        () =>
        ({ commands }) => {
          commands.insertContent(
            '<let-section><let-section-item><p></p></let-section-item><let-section-item><p></p></let-section-item></let-section>',
          );
          return true;
        },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(LetSectionComponent);
  },
});

export const LetSectionItem = Node.create({
  name: 'letSectionItem',
  group: 'block',
  content: 'block+',
  isolating: true,
  allowGapCursor: true,

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('class');
        },
        renderHTML: (attributes) => {
          if (attributes.class) {
            return {
              class: attributes.class,
            };
          }
        },
      },

      size: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-size'),
        renderHTML: (attributes) => {
          return {
            'data-size': attributes.size,
          };
        },
      },

      color: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-color'),
        renderHTML: (attributes) => {
          return {
            'data-color': attributes.color,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'let-section-item',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-section-item', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LetSectionItemComponent);
  },
});
