<script setup>
import { DxForm, DxItem, DxLabel, DxSimpleItem } from 'devextreme-vue/form';
import { ref } from 'vue';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { getOpslagDatasource } from '@/data/opslag';
import { getFilDatasource } from '@/data/fil';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import LetLink from '@/components/filer/LetLink.vue';

defineProps({
  opslag: { type: Object, required: true },
  editable: { type: Boolean, default: false },
});

const emit = defineEmits(['deleteLink', 'saveLink', 'moveLink']);

const opslagDatasource = getOpslagDatasource();
const filDatasource = getFilDatasource();

const link = ref({});
const showForm = ref(false);

function deleteLink(linkToDelete) {
  emit('deleteLink', linkToDelete);
}

function addLink() {
  link.value = { linkType: '_blank' };
  showLinkForm();
}

function showLinkForm() {
  showForm.value = true;
}

function saveLink() {
  emit('saveLink', link.value);
  showForm.value = false;
}

function editLink(linkToEdit) {
  link.value = linkToEdit;
  showForm.value = true;
}

function opslagSelected(e) {
  const opslag = e.selectedItem;
  if (opslag) {
    link.value.link = '/opslag/' + (opslag.stinavn || opslag.id);
  }
}

function filSelected(e) {
  const fil = e.selectedItem;
  link.value.link = getAppConfig().apiRoot + '/api/Fil/' + (fil.stinavn || fil.id);
}

function moveUp(link) {
  emit('moveLink', link, -1);
}
function moveDown(link) {
  emit('moveLink', link, 1);
}
</script>

<template>
  <div v-for="(link, index) in opslag.links" :key="link.id" class="link-container">
    <div class="link-item" v-if="editable">
      <div class="link-link">{{ link.linkTekst }}</div>
      <div
        :style="{ visibility: index > 0 ? 'visible' : 'hidden' }"
        class="link-action"
        @click="moveUp(link)"
      >
        <i class="dx-icon-arrowup"></i>
      </div>
      <div
        :style="{ visibility: index < opslag.links.length - 1 ? 'visible' : 'hidden' }"
        class="link-action"
        @click="moveDown(link)"
      >
        <i class="dx-icon-arrowdown"></i>
      </div>
      <div class="link-action" @click="editLink(link)">
        <i class="dx-icon-edit"></i>
      </div>
      <div class="link-action" @click="deleteLink(link)">
        <i class="dx-icon-remove"></i>
      </div>
    </div>
    <div v-else>
      <LetLink :href="link.link" :target="link.linkType">{{ link.linkTekst }}</LetLink>
    </div>
  </div>
  <div class="link-add-action" v-if="editable" @click="addLink()">
    <i class="dx-icon-add"></i>
  </div>

  <DxPopup
    v-model:visible="showForm"
    title="Link"
    :width="600"
    :height="460"
    :hide-on-outside-click="true"
  >
    <DxForm :form-data="link" label-mode="outside" label-location="left">
      <DxSimpleItem data-field="linkTekst" :is-required="true">
        <DxLabel text="Tekst" />
      </DxSimpleItem>
      <DxItem data-field="link" :is-required="true">
        <DxLabel text="Indtast url" />
        <DxTextBox v-model="link.link" />
      </DxItem>
      <DxItem>eller</DxItem>
      <DxItem>
        <DxLabel text="Vælg opslag" />
        <DxSelectBox
          :data-source="opslagDatasource"
          value-expr="id"
          display-expr="overskrift"
          :search-enabled="true"
          search-expr="Overskrift"
          @selection-changed="opslagSelected"
        />
      </DxItem>
      <DxItem>eller</DxItem>
      <DxItem>
        <DxLabel text="Vælg fil" />
        <DxSelectBox
          :data-source="filDatasource"
          value-expr="id"
          display-expr="navn"
          :search-enabled="true"
          search-expr="Navn"
          @selection-changed="filSelected"
        />
      </DxItem>
      <DxItem data-field="linkType">
        <DxLabel text="Åbnes" />
        <DxSelectBox
          v-model="link.linkType"
          :items="[
            { value: '_self', text: 'I samme vindue' },
            { value: '_blank', text: 'I nyt vindue' },
            { value: 'download', text: 'Download fil' },
          ]"
          value-expr="value"
          display-expr="text"
        />
      </DxItem>
    </DxForm>
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="after"
      :options="{
        text: 'OK',
        onClick: saveLink,
      }"
    />
  </DxPopup>
</template>

<style scoped lang="scss">
.link-container {
  font-size: 1rem;
}

.link-item {
  display: flex;
}

.link-link {
  flex: 1;
}
.link-action {
  width: 20px;
  text-align: right;
  cursor: pointer;
}
.link-add-action {
  margin-top: 10px;
  cursor: pointer;
}

.link-add-button-container {
  display: flex;
  gap: 10px;
  justify-content: right;
  margin-top: 10px;
}
</style>
