import { createRouter, createWebHistory } from 'vue-router';
import defaultLayout from '@/layout/default/DefaultLayout.vue';
import simpleLayout from '@/letapps-vue/layouts/SimpleLayout.vue';
import ProfilePage from '@/pages/ProfilePage.vue';
import Error404 from '@/letapps-vue/pages/Error404Page.vue';
import ForRaadgivere from '@/pages/raadgiver/ForRaadgiverePage.vue';
import Vejledninger from '@/pages/raadgiver/VejledningerPage.vue';
import Undervisning from '@/pages/raadgiver/UndervisningPage.vue';
import Rapportering from '@/pages/rapportering/RapporteringPage.vue';
import NysalgTotaler from '@/pages/rapportering/NysalgTotalerPage.vue';
import DriftBeskederPage from '@/components/driftstatus/DriftBeskederPage.vue';
import TestPage from '@/letapps-vue/components/TestPage.vue';
import {
  ROUTE_ADMIN,
  ROUTE_DRIFTBESKEDER,
  ROUTE_FILER,
  ROUTE_HOME,
  ROUTE_KVARTALSRAPPORT,
  ROUTE_KVARTALSRAPPORT_PRINT,
  ROUTE_NYHEDER,
  ROUTE_OPSLAG,
  ROUTE_FILE_VIEWER,
  ROUTE_SEARCH,
  ROUTE_LEDERSIDEN,
} from '@/router/routeNames';
import OpslagDetail from '@/components/opslag/OpslagDetail.vue';
import DriftbeskedDetail from '@/components/driftstatus/DriftbeskedDetail.vue';
import StartPage from '@/pages/StartPage.vue';
import { type Component } from 'vue';
import { RettighedsType } from '@/types/rettighed';
import FilerPage from '@/pages/filer/FilerPage.vue';
import KvartalsrapportPage from '@/pages/kvartalsrapport/KvartalsrapportPage.vue';
import KvartalsrapportForside from '@/pages/kvartalsrapport/KvartalsrapportForside.vue';
import KvartalsrapportForord from '@/pages/kvartalsrapport/KvartalsrapportForord.vue';
import KvartalsrapportHovedtal from '@/pages/kvartalsrapport/KvartalsrapportHovedtal.vue';
import KvartalsrapportLivrente from '@/pages/kvartalsrapport/KvartalsrapportLivrente.vue';
import NyhederPage from '@/components/nyheder/NyhederPage.vue';
import SearchPage from '@/pages/SearchPage.vue';
import AdminPage from '@/pages/admin/AdminPage.vue';
import OpslagGrid from '@/components/opslag/OpslagGrid.vue';
import FilerGrid from '@/pages/filer/FilerGrid.vue';
import KvartalsrapportDoed from '@/pages/kvartalsrapport/KvartalsrapportDoed.vue';
import KvartalsrapportSygdom from '@/pages/kvartalsrapport/KvartalsrapportSygdom.vue';
import KvartalsrapportRaadgiverAdfaerd from '@/pages/kvartalsrapport/KvartalsrapportRaadgiverAdfaerd.vue';
import KvartalsrapportFinansPlus from '@/pages/kvartalsrapport/KvartalsrapportFinansPlus.vue';
import KvartalsrapportSamletIndtjening from '@/pages/kvartalsrapport/KvartalsrapportSamletIndtjening.vue';
import KvartalsrapportPrint from '@/pages/kvartalsrapport/KvartalsrapportPrint.vue';
import LetFileViewer from '@/components/filer/LetFileViewer.vue';
import LederPage from '@/pages/LederPage.vue';
import DriftBeskederGrid from '@/components/driftstatus/DriftBeskederGrid.vue';

// function loadView(view) {
//   return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
// }

declare module 'vue-router' {
  interface RouteMeta {
    rettighed?: RettighedsType;
    layout?: Component;
  }
}

const router = createRouter({
  scrollBehavior(to) {
    if (!to.hash) {
      // scroll to top if no achor is specified
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  },
  routes: [
    {
      path: ROUTE_HOME,
      meta: {
        rettighed: RettighedsType.AppLetguide,
        layout: defaultLayout,
      },
      component: StartPage,
    },
    {
      path: ROUTE_SEARCH,
      meta: {
        layout: defaultLayout,
      },
      component: SearchPage,
    },
    {
      path: ROUTE_ADMIN,
      meta: {
        rettighed: RettighedsType.LetguideOpslagAdmin,
        layout: defaultLayout,
      },
      component: AdminPage,
      redirect: '/admin/opslag',
      children: [
        {
          path: 'opslag',
          meta: {
            rettighed: RettighedsType.LetguideOpslagAdmin,
            layout: defaultLayout,
          },
          component: OpslagGrid,
        },
        {
          path: 'filer',
          meta: {
            rettighed: RettighedsType.LetguideOpslagAdmin,
            layout: defaultLayout,
          },
          component: FilerGrid,
        },
        {
          path: 'driftbeskeder',
          meta: {
            rettighed: RettighedsType.LetguideDriftbesked,
            layout: defaultLayout,
          },
          component: DriftBeskederGrid,
        },
      ],
    },
    {
      path: '/test',
      meta: {
        layout: defaultLayout,
      },
      component: TestPage,
    },
    {
      path: ROUTE_DRIFTBESKEDER,
      meta: {
        layout: defaultLayout,
      },
      component: DriftBeskederPage,
    },
    {
      path: ROUTE_DRIFTBESKEDER + '/:id/:viewMode?',
      meta: {
        layout: defaultLayout,
      },
      component: DriftbeskedDetail,
    },
    {
      path: ROUTE_OPSLAG + '/:id/:viewMode?',
      meta: {
        rettighed: RettighedsType.AllAuthenticatedUsers,
        layout: defaultLayout,
      },
      component: OpslagDetail,
    },
    {
      path: ROUTE_LEDERSIDEN,
      meta: {
        rettighed: RettighedsType.LetguideFagleder,
        layout: defaultLayout,
      },
      component: LederPage,
    },
    {
      path: ROUTE_NYHEDER,
      meta: {
        layout: defaultLayout,
      },
      component: NyhederPage,
    },
    {
      path: ROUTE_FILER + '/:id?',
      meta: {
        rettighed: RettighedsType.AllAuthenticatedUsers,
        layout: defaultLayout,
      },
      component: FilerPage,
    },
    {
      path: '/raadgiver',
      meta: { layout: defaultLayout },
      component: ForRaadgivere,
    },
    {
      path: '/raadgiver/vejledning',
      meta: { layout: defaultLayout },
      component: Vejledninger,
    },
    {
      path: '/raadgiver/undervisning',
      meta: { layout: defaultLayout },
      component: Undervisning,
    },
    {
      path: '/rapportering',
      meta: {
        rettighed: RettighedsType.LetguideRapportering,
        layout: defaultLayout,
      },
      component: Rapportering,
    },
    {
      path: '/rapportering/nysalgtotaler',
      meta: {
        rettighed: RettighedsType.LetguideRapportering,
        layout: defaultLayout,
      },
      component: NysalgTotaler,
    },
    {
      path: ROUTE_KVARTALSRAPPORT,
      meta: {
        rettighed: RettighedsType.LetguideKvartalsrapport,
        layout: defaultLayout,
      },
      component: KvartalsrapportPage,
      redirect: ROUTE_KVARTALSRAPPORT + '/forside',
      children: [
        {
          path: 'forside',
          component: KvartalsrapportForside,
        },
        {
          path: 'forord',
          component: KvartalsrapportForord,
        },
        {
          path: 'hovedtal',
          component: KvartalsrapportHovedtal,
        },
        {
          path: 'livrente',
          component: KvartalsrapportLivrente,
        },
        {
          path: 'doed',
          component: KvartalsrapportDoed,
        },
        {
          path: 'sygdom',
          component: KvartalsrapportSygdom,
        },
        {
          path: 'raadgiveradfaerd',
          component: KvartalsrapportRaadgiverAdfaerd,
        },
        {
          path: 'finansplus',
          component: KvartalsrapportFinansPlus,
        },
        {
          path: 'samlet',
          component: KvartalsrapportSamletIndtjening,
        },
      ],
    },
    {
      path: ROUTE_KVARTALSRAPPORT_PRINT,
      meta: {
        rettighed: RettighedsType.LetguideKvartalsrapport,
        layout: simpleLayout,
      },
      component: KvartalsrapportPrint,
    },
    {
      path: ROUTE_FILE_VIEWER + '/:id/:filename',
      meta: {
        layout: simpleLayout,
      },
      component: LetFileViewer,
    },
    {
      path: '/profile',
      meta: { layout: defaultLayout },
      component: ProfilePage,
    },
    {
      path: '/:catchAll(.*)*',
      component: Error404,
      meta: { layout: simpleLayout },
    },
  ],
  history: createWebHistory(),
});

export default router;
