<script setup>
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';

const props = defineProps({
  editable: { type: Boolean, default: false },
  href: { type: String, required: true },
  target: { type: String, required: false },
  cssClass: { type: String, required: false },
});

function onClick(e) {
  e.stopPropagation();
  if (props.editable) return;

  axios
    .get(props.href, {
      responseType: 'blob',
    })
    .then((response) => {
      let downloadFilename = 'download.fil';
      //Læs filnavn fra response header
      const match = response.headers['content-disposition'].match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
      );
      if (match && match[1]) {
        downloadFilename = match[1];
      }

      if (props.target === 'download') {
        const link = document.createElement('a');
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', downloadFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        downloadFilename = downloadFilename.replaceAll('.', '_');
        let fileViewUrl = props.href.replaceAll('/Fil/', '/fileview/') + '/' + downloadFilename;
        fileViewUrl = fileViewUrl.substring(fileViewUrl.indexOf('/api/') + 4);
        window.open(fileViewUrl, '_blank');
      }
    });
}

function needsAuth() {
  return props.href?.startsWith(getAppConfig().apiRoot);
}
</script>

<template>
  <span v-if="needsAuth()" @click="onClick" class="let-link" :class="cssClass">
    <slot />
    <i v-if="target === 'download'" class="let-link-icon dx-icon-download" />
    <i v-else class="let-link-icon dx-icon-textdocument" />
  </span>
  <a v-else :href="props.href" :target="props.target" class="let-link" :class="cssClass">
    <slot />
    <i v-if="!needsAuth() && target === '_blank'" class="let-link-icon dx-icon-export" />
  </a>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.tiptap[contenteditable='true'] {
  .let-link {
    cursor: auto;
  }
}

.let-link {
  color: $letpension-orange;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.let-link-icon {
  padding-left: 3px;
  color: $letpension-orange;
}
</style>
