<script setup>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { accesibleMenuItems } from '@/app-navigation';
import { RettighedsType } from '@/types/rettighed';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import HeaderMenuItem from '@/layout/default/HeaderMenuItem.vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

defineProps({ showMenuFunc: Function });

const route = useRoute();

const appName = getAppConfig().appName;

const scrolled = ref(false);
const responsiveMenu = ref();

function clickMenuItem(e) {
  console.log('click');

  responsiveMenu.value.classList.toggle('submenu-visible');
}

function getActiveClass(path) {
  return route.path === path ? 'router-link-active' : null;
}

onMounted(() => {
  window.addEventListener('scroll', onScroll, true);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll, true);
});
let scrollTimer = null;
function onScroll() {
  if (scrollTimer) {
    clearTimeout(scrollTimer);
  }
  let limit = 50;
  if (scrolled.value) {
    limit = 1;
  }
  scrollTimer = setTimeout(() => {
    scrolled.value = window.scrollY >= limit;
  }, 10);
}
</script>

<template>
  <header>
    <DxToolbar class="header-toolbar" :class="scrolled ? 'header-scrolled' : ''">
      <DxItem location="before">
        <RouterLink to="/">
          <img
            src="../../letapps-vue/assets/letpension-logo.svg"
            alt="Letpension logo"
            class="logo"
          />
          <img
            src="../../letapps-vue/assets/letpension-logo-symbol.svg"
            alt="Letpension logo"
            class="logo-small"
          />
          <div v-if="appName" class="app-name-container">
            <div class="app-name">{{ appName }}</div>
          </div>
        </RouterLink>
      </DxItem>

      <DxItem
        v-for="(n, index) in accesibleMenuItems"
        location="after"
        :key="index"
        cssClass="normal-menu"
      >
        <HeaderMenuItem :item="n" :show-menu-func="showMenuFunc" />
      </DxItem>

      <DxItem location="after" cssClass="profile-item">
        <i class="dx-icon-menu profile-icon" @click="(e) => clickMenuItem(e)"></i>
      </DxItem>

      <DxItem location="after">
        <div class="menu-item-spacer" />
      </DxItem>
      <DxItem
        v-if="hasRettighed(RettighedsType.LetguideRaadgiver)"
        location="after"
        cssClass="profile-item"
      >
        <RouterLink to="/search"> <i class="dx-icon-search profile-icon"></i>&nbsp; </RouterLink>
      </DxItem>
      <DxItem
        v-if="hasRettighed(RettighedsType.LetguideOpslagAdmin)"
        location="after"
        cssClass="profile-item"
      >
        <RouterLink to="/admin">
          <i class="dx-icon-preferences profile-icon"></i>&nbsp;
        </RouterLink>
      </DxItem>
      <DxItem location="after" cssClass="profile-item">
        <RouterLink to="/profile"> <i class="dx-icon-user profile-icon"></i>&nbsp; </RouterLink>
      </DxItem>
    </DxToolbar>
    <div ref="responsiveMenu" class="submenu" @click="clickMenuItem">
      <div v-for="(item, index) in accesibleMenuItems" :key="index">
        <RouterLink v-if="item.path" :to="item.path" :class="getActiveClass(item.path)">
          <div class="top-menu-item">
            {{ item.text }}
          </div>
        </RouterLink>
      </div>
    </div>
  </header>
  <div class="space-below-header" :class="scrolled ? 'header-scrolled' : ''" />
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

@media print {
  header {
    display: none;
  }
}

.space-below-header {
  height: 100px;
  transition: height 300ms ease;
  // margin-bottom: 20px;

  &.header-scrolled {
    height: 50px !important;
  }
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1501;
  overflow: visible;
}

.app-name-container {
  position: relative;

  .app-name {
    text-transform: uppercase;
    position: absolute;
    left: 63px;
    top: 4px;
    color: $letpension-grey-text;
    letter-spacing: 3px;
  }
}

.menu-item-spacer {
  width: 30px;
}

.header-toolbar {
  padding: 0 68px;
  background-color: $letpension-sand;

  :deep(.dx-toolbar-items-container) {
    height: 100px !important;
    transition: height 300ms ease;
  }

  :deep(.dx-toolbar-item) {
    padding: 0;
  }

  &.header-scrolled {
    :deep(.dx-toolbar-items-container) {
      height: 50px !important;
    }
  }
}

.logo-container {
  display: flex;
  width: 250px;
  margin-right: 23px;
}

.logo-image {
  text-align: left;
}

.logo-tekst {
  flex: 1;
  font-family: Marcia;
  font-size: 36px;
  color: black;
  //font-weight: 600;
  margin-left: 10px;
  position: relative;
  top: 6px;
}

a:has(.logo-tekst) {
  text-decoration: none;
}

.profile-item {
  a {
    padding-left: 10px;
    color: black;
    text-decoration: none;
  }

  a:hover {
    opacity: 0.7;
  }
}

.profile-email {
  font-size: 1rem;
}

.profile-icon {
  font-size: 1.375rem;
  position: relative;
  top: 2px;

  &:hover {
    color: $letpension-orange;
  }
}

.router-link-active {
  .profile-name {
    color: $letpension-orange;
  }

  .profile-icon {
    color: $letpension-orange;
  }
}

.submenu {
  display: none;
  position: absolute;
  right: 0;
  min-width: 245px;
  min-height: 100vh;
  background: $letpension-sand;
  z-index: 99999;

  a {
    text-decoration: none;
  }

  a:hover {
    .top-menu-item {
      color: $letpension-orange;
      text-decoration: none;
    }
  }
}

.submenu-visible {
  display: block;
}

.dx-icon-menu {
  cursor: pointer;
}

.top-menu-item {
  font-size: 0.9375rem;
  font-weight: 600;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 20px;
  color: black;
}

.router-link-active {
  .top-menu-item {
    color: $letpension-orange;
  }
}

:deep(.normal-menu) {
  display: none;
}

.app-name-container {
  display: none;
}

.logo {
  height: 42px;
  display: none;
}

.logo-small {
  height: 35px;
  display: block;
  position: relative;
  top: -5px;
}

@media (min-width: 1300px) {
  :deep(.normal-menu) {
    display: table-cell;
  }
  :deep(.dx-icon-menu) {
    display: none;
  }

  :deep(.submenu) {
    display: none;
  }
}

@media (min-width: 550px) {
  .app-name-container {
    display: block;
  }

  .logo {
    display: block;
  }

  .logo-small {
    display: none;
  }
}
</style>
