import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let store;
export function getBrugerNotifikationDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/BrugerNotifikationer', 'id');
  }

  return store;
}
