import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let store;
export function getNotifikationTypeDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/NotifikationTyper', 'id');
  }

  return store;
}
