import { createDevextremeDatasource } from '@/letapps-vue/utils/useRestApi';

let driftbeskedStore;
export function getDriftbeskedDatasource() {
  if (!driftbeskedStore) {
    driftbeskedStore = createDevextremeDatasource('/api/Driftbeskeder', 'id');
  }

  return driftbeskedStore;
}

let aktiveDriftbeskederStore;
export function getAktiveDriftbeskederDatasource() {
  if (!aktiveDriftbeskederStore) {
    aktiveDriftbeskederStore = createDevextremeDatasource('/api/Driftbeskeder/aktive', 'id');
  }

  return aktiveDriftbeskederStore;
}

let driftOrgStore;
export function getDriftOrgDatasource() {
  if (!driftOrgStore) {
    driftOrgStore = createDevextremeDatasource('/api/Organisationer', 'id', {
      filter: ['driftstatus', '=', 1],
    });
  }

  return driftOrgStore;
}

export const driftstatusValues = [
  {
    status: 'ok',
    text: 'Ok',
    impact: 0,
  },
  // {
  //   status: 'info',
  //   text: 'Info',
  //   impact: 10,
  // },
  // {
  //   status: 'warning',
  //   text: 'Advarsel',
  //   impact: 20,
  // },
  {
    status: 'error',
    text: 'Fejl',
    impact: 30,
  },
];
