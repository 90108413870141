<script setup>
import { useAccordionStore } from '@/tiptap/let-accordion/accordionStore';
import { getPositionOfNode } from '@/tiptap/tiptapUtils';
import { getNodeAtPosition, NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { DxButton } from 'devextreme-vue/button';
import { nanoid } from 'nanoid';

const props = defineProps(nodeViewProps);

const accordionStore = useAccordionStore();

function clickTab(e) {
  const button = e.target;
  const tabsContainer = button.closest('.let-tabs');

  const buttons = tabsContainer.querySelectorAll('.let-tab-button');
  buttons.forEach((tab) => {
    if (tab.getAttribute('tabid') === props.node.attrs.tabId) {
      tab.classList.add('active');
      const cleanText = tab.innerText.toLowerCase().replaceAll(' ', '');
      history.replaceState('', '', '#' + cleanText);
    } else {
      tab.classList.remove('active');
    }
  });

  let tabs = tabsContainer.querySelectorAll('.let-tab');
  let tabFound = false;
  tabs.forEach((tab) => {
    if (tab.getAttribute('tabid') === props.node.attrs.tabId) {
      tab.classList.add('active');
      tabFound = true;
    } else {
      tab.classList.remove('active');
    }
  });

  //Tilføj tab, hvis den mangler
  //Hvis problemet med at tabs "forsvinder" bliver løst, kan denne blok fjernes.
  if (!tabFound && props.editor.view.editable) {
    const tabsNode = getNodeAtPosition(props.editor.state, 'letTabs', props.getPos())[0];
    const positionOfTabsNode = getPositionOfNode(props.editor, tabsNode);
    let insertTabAtPos = null;
    tabsNode.descendants((node, pos) => {
      if (node.type.name === 'letTab') {
        if (!insertTabAtPos) {
          //pos is relative to the tabsNode. Therefore, we need to add the pos of the tabsNode itself, to get the absolute pos
          insertTabAtPos = positionOfTabsNode + pos;
        }
      }
    });

    props.editor
      .chain()
      .insertContentAt(
        insertTabAtPos,
        '<let-tab data-tab-id="' + props.node.attrs.tabId + '"><p>Nyt indhold</p></let-tab>',
      )
      .run();

    tabs = tabsContainer.querySelectorAll('.let-tab');
    tabs.forEach((tab) => {
      if (tab.getAttribute('tabid') === props.node.attrs.tabId) {
        tab.classList.add('active');
      }
    });
  }

  accordionStore.closeAll();
}

function moveLeft(o) {
  o.event.stopPropagation();
  const prevButton = getNodeAtPosition(props.editor.state, 'letTabButton', props.getPos() - 1)[0];
  if (prevButton) {
    cloneNodeTo(props.getPos() - prevButton.nodeSize);
    props.deleteNode();
  }
}

function moveRight(o) {
  o.event.stopPropagation();
  const nextButton = getNodeAtPosition(
    props.editor.state,
    'letTabButton',
    props.getPos() + props.node.nodeSize + 1,
  )[0];
  if (nextButton) {
    cloneNodeTo(props.getPos() + props.node.nodeSize + nextButton.nodeSize);
    props.deleteNode();
  }
}

function cloneNodeTo(pos) {
  props.editor.commands.insertContentAt(pos, props.node.toJSON());
}

function deleteTab(o) {
  o.event.stopPropagation();
  const tabsNode = getNodeAtPosition(props.editor.state, 'letTabs', props.getPos())[0];
  const positionOfTabsNode = getPositionOfNode(props.editor, tabsNode);
  //Find the corresponding tab, and delete it
  tabsNode.descendants((node, pos) => {
    if (node.type.name === 'letTab') {
      if (node.attrs.tabId === props.node.attrs.tabId) {
        //pos is relative to the tabsNode. Therefore, we need to add the pos of the tabsNode itself, to get the absolute pos
        props.editor.commands.deleteRange({
          from: positionOfTabsNode + pos,
          to: positionOfTabsNode + pos + node.nodeSize,
        });
      }
    }
  });
  //Also delete the tab button
  props.deleteNode();
}

function addTab(o) {
  o.event.stopPropagation();

  const tabId = nanoid(10);
  const buttonHtml = '<let-tab-button data-tab-id="' + tabId + '">Ny fane</let-tab-button>';
  const tabHtml = '<let-tab data-tab-id="' + tabId + '"><p>Nyt indhold</p></let-tab>';

  const tabsNode = getNodeAtPosition(props.editor.state, 'letTabs', props.getPos())[0];
  const positionOfTabsNode = getPositionOfNode(props.editor, tabsNode);
  let insertTabAtPos = null;
  tabsNode.descendants((node, pos) => {
    if (node.type.name === 'letTab') {
      if (!insertTabAtPos) {
        //pos is relative to the tabsNode. Therefore, we need to add the pos of the tabsNode itself, to get the absolute pos
        insertTabAtPos = positionOfTabsNode + pos;
      }
    }
  });

  const insertButtonAtPos = props.getPos() + props.node.nodeSize;

  props.editor
    .chain()
    .insertContentAt(insertTabAtPos, tabHtml)
    .insertContentAt(insertButtonAtPos, buttonHtml)
    .run();
}
</script>

<template>
  <node-view-wrapper>
    <div class="let-tab-button-container" contenteditable="false" @click="(e) => clickTab(e)">
      <DxButton
        v-if="editor.isEditable"
        class="let-tab-action-button move-left"
        icon="arrowleft"
        hint="Flyt til venstre"
        @click="moveLeft"
      />
      <node-view-content
        class="let-tab-button"
        :contenteditable="editor.isEditable"
        v-bind="node.attrs"
      />
      <DxButton
        v-if="editor.isEditable"
        class="let-tab-action-button move-right"
        icon="arrowright"
        hint="Flyt til højre"
        @click="moveRight"
      />
      <DxButton
        v-if="editor.isEditable"
        class="let-tab-action-button delete-tab"
        icon="trash"
        hint="Slet fane"
        @click="deleteTab"
      />
      <DxButton
        v-if="editor.isEditable"
        class="let-tab-action-button add-tab"
        icon="plus"
        hint="Tilføj fane"
        @click="addTab"
      />
    </div>
  </node-view-wrapper>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

[data-node-view-wrapper] {
  display: flex;
}

.let-tab-button-container {
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  display: flex;
  gap: 3px;
  position: relative;
  min-width: 80px;
  font-weight: 500;

  &:hover {
    .let-tab-action-button {
      visibility: visible;
      opacity: 1;
    }
  }
}

.let-tab-action-button {
  width: 22px;
  height: 22px;
  background-color: $letpension-nougat;
  cursor: pointer;
  border-radius: 50%;
  margin-top: 13px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease;
  position: absolute;
  top: -16px;
  z-index: 10000;

  :deep(.dx-button-content) {
    padding: 0;

    .dx-icon {
      color: white;
      font-size: 0.9rem;
    }
  }

  &.move-left {
    left: -3px;
  }

  &.add-tab {
    right: -3px;
  }

  &.delete-tab {
    right: 19px;
  }

  &.move-right {
    right: 41px;
  }
}

.tiptap[contenteditable='false'] {
  .let-tab-button-container {
    cursor: pointer;
  }
}

.let-tab-button {
  padding: 12px 0 8px 0;
  border-bottom: 4px solid transparent;

  &.active {
    border-bottom: 4px solid $letpension-blue;

    &:hover {
      border-bottom: 4px solid $letpension-blue;
    }
  }

  &:hover {
    border-bottom: 4px solid lighten($letpension-blue, 20%);
  }
}
</style>
