import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAccordionStore = defineStore('accordion', () => {
  const childCount = ref(0);
  const latestCloseAllRequest = ref(new Date());

  function setChildCount(newValue: number) {
    childCount.value = newValue;
  }

  function closeAll() {
    latestCloseAllRequest.value = new Date();
  }

  return { childCount, latestCloseAllRequest, setChildCount, closeAll };
});
