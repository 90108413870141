import Image from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import LetImageComponent from './LetImageComponent.vue';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { getDataUrlFromImageFile } from '@/letapps-vue/utils/imageUtils';
import { mergeAttributes } from '@tiptap/core';

export default Image.extend({
  name: 'LetImage',

  addOptions() {
    return {
      uploader: null,
    };
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64 ? 'img[src]' : 'img[src]:not([src^="data:"])',
      },
      {
        tag: 'let-img',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addAttributes() {
    return {
      ...this.parent?.(),

      caption: {
        parseHTML: (element) => element.getAttribute('data-caption'),
        renderHTML: (attributes) => {
          return {
            'data-caption': attributes.caption,
          };
        },
      },

      width: {
        default: '100%',
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },

      height: {
        default: 'auto',
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
      },

      isDraggable: {
        default: true,
        renderHTML: () => {
          return {};
        },
      },
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),

      toggleResizable:
        () =>
        ({ tr }) => {
          if (tr) {
            const { node } = tr.selection;

            if (node?.type?.name === 'ResizableImage') {
              node.attrs.isDraggable = !node.attrs.isDraggable;
            }
          }
        },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(LetImageComponent);
  },

  addProseMirrorPlugins() {
    const uploader = this.options.uploader;
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handlePaste(view, event) {
            if (event.clipboardData && event.clipboardData.files && event.clipboardData.files[0]) {
              const file = event.clipboardData.files[0];
              if (validateImageFile(file)) {
                getDataUrlFromImageFile(file, 1024, 500).then(async (dataUrl) => {
                  const imageSrc = await uploader(dataUrl);
                  const { schema } = view.state;
                  const node = schema.nodes.LetImage.create({
                    src: imageSrc,
                  });
                  const transaction = view.state.tr.replaceSelectionWith(node);
                  view.dispatch(transaction);
                });
              }

              return true; // handled
            }
            return false; // not handled use default behaviour
          },
          handleDrop: function (view, event, slice, moved) {
            if (
              !moved &&
              event.dataTransfer &&
              event.dataTransfer.files &&
              event.dataTransfer.files[0]
            ) {
              const file = event.dataTransfer.files[0];
              if (validateImageFile(file)) {
                getDataUrlFromImageFile(file, 1024, 500).then(async (dataUrl) => {
                  const imageSrc = await uploader(dataUrl);
                  const { schema } = view.state;
                  const coordinates = view.posAtCoords({
                    left: event.clientX,
                    top: event.clientY,
                  });
                  const node = schema.nodes.LetImage.create({
                    src: imageSrc,
                  });
                  const transaction = view.state.tr.insert(coordinates.pos, node);
                  view.dispatch(transaction);
                });
              }

              return true; // handled
            }
            return false; // not handled use default behaviour
          },
        },
      }),
    ];
  },
});

function validateImageFile(file) {
  if (file.size / 1024 / 1024 > 5) {
    // handle error
    console.error('Fil for stor');
    return false;
  }

  // Check the allowed content type
  if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
    // handle error
    console.error('Ukendt filtype');
    return false;
  }

  return true;
}
