<script setup>
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import LetLoadIndicator from '@/letapps-vue/components/LetLoadIndicator.vue';
import RaadgiverSalgChart from '@/pages/kvartalsrapport/charts/RaadgiverSalgChart.vue';
import RaadgiverProdukterChart from '@/pages/kvartalsrapport/charts/RaadgiverProdukterChart.vue';
import RaadgiverFordelingTypeChart from '@/pages/kvartalsrapport/charts/RaadgiverFordelingTypeChart.vue';

defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();
</script>

<template>
  <div class="titel">Rådgiveradfærd</div>
  <div class="flex-container container" v-if="store.aktivitet">
    <div class="flex-2">
      <div class="chart-titel">Hvor mange rådgivere sælger Letsikringer på månedsbasis?</div>
      <div class="flex-container">
        <div class="flex-2">
          <RaadgiverSalgChart :print="print" />
        </div>
        <div class="flex-1 explain left">
          Grafikken viser fordelingen af rådgivere, der har udarbejdet et eller flere tilbud, som
          efterfølgende er blevet til en aftale med salg af Letsikringer. Letsikring af lån og
          kredit er ikke inkluderet.
        </div>
      </div>
      <div class="chart-titel">Fordeling på antal produkter - år til dato</div>
      <div class="flex-container">
        <div class="flex-2">
          <RaadgiverProdukterChart :print="print" />
        </div>
        <div class="flex-1 explain left">
          Fordelingen på antal produkter viser antallet af produkter i det samlede antal forslag.
          Der er ikke taget hensyn til, om forslaget er blevet til et tilbud eller en aftale.
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="chart-titel">Fordeling på type af forslag - år til dato</div>
      <RaadgiverFordelingTypeChart :print="print" />
      <div class="explain top">
        Fordelingen på antal forslag viser alle forslag, der er lavet i Rådgiverportalen. Der er
        ikke taget hensyn til, om forslaget har udmøntet sig i et tilbud eller en aftale, ej heller
        om de er blevet oprettet ved en fejl eller som kopi af et andet forslag.
      </div>
    </div>
  </div>
  <div v-else>
    <LetLoadIndicator :visible="true" />
    <div>Henter aktivitet...</div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.explain {
  position: relative;
  padding: 20px;
  margin: 1em 0 3em;
  background: $letpension-sand;

  &.top {
    &:after {
      top: -15px;
      /* value = - border-top-width - border-bottom-width */
      left: 100px;
      /* controls horizontal position */
      bottom: auto;
      border-width: 0 15px 15px;
      /* vary these values to change the angle of the vertex */
      border-color: $letpension-sand transparent;
    }
  }

  &.left {
    margin-left: 50px;

    &:after {
      top: 75px;
      /* controls vertical position */
      left: -20px;
      /* value = - border-left-width - border-right-width */
      bottom: auto;
      border-width: 10px 20px 10px 0;
      border-color: transparent $letpension-sand;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    /* value = - border-top-width - border-bottom-width */
    left: 50px;
    /* controls horizontal position */
    border-width: 15px 15px 0;
    /* vary these values to change the angle of the vertex */
    border-style: solid;
    border-color: $letpension-sand transparent;
    /* reduce the damage in FF3.0 */
    display: block;
    width: 0;
  }
}
</style>
