<script setup>
import { NodeViewContent, NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { useAccordionStore } from './accordionStore';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

const accordionStore = useAccordionStore();
const { latestCloseAllRequest } = storeToRefs(accordionStore);
const props = defineProps(nodeViewProps);

const me = ref();

watch(latestCloseAllRequest, closeAll);

function onToggle(e) {
  const targetItem = e.target.closest('.let-accordion-item');
  const container = e.target.closest('.let-accordion-container');
  const allItems = container.querySelectorAll('.let-accordion-item');

  //Close other items
  allItems.forEach((item) => {
    if (item !== targetItem) {
      item.classList.remove('let-accordion-item-expanded');
    }
  });

  //Toggle current item
  targetItem.classList.toggle('let-accordion-item-expanded');
}

function closeAll() {
  if (props.editor.view.editable) return;

  const container = me.value?.$el.closest('.let-accordion-container');
  const allItems = container.querySelectorAll('.let-accordion-item');

  allItems.forEach((item) => {
    item.classList.remove('let-accordion-item-expanded');
  });
}
</script>

<template>
  <node-view-wrapper class="let-accordion-item-header" @click="onToggle" ref="me">
    <node-view-content class="let-accordion-item-header-content" />
    <div class="let-accordion-item-header-open-icon">
      <i class="dx-icon-add"></i>
    </div>
  </node-view-wrapper>
</template>

<style lang="scss">
@import '@/css//variables.letguide.scss';

.let-accordion-item-header {
  font-weight: 600;
  border-top: 1px solid $letpension-grey;
  padding: 15px 5px;
  display: flex;
}

.tiptap[contenteditable='false'] {
  .let-accordion-item-header {
    cursor: pointer;
  }
}

.let-accordion-item-header-content {
  flex: 1;
}

.let-accordion-item-header-open-icon {
  width: 20px;
  transform: translateY(10%) translateX(0%) rotate(0deg);
  transition: transform 200ms ease-out;

  i {
    font-size: 1.25rem;
  }
}

.let-accordion-item-expanded {
  .let-accordion-item-header-open-icon {
    transform: translateY(15%) translateX(-15%) rotate(45deg);
  }

  .let-accordion-item-header-close-icon {
    display: block;
  }
}
</style>
