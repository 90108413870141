<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

function getData() {
  const salgProduktGrupper = store.udvikling.salgProduktGrupper;
  const salg = salgProduktGrupper.filter((gruppe) => gruppe.salgProduktGruppeNavn === 'Pension')[0];
  return salg.kvartalSalg.slice(-1)[0];
}

function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    chart: {
      ...getDefaultOptions(props.print).chart,
      dropShadow: {
        enabled: false,
      },
    },
    dataLabels: {
      formatter: function (val, opts) {
        return formatNumber(opts.w.config.series[opts.seriesIndex]);
      },
      dropShadow: false,
      style: {
        colors: ['#000'],
      },
      background: {
        enabled: true,
        foreColor: '#fff',
      },
    },
    labels: ['Indskud', 'Løbende'],
  });
}

function getSeries() {
  const data = getData();
  return [data.antalIndskud, data.antalLoebendeIndbetaling];
}
</script>

<template>
  <div class="chart" v-if="store.udvikling.salgProduktGrupper">
    <apexchart height="100%" type="pie" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
