<script setup>
import { computed } from 'vue';
import LetPage from '@/letapps-vue/components/LetPage';
import { useRoute } from 'vue-router';
import DriftBeskederGrid from '@/components/driftstatus/DriftBeskederGrid.vue';
import DriftbeskedDetail from '@/components/driftstatus/DriftbeskedDetail.vue';

const route = useRoute();
const beskedId = computed(() => Number(route.params.id));
const showDetails = computed(() => !!route.params.id);
</script>

<template>
  <LetPage fit title="Driftbeskeder">
    <div v-show="!showDetails">
      <Suspense>
        <DriftBeskederGrid />
      </Suspense>
    </div>
    <DriftbeskedDetail v-if="showDetails" :beskedId="beskedId" />
  </LetPage>
</template>

<style scoped lang="scss"></style>
