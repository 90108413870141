<script setup lang="ts">
import { periodeTypeOptions } from '@/utils/rapporteringUtils';
import { DxSelectBox } from 'devextreme-vue/select-box';

const props = defineProps({ rapport: { type: String, required: true } });

interface PeriodTypeOption {
  value: string;
  text: string;
}

function getPeriodeTypeOptions() {
  const list: PeriodTypeOption[] = [];
  periodeTypeOptions.forEach((periodeType) => {
    if (['ydelsesaendringer-pi', 'skiftafpengeinstitut-pi'].includes(props.rapport)) {
      if (periodeType.value === 'ÅTD') {
        list.push(periodeType);
      }
    } else {
      list.push(periodeType);
    }
  });
  return list;
}
</script>

<template>
  <DxSelectBox
    :items="getPeriodeTypeOptions()"
    placeholder="Periode"
    displayExpr="text"
    valueExpr="value"
    style="margin-right: 12px"
    :width="100"
  />
</template>

<style scoped></style>
