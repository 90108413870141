import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useOpslagStore = defineStore('opslag', () => {
  const gridState = ref({});
  const includeDeletedRecords = ref(false);

  function setGridState(value) {
    gridState.value = value;
  }

  function setIncludeDeletedRecords(value) {
    includeDeletedRecords.value = value;
  }

  return { gridState, includeDeletedRecords, setGridState, setIncludeDeletedRecords };
});
