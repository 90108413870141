<script setup>
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { hasRettighed } from '@/letapps-vue/utils/auth';

const props = defineProps({
  showMenuFunc: Function,
  item: Object,
});

const submenu = ref();

const accesibleSubMenuItems = computed(() =>
  props.item.items?.filter((item) => !item.rettighed || hasRettighed(item.rettighed)),
);

function mouseOverMenuItem(e, isOver) {
  return;

  // If no child menu, do nothing
  if (!accesibleSubMenuItems.value?.length > 0) {
    return;
  }

  props.showMenuFunc(isOver, {
    text: props.item.text,
    left: e.target.getBoundingClientRect().left,
  });

  if (isOver) {
    submenu.value.classList.add('submenu-visible');
  } else {
    submenu.value.classList.remove('submenu-visible');
  }
}

const route = useRoute();

function getActiveClass(path) {
  let cssClass = route.path === path ? 'router-link-active' : '';
  if (path === '/opslag/tilraadgivere') {
    cssClass = cssClass + ' router-link-raadgivere';
  }
  return cssClass;
}
</script>

<template>
  <RouterLink v-if="item.path" :to="item.path" :class="getActiveClass(item.path)">
    <div
      class="top-menu-item"
      @mouseover="(e) => mouseOverMenuItem(e, true)"
      @mouseout="(e) => mouseOverMenuItem(e, false)"
    >
      {{ item.text }}
    </div>
  </RouterLink>
  <div
    v-else
    class="top-menu-item"
    @mouseover="(e) => mouseOverMenuItem(e, true)"
    @mouseout="(e) => mouseOverMenuItem(e, false)"
  >
    {{ item.text }}
  </div>
  <div ref="submenu" class="submenu" v-if="accesibleSubMenuItems">
    <div v-for="(item, index) in accesibleSubMenuItems" :key="index">{{ item.text }}</div>
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension';

a {
  color: black;
  //opacity: 0.5;
  text-decoration: none;
}

.dx-theme-generic-typography a {
  color: black;
  //opacity: 0.5;
  text-decoration: none;
}

a:hover {
  .top-menu-item {
    color: $letpension-orange;
  }
}

.router-link-active {
  .top-menu-item {
    color: $letpension-orange;
  }
}

.top-menu-item {
  font-size: 0.9375rem;
  font-weight: 600;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px 0 20px;
}

.submenu {
  display: none;
  position: absolute;
  background: yellow;
  padding: 20px;
  z-index: 999999;
}

.submenu-visible {
  display: block;
}

.app:has(.opslag-type-Q_A, .opslag-type-Vejledning, .opslag-type-Produktside) {
  .router-link-raadgivere {
    color: $letpension-orange;
  }
}
</style>
