<script setup>
import { ref, watchEffect } from 'vue';
import { confirm } from 'devextreme/ui/dialog';
import {
  DxColCountByScreen,
  DxForm,
  DxGroupItem,
  DxLabel,
  DxSimpleItem,
} from 'devextreme-vue/form';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import { getDriftOrgDropdownOptions } from '@/utils/formHelper';
import LetBackButton from '@/letapps-vue/components/LetBackButton.vue';
import { ROUTE_DRIFTBESKEDER } from '@/router/routeNames';
import { driftstatusValues, getDriftbeskedDatasource } from '@/data/driftstatus';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import {
  DxHtmlEditor,
  DxToolbar as DxToolbarHtml,
  DxItem as DxItemHtml,
} from 'devextreme-vue/html-editor';

const router = useRouter();

const props = defineProps({
  id: {
    type: Number,
    required: false,
  },
});

const beskedDatasource = getDriftbeskedDatasource();

const beskedForm = ref();
const besked = ref();
const loading = ref(false);

async function gemBesked() {
  const validation = beskedForm.value.instance.validate();
  if (validation.isValid === false) return;

  loading.value = true;
  try {
    await beskedDatasource.save(besked.value);
  } catch (e) {
    console.error(e.message);
    console.error(e.response.data.Message);
  }
  loading.value = false;

  router.back();
}

async function sletBesked() {
  let result = confirm(
    'Er du sikker at du vil slette <b>' + besked.value.overskrift + '</b>?',
    'Slet besked',
  );
  result.then(async (answer) => {
    if (answer) {
      await beskedDatasource.store().remove(besked.value.id);
      await router.push(ROUTE_DRIFTBESKEDER);
    }
  });
}

async function lukBesked() {
  besked.value.slutTidspunkt = new Date();

  try {
    await beskedDatasource.save(besked.value);
  } catch (e) {
    console.error(e.message);
    console.error(e.response.data.Message);
  }
}

watchEffect(async () => {
  if (props.id > 0) {
    besked.value = await beskedDatasource.store().byKey(props.id);
  } else {
    besked.value = {
      startTidspunkt: new Date(),
      status: 'error',
    };
  }
});

setTimeout(() => {
  if (props.id === -1) beskedForm.value.instance.getEditor('overskrift').focus();
}, 200);
</script>

<template>
  <LetPage :fit="true">
    <div v-if="besked">
      <DxToolbar class="let-toolbar">
        <DxItem location="before">
          <LetBackButton />
        </DxItem>
        <DxItem location="after">
          <LetButton
            text="Marker som løst"
            v-if="id > 0"
            @click="lukBesked"
            styling-mode="outlined"
            type="default"
          />
        </DxItem>
        <DxItem location="after">
          <LetButton
            :loading="loading"
            text="Gem besked"
            :use-submit-behavior="false"
            @click="gemBesked"
            type="default"
          />
        </DxItem>
        <DxItem location="after" locateInMenu="always">
          <LetButton
            text="Slet besked"
            v-if="id > 0"
            @click="sletBesked"
            type="danger"
            styling-mode="text"
          />
        </DxItem>
      </DxToolbar>
      <form>
        <DxForm
          :form-data="besked"
          ref="beskedForm"
          :showValidationSummary="true"
          style="max-width: 900px"
        >
          <DxGroupItem>
            <DxSimpleItem data-field="overskrift" :is-required="true" />
            <DxSimpleItem data-field="tekst" :is-required="true">
              <DxHtmlEditor
                v-model="besked.tekst"
                :autoResizeEnabled="true"
                :minHeight="180"
                :maxHeight="320"
              >
                <DxToolbarHtml>
                  <DxItemHtml name="bold"></DxItemHtml>
                  <DxItemHtml name="underline"></DxItemHtml>
                  <DxItemHtml name="italic"></DxItemHtml>
                  <DxItemHtml name="bulletList"></DxItemHtml>
                  <DxItemHtml name="link"></DxItemHtml>
                  <DxItemHtml name="separator"></DxItemHtml>
                </DxToolbarHtml>
              </DxHtmlEditor>
            </DxSimpleItem>
            <DxGroupItem>
              <DxColCountByScreen :md="2" :lg="2" />
              <DxSimpleItem data-field="status" :is-required="true">
                <DxSelectBox
                  :data-source="driftstatusValues"
                  v-model="besked.status"
                  display-expr="text"
                  value-expr="status"
                  field-template="field"
                  item-template="item"
                >
                  <template #field="{ data }">
                    <div class="custom-item">
                      <i
                        v-if="data"
                        class="dx-icon-isnotblank status-icon"
                        :class="'color-' + data.status"
                      ></i>
                      <DxTextBox :value="data && data.text" :read-only="true" class="status-name" />
                    </div>
                  </template>

                  <template #item="{ data }">
                    <div>
                      <i class="dx-icon-isnotblank status-icon" :class="'color-' + data.status"></i>
                      <span>{{ data.text }}</span>
                    </div>
                  </template>
                </DxSelectBox>
              </DxSimpleItem>
              <DxSimpleItem
                data-field="organisationId"
                :is-required="true"
                :editor-options="getDriftOrgDropdownOptions()"
                editorType="dxSelectBox"
              >
                <DxLabel text="ITC" />
              </DxSimpleItem>
              <DxSimpleItem
                data-field="startTidspunkt"
                :is-required="true"
                css-class="input-numbers"
              >
                <DxLabel text="Start" />
                <DxDateBox
                  displayFormat="dd-MM-yyyy HH:mm"
                  v-model="besked.startTidspunkt"
                  type="datetime"
                  :showAnalogClock="false"
                />
              </DxSimpleItem>
              <DxSimpleItem
                data-field="slutTidspunkt"
                :is-required="true"
                css-class="input-numbers"
              >
                <DxLabel text="Slut" />
                <DxDateBox
                  displayFormat="dd-MM-yyyy HH:mm"
                  v-model="besked.slutTidspunkt"
                  type="datetime"
                  :showAnalogClock="false"
                />
              </DxSimpleItem>
            </DxGroupItem>
          </DxGroupItem>
        </DxForm>
      </form>
    </div>
  </LetPage>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.custom-item {
  position: relative;
  min-height: 30px;
}

.dx-dropdowneditor-input-wrapper .custom-item > img {
  padding-left: 8px;
}

:deep(.dx-htmleditor .dx-htmleditor-toolbar-wrapper) {
  padding: 0 !important;
}

.dx-htmleditor-toolbar .dx-button .dx-button-content {
  padding: 0 !important;
}

.custom-item .status-name {
  display: inline-block;
  padding-left: 24px;
  text-indent: 0;
  line-height: 30px;
  width: 100%;
}

.custom-item > i {
  top: 9px;
  left: 10px;
  font-size: 1rem;
  position: absolute;
}
</style>
