<script setup lang="ts">
import OpslagLinkList from '@/components/opslag/OpslagLinkList.vue';
import { getOpslagDatasource } from '@/data/opslag';
import { getTagDatasource } from '@/data/tag';
import { getMaalgruppeRolleDatasource } from '@/data/rolle';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { getDataUrlFromImageFile } from '@/letapps-vue/utils/imageUtils';
import { ROUTE_HOME, ROUTE_OPSLAG } from '@/router/routeNames';
import LetTipTapEditor from '@/tiptap/component/LetTipTapEditor.vue';
import type { Link } from '@/types/link';
import type { OpslagAdgang } from '@/types/opslagAdgang';
import { RettighedsType } from '@/types/rettighed';
import type { Tag } from '@/types/tag';
import { getByKey } from '@/utils/opslagUtils';
import type { AxiosResponse } from 'axios';
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox, DxButton as DxDateBoxButton } from 'devextreme-vue/date-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxForm, DxLabel, DxSimpleItem } from 'devextreme-vue/form';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar';
import { confirm } from 'devextreme/ui/dialog';
import type { ValueChangedEvent } from 'devextreme/ui/select_box';
import type { CustomItemCreatingEvent } from 'devextreme/ui/tag_box';
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  id: {
    type: [Number, String],
    required: false,
  },
});

const opslagDatasource = getOpslagDatasource();
const tagDatasource = getTagDatasource();
await tagDatasource.loadIfEmpty();

const maalgruppeRolleDatasource = getMaalgruppeRolleDatasource();

const opslagForm = ref();
const opslag = ref();
const loading = ref(false);
const opslagFindesIkke = ref(false);
const tiptapEditor = ref();

async function gemKladde() {
  if (!opslag.value.kladde) {
    opslag.value.id = undefined;
    opslag.value.kladde = 1;

    opslag.value.links.forEach((link: Link) => {
      link.id = undefined;
      link.opslagId = undefined;
    });

    opslag.value.tags.forEach((tag: Tag) => {
      tag.id = undefined;
      tag.opslagId = undefined;
    });

    opslag.value.adgange.forEach((adgang: OpslagAdgang) => {
      adgang.id = undefined;
      adgang.opslagId = undefined;
    });
  }

  await gemOpslag();
}

async function gemOpslagKopi() {
  opslag.value.id = undefined;
  opslag.value.stinavn = undefined;
  opslag.value.masterId = undefined;
  opslag.value.notifikationAfsendt = undefined;
  opslag.value.publiceringsTidspunkt = undefined;
  opslag.value.kladde = 1;
  opslag.value.overskrift = opslag.value.overskrift + ' (KOPI)';

  opslag.value.links.forEach((link: Link) => {
    link.id = undefined;
    link.opslagId = undefined;
  });

  opslag.value.tags.forEach((tag: Tag) => {
    tag.id = undefined;
    tag.opslagId = undefined;
  });

  opslag.value.adgange.forEach((adgang: OpslagAdgang) => {
    adgang.id = undefined;
    adgang.opslagId = undefined;
  });

  await gemOpslag();
}

async function gemOpslag() {
  const validation = opslagForm.value.instance.validate();
  if (validation.isValid === false) return;

  loading.value = true;

  let newId = undefined;

  const newTags: Tag[] = [];
  const tagList: Tag[] = tagDatasource.items();
  let opslagTagNames = '';
  opslag.value.tagIds.forEach((tagId: number) => {
    const existingTag = opslag.value.tags?.find((t: Tag) => t.tagId === tagId);
    if (existingTag) {
      newTags.push(existingTag);
      opslagTagNames += existingTag.navn + ' ';
    } else {
      newTags.push({ tagId: tagId, opslagId: opslag.value.id });
      const newTag = tagList.filter((t: Tag) => t.id == tagId);
      if (newTag.length > 0) opslagTagNames += newTag[0].navn + ' ';
    }
  });
  opslag.value.tags = newTags;

  const newAdgange: OpslagAdgang[] = [];
  opslag.value.maalgruppeRolleIds.forEach((rolleId: number) => {
    const existingAdgang = opslag.value.adgange?.find((a: OpslagAdgang) => a.rolleId === rolleId);
    if (existingAdgang) {
      newAdgange.push(existingAdgang);
    } else {
      newAdgange.push({ rolleId: rolleId, opslagId: opslag.value.id });
    }
  });
  opslag.value.adgange = newAdgange;

  opslag.value.links.forEach((link: Link, index: number) => {
    if (link.id && link.id < 0) {
      link.id = undefined;
    }
    link.sortering = index;
  });

  opslag.value.soegeTekst = opslagTagNames + tiptapEditor.value.getText();

  try {
    const response = await opslagDatasource.save(opslag.value);
    newId = response.data.stinavn || response.data.masterId;
  } catch (e) {
    console.error(e);
  }
  loading.value = false;

  router.push(ROUTE_OPSLAG + '/' + newId);
}

async function fortryd() {
  if (router.options.history.state.back) {
    router.back();
  } else {
    await router.push(ROUTE_OPSLAG);
  }
}

async function sletOpslag() {
  let result;
  if (opslag.value.kladde) {
    result = confirm('Er du sikker at du vil slette kladden?', 'Slet kladde');
  } else {
    result = confirm(
      'Er du sikker at du vil slette <b>' + opslag.value.overskrift + '</b>?',
      'Slet opslag',
    );
  }

  result.then(async (answer) => {
    if (answer) {
      await opslagDatasource.store().remove(opslag.value.id);
      if (opslag.value.kladde) {
        await router.push(ROUTE_OPSLAG + '/' + (opslag.value.stinavn || opslag.value.masterId));
      } else {
        await router.push(ROUTE_OPSLAG);
      }
    }
  });
}

async function fortrydSletning() {
  opslag.value.slettet = false;
  await gemOpslag();
}

watchEffect(async () => {
  if (props.id) {
    try {
      opslag.value = await getByKey(props.id);
    } catch (error) {
      opslagFindesIkke.value = true;
    }
    if (opslag.value) {
      opslag.value.tagIds = opslag.value.tags.map((tag: Tag) => tag.tagId);
      opslag.value.maalgruppeRolleIds = opslag.value.adgange
        .filter((adgang: OpslagAdgang) => adgang.rolleId)
        .map((adgang: OpslagAdgang) => adgang.rolleId);
      opslag.value.links = opslag.value.links.sort(
        (a: Link, b: Link) => (a.sortering || 0) - (b.sortering || 0),
      );
    } else {
      opslag.value.tagIds = [];
      opslag.value.maalgruppeRolleIds = [];
      opslag.value.links = [];
    }
  }
});

async function handleMiniature(e: ValueChangedEvent) {
  let files = e.value;
  if (files) {
    opslag.value.miniature = await getDataUrlFromImageFile(files[0], 312, 175);
  }
}

function createNewTag(e: CustomItemCreatingEvent) {
  e.customItem = new Promise(function (resolve) {
    tagDatasource
      .store()
      .insert({ navn: e.text })
      .done((response: AxiosResponse) => {
        tagDatasource.reload().done(() => {
          resolve(response.data);
        });
      });
  });
}

function deleteLink(link: Link) {
  const newList: Link[] = [];
  opslag.value.links.forEach((l: Link) => {
    if (l !== link) {
      newList.push(l);
    }
  });
  opslag.value.links = newList;
}

let tempLinkId = -1;

function saveLink(link: Link) {
  if (!link.id) {
    tempLinkId--;
    link.id = tempLinkId;
  }
  const newList: Link[] = [];
  let linkExists = false;
  opslag.value.links.forEach((l: Link, index: number) => {
    if (l.id === link.id) {
      link.sortering = index;
      newList.push(link);
      linkExists = true;
    } else {
      l.sortering = index;
      newList.push(l);
    }
  });
  if (!linkExists) {
    link.sortering = opslag.value.links.length;
    newList.push(link);
  }
  opslag.value.links = newList;
}

function moveLink(link: Link, offset: number) {
  const newList: Link[] = [];
  const currentSort = link.sortering || 0;
  opslag.value.links.forEach((l: Link) => {
    if (l.sortering === currentSort + offset) {
      l.sortering = currentSort;
    } else if (l.sortering === currentSort) {
      l.sortering = currentSort + offset;
    }
    newList.push(l);
  });
  opslag.value.links = newList.sort((a, b) => (a.sortering || 0) - (b.sortering || 0));
}

const publicerNuButtonOptions = {
  text: 'Nu',
  stylingMode: 'text',
  onClick: () => {
    opslag.value.publiceringsTidspunkt = new Date();
  },
};

const udloebsTidspunktNuButtonOptions = {
  text: 'Nu',
  stylingMode: 'text',
  onClick: () => {
    opslag.value.udloebsTidspunkt = new Date();
  },
};

function getManchetLabel() {
  if (opslag.value.opslagType === 'Vejledning') {
    return 'Formål';
  } else {
    return 'Manchet';
  }
}

function onOpslagTypeChange(e: ValueChangedEvent) {
  if (e.value === 'Indhold') {
    opslag.value.sidebredde = 'fuld';
  } else if (e.value === 'Q&A') {
    opslag.value.sidebredde = 'smal';
  } else {
    opslag.value.sidebredde = 'normal';
  }
}
</script>

<template>
  <LetPage :fit="true">
    <div v-if="opslag" class="flex-container-column">
      <DxToolbar class="let-toolbar">
        <DxItem location="after" v-if="opslag.kladde">
          <div class="kladde">Ikke publiceret!</div>
        </DxItem>
        <DxItem location="after">
          <DxButton text="Fortryd" @click="fortryd" styling-mode="outlined" type="default" />
        </DxItem>
        <DxItem location="after">
          <LetButton
            :loading="loading"
            text="Gem kladde"
            :use-submit-behavior="false"
            @click="gemKladde"
            type="default"
          />
        </DxItem>
        <DxItem locate-in-menu="always" location="after">
          <DxButton text="Gem som kopi" @click="gemOpslagKopi" styling-mode="text" />
        </DxItem>
        <DxItem locate-in-menu="always" location="after" v-if="id && opslag && !opslag.slettet">
          <DxButton
            :text="opslag.kladde ? 'Slet kladde' : 'Slet opslag'"
            @click="sletOpslag"
            type="danger"
            styling-mode="text"
          />
        </DxItem>
        <DxItem locate-in-menu="always" location="after" v-if="id && opslag && opslag.slettet">
          <DxButton
            text="Fortryd sletning"
            @click="fortrydSletning"
            type="danger"
            styling-mode="text"
          />
        </DxItem>
      </DxToolbar>
      <div v-if="opslag" class="flex-container flex-1 opslag-container">
        <div class="editor-container" :class="'opslag-type-' + opslag.opslagType">
          <LetTipTapEditor ref="tiptapEditor" v-model="opslag.tekst" :opslagId="opslag.id" />
        </div>
        <div class="metadata-container">
          <DxForm
            :form-data="opslag"
            ref="opslagForm"
            :showValidationSummary="true"
            labelLocation="top"
          >
            <DxSimpleItem data-field="overskrift" :is-required="true" />
            <DxSimpleItem data-field="opslagType" :is-required="true">
              <DxLabel text="Type" />
              <DxSelectBox
                v-model="opslag.opslagType"
                :items="['Nyhed', 'Vejledning', 'Indhold', 'Produktside', 'Q&A']"
                @value-changed="onOpslagTypeChange"
              />
            </DxSimpleItem>
            <DxSimpleItem data-field="manchet" :is-required="false" editor-type="dxTextArea">
              <DxLabel :text="getManchetLabel()" />
            </DxSimpleItem>
            <DxSimpleItem data-field="publiceringsTidspunkt" css-class="input-numbers">
              <DxLabel text="Publiceringstidspunkt" />
              <DxDateBox
                displayFormat="dd-MM-yyyy HH:mm"
                v-model="opslag.publiceringsTidspunkt"
                type="datetime"
                :showAnalogClock="false"
              >
                <DxDateBoxButton
                  :options="publicerNuButtonOptions"
                  name="publicerNuButton"
                  location="after"
                />
                <DxDateBoxButton name="dropDown" />
              </DxDateBox>
            </DxSimpleItem>
            <DxSimpleItem data-field="udloebsTidspunkt" css-class="input-numbers">
              <DxLabel text="Udløb" />
              <DxDateBox
                displayFormat="dd-MM-yyyy HH:mm"
                v-model="opslag.udloebsTidspunkt"
                type="datetime"
                :showAnalogClock="false"
              >
                <DxDateBoxButton
                  :options="udloebsTidspunktNuButtonOptions"
                  name="udloebsTidspunktNuButton"
                  location="after"
                />
                <DxDateBoxButton name="dropDown" />
              </DxDateBox>
            </DxSimpleItem>
            <DxSimpleItem
              data-field="naesteOpdatering"
              css-class="input-numbers"
              v-if="opslag.opslagType === 'Vejledning' || opslag.opslagType === 'Produktside'"
            >
              <DxLabel text="Næste opdatering" />
              <DxDateBox
                displayFormat="dd-MM-yyyy"
                v-model="opslag.naesteOpdatering"
                type="date"
                :showAnalogClock="false"
              />
            </DxSimpleItem>
            <DxSimpleItem data-field="sidebredde">
              <DxLabel text="Sidebredde" />
              <DxSelectBox
                v-model="opslag.sidebredde"
                :items="[
                  { value: 'normal', text: 'Normal' },
                  { value: 'smal', text: 'Smal' },
                  { value: 'bred', text: 'Bred' },
                  { value: 'fuld', text: 'Fuld bredde' },
                ]"
                display-expr="text"
                value-expr="value"
              />
            </DxSimpleItem>
            <DxSimpleItem data-field="forfatter" />
            <DxSimpleItem
              data-field="ansvarlig"
              v-if="opslag.opslagType === 'Vejledning' || opslag.opslagType === 'Produktside'"
            />
            <DxSimpleItem
              data-field="godkendelse"
              v-if="opslag.opslagType === 'Vejledning' || opslag.opslagType === 'Produktside'"
            />
            <DxSimpleItem data-field="stinavn" />
            <DxSimpleItem data-field="tags">
              <DxLabel text="Tags" />
              <DxTagBox
                :data-source="tagDatasource"
                v-model="opslag.tagIds"
                displayExpr="navn"
                valueExpr="id"
                :accept-custom-value="hasRettighed(RettighedsType.LetguideTagAdmin)"
                @custom-item-creating="createNewTag"
              />
            </DxSimpleItem>
            <DxSimpleItem data-field="adgange">
              <DxLabel text="Målgruppe" />
              <DxTagBox
                :data-source="maalgruppeRolleDatasource"
                v-model="opslag.maalgruppeRolleIds"
                displayExpr="navn"
                valueExpr="id"
              />
            </DxSimpleItem>
            <DxSimpleItem data-field="altidOeverst" editType="dxCheckBox">
              <DxLabel text="Altid øverst" />
            </DxSimpleItem>
            <DxSimpleItem data-field="miniature">
              <DxLabel text="Miniature" />
              <DxFileUploader
                accept="image/*"
                uploadMode="useForm"
                class="opslag-miniature"
                @value-changed="handleMiniature"
                :showFileList="false"
              />
              <img :src="opslag.miniature" />
            </DxSimpleItem>
            <DxSimpleItem
              v-if="opslag.opslagType === 'Vejledning' || opslag.opslagType === 'Produktside'"
            >
              <DxLabel text="Relaterede materialer" />
              <OpslagLinkList
                :opslag="opslag"
                :editable="true"
                @delete-link="deleteLink"
                @save-link="saveLink"
                @move-link="moveLink"
              />
            </DxSimpleItem>
          </DxForm>
        </div>
      </div>
    </div>
    <div v-if="opslagFindesIkke">
      Opslag findes ikke. Klik <router-link :to="ROUTE_HOME">her</router-link> for at komme retur
      til forsiden
    </div>
  </LetPage>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.kladde {
  font-weight: bold;
  color: $letpension-error;
  margin-right: 20px;
}

.opslag-container {
  overflow: auto;
}

.editor-container {
  flex: 3;
  overflow: auto;
}

.metadata-container {
  flex: 1;
  padding-right: 4px;
  overflow: auto;
}

.opslag-miniature {
  background: $letpension-sand-light;
}

.opslag-miniature :deep(.dx-fileuploader-files-container) {
  display: none;
}
</style>
