import { VueNodeViewRenderer } from '@tiptap/vue-3';
import LetTableWrapperComponent from './LetTableWrapperComponent.vue';
import { mergeAttributes, Node } from '@tiptap/core';

export const LetTableWrapper = Node.create({
  name: 'letTableWrapper',
  group: 'block',
  content: 'table',

  parseHTML() {
    return [{ tag: 'let-table-wrapper' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['let-table-wrapper', mergeAttributes(HTMLAttributes), 0];
  },

  addAttributes() {
    return {
      ...this.parent?.(),

      class: {
        default: null,
        // Customize the HTML parsing
        parseHTML: (element) => {
          return element.getAttribute('class');
        },
        // … and customize the HTML rendering
        renderHTML: (attributes) => {
          if (attributes.class) {
            return {
              class: attributes.class,
            };
          }
        },
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(LetTableWrapperComponent);
  },
});
