<script setup></script>

<template>
  <div class="content">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.content {
  flex: 1;
}

:deep(.container) {
  height: 100%;
  font-size: 16px;
  gap: 80px;
  padding-bottom: 40px;
  overflow: auto;
}

:deep(.titel) {
  font-family: Marcia;
  font-size: 48px;
}

:deep(.undertitel) {
  font-family: Montserrat, sans-serif;
  color: $letpension-orange;
  font-size: 24px;
  margin-left: 20px;
}

:deep(.chart-titel) {
  font-size: 16px;
  font-weight: bold;
  margin-top: 40px;
}

@media print {
  @page {
    size: landscape;
    margin: 20mm;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  :deep(.titel) {
    font-size: 56px;
  }

  :deep(.chart-titel) {
    margin-top: 20px;
  }

  :deep(.tiptap p) {
    font-size: 18px;
  }

  :deep(.tiptap h3) {
    font-size: 20px;
  }
}
</style>
