<script setup>
import { ref, watchEffect } from 'vue';
import LetBackButton from '@/letapps-vue/components/LetBackButton.vue';
import { ROUTE_DRIFTBESKEDER } from '@/router/routeNames';
import { getDriftbeskedDatasource } from '@/data/driftstatus';
import { formatDate } from 'devextreme/localization';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { RettighedsType } from '@/types/rettighed';
import LetToolbar from '@/letapps-vue/components/LetToolbar.vue';
import LetReadingArea from '@/letapps-vue/components/LetReadingArea.vue';
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import dayjs from 'dayjs';

const props = defineProps({
  id: {
    type: Number,
    required: false,
  },
});

async function sendNotifikation() {
  const response = await axios.get(`${getAppConfig().apiRoot}/api/Driftbeskeder/sendnotifikation`, {
    params: { driftbeskedId: props.id },
  });
  if (response.status === 200) {
    besked.value.notifikationAfsendt = new Date();
  }
}

const beskedDatasource = getDriftbeskedDatasource();
const besked = ref();

watchEffect(async () => {
  if (props.id) {
    besked.value = await beskedDatasource.store().byKey(props.id);
  } else {
    besked.value = {
      startTidspunkt: new Date(),
    };
  }
});
</script>

<template>
  <LetPage>
    <LetToolbar>
      <LetBackButton text="Tilbage" />
      <template #end>
        <div
          v-if="besked?.notifikationAfsendt && hasRettighed(RettighedsType.LetguideDriftbesked)"
          class="seneste-notifikation"
        >
          Seneste notifikation: {{ dayjs(besked.notifikationAfsendt).format('DD-MM-YYYY HH:mm') }}
        </div>
        <LetButton
          v-if="hasRettighed(RettighedsType.LetguideDriftbesked)"
          icon="email"
          text="Send notifikation"
          type="default"
          styling-mode="text"
          @click="sendNotifikation"
        />
        <router-link
          v-if="hasRettighed(RettighedsType.LetguideDriftbesked)"
          :to="`${ROUTE_DRIFTBESKEDER}/${id}/edit`"
        >
          <LetButton icon="edit" text="Rediger besked" type="default" styling-mode="text" />
        </router-link>
      </template>
    </LetToolbar>
    <LetReadingArea sidebredde="smal">
      <div v-if="besked" class="besked tiptap">
        <div>
          <h1>{{ besked.overskrift }}</h1>
          <span class="besked-overskrift" v-if="besked.organisationId !== 1">
            ({{ besked.organisation?.navn }})</span
          >
        </div>
        <div class="besked-starttidspunkt">
          {{ formatDate(new Date(besked.startTidspunkt), 'dd-MM-yyyy hh:mm') }}
        </div>
        <div class="besked-tekst" v-html="besked.tekst"></div>
      </div>
    </LetReadingArea>
  </LetPage>
</template>

<style scoped lang="scss">
@import '@/css/variables.letguide.scss';

.seneste-notifikation {
  padding-top: 7px;
}

.besked-overskrift {
  font-weight: bold;
  font-size: 1.125rem;
}

.besked-starttidspunkt {
  margin-top: 10px;
  color: $letpension-grey;
}

.besked-tekst {
  margin-top: 20px;
  font-size: 1rem;
}

.besked-oprettet-af {
  color: $letpension-grey-text;
  margin-top: 10px;
}

.tiptap {
  .besked-tekst {
    :deep(p) {
      margin-bottom: 0;
    }

    :deep(ul) {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}
</style>
