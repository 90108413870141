export const ROUTE_HOME = '/';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_SEARCH = '/search';
export const ROUTE_DRIFTBESKEDER = '/driftbeskeder';
export const ROUTE_OPSLAG = '/opslag';
export const ROUTE_NYHEDER = '/nyheder';
export const ROUTE_FILER = '/filer';
export const ROUTE_KVARTALSRAPPORT = '/kvartalsrapport';
export const ROUTE_KVARTALSRAPPORT_PRINT = '/kvartalsrapportprint';
export const ROUTE_FILE_VIEWER = '/fileview';
export const ROUTE_LEDERSIDEN = '/ledersiden';
